import { Logger, getNullLogger } from '@uniformdev/common';
import { getSubscriptionManager } from '@uniformdev/optimize-common-sitecore';
import { PageDescription, PersonalizationManager, PersonalizationManagerEvent } from './index';
import {
    getSitecorePersonalizationRuleManager,
    GetSitecorePersonalizationRuleManagerArgs,
    RuleBasedRenderingPersonalizationDefinition,
} from '../ruleManagers/sitecore';
import { getSitecoreContextDataReader, SitecoreContextDataSource } from './contextReaders';
import { getNullTestManager, TestManager } from '../testManagers';
import { PersonalizationEventData } from '@uniformdev/tracking';

export interface SitecoreRoute {
    name: string, 
    itemId: string
};

/**
 * Represents a Sitecore component that can be personalized.
 * As a result of personalization rules matching, the
 * data source on the definition may change.
 */
export interface RenderingDefinition {
    uid: string;
    fields?: any;
    componentName: string;
    dataSource?: string;
    params?: any;
    personalization?: PersonalizationEventData;
}

/**
 * Settings used to get a Sitecore personalization manager.
 */
export interface GetSitecorePersonalizationManagerArgs {
    /**
     * Context data read from JSS Layout Service, Uniform Page Service, etc.
     */
    contextData: any;
    /**
     * Specifies how to read personalization settings from the context data.
     */
    contextDataSource?: SitecoreContextDataSource;
    disabled?: boolean;
    logger?: Logger;
    testManager?: TestManager;
}

export interface SitecoreItem extends PageDescription {
};

/**
 * Gets a Sitecore personalization manager.
 * @param args
 */
export function getSitecorePersonalizationManager(args: GetSitecorePersonalizationManagerArgs): PersonalizationManager<RenderingDefinition, SitecoreItem> {
    const id = "SC_PERS_MGR";
    const { disabled = false, contextData, contextDataSource, testManager = getNullTestManager(), logger = getNullLogger() } = args;
    //
    //
    const reader = getSitecoreContextDataReader(contextDataSource);
    const page = reader?.getPageDetails(contextData);
    const definitions = reader?.getDefinitions(contextData) as RuleBasedRenderingPersonalizationDefinition;
    const item = reader?.getPageDetails(contextData);
    //
    //
    const args2: GetSitecorePersonalizationRuleManagerArgs = {
        definitions,
        item, 
        logger
    };
    const ruleManager = getSitecorePersonalizationRuleManager(args2);
    //
    //
    const subscriptions = getSubscriptionManager<PersonalizationManagerEvent>(`${id}_SUBS`, false, logger);
    //
    //
    async function doPersonalize(rendering: RenderingDefinition, triggeredTriggers?: string[]) {
        if (!rendering) {
            logger.error('Sitecore personalization manager - No rendering was specified.');
            return;
        }
        if (disabled) {
            logger.debug('Sitecore personalization manager - Personalization is disabled.');
            return;
        }
        if (!ruleManager) {
            logger.debug('Sitecore personalization manager - No rule manager is configured.');
            return;
        }
        const rules = ruleManager.getRulesForRendering(rendering);
        if (!rules || rules.length == 0) {
            logger.debug('Sitecore personalization manager - No rules were resolved for the rendering.', { rendering: rendering.uid });
            return;
        }
        //
        //Set to loading before applying the rules
        subscriptions.publish({
            component: rendering.uid,
            isLoading: true,
            page,
            type: 'state-changed',
            when: new Date(),
        });
        try {
            //
            //Get the first matching rule. If a rule has dependencies
            //that are not met, the rule is skipped. When an event
            //occurs that might change the dependencies,
            const rule = await ruleManager.getFirstMatchingRule(rendering, triggeredTriggers);
            if (!rule) {
                logger.debug('Sitecore personalization manager - No personalization rule found that applies to the rendering.', { rendering: rendering.uid });
                subscriptions.publish({
                    component: rendering.uid,
                    isLoading: false,
                    page,
                    type: 'state-changed',
                    when: new Date(),
                });
                return;
            }
            logger.debug('Sitecore personalization manager - Will run the actions associated with the rule.', { rule, rendering: rendering.uid });
            const result = await ruleManager.runRuleActions(rule, rendering);
            const includedInTest = testManager?.getIsIncludedInTest() ?? false;
            //
            //Publish a state-changed event to notify components that
            //personalization is done loading and that there may be
            //changes to render.
            logger.debug('Sitecore personalization manager - Result of running the rule actions.', { result, rule, rendering: rendering.uid });
            subscriptions.publish({
                changes: result.changes,
                component: rendering.uid,
                includedInTest,
                isLoading: false,
                page,
                personalizedData: result.fields,
                rule,
                type: 'state-changed',
                when: new Date(),
            });
        } catch (error) {
            subscriptions.publish({
                component: rendering.uid,
                error,
                isLoading: false,
                page,
                type: 'state-changed',
                when: new Date(),
            });
            return;
        }
    }
    //
    //
    const manager: PersonalizationManager<RenderingDefinition, SitecoreItem> = {
        id,
        disabled,
        page,
        subscriptions,
        triggers: ruleManager?.triggers,
        testManager,
        onTrigger: async (trigger, rendering) => {
            doPersonalize(rendering, [trigger]);
        },
        personalize: async (rendering) => {
            doPersonalize(rendering);
        }
    };
    return manager;
}
