import { Logger } from '@uniformdev/common';
import { appendArray } from '@uniformdev/optimize-common-sitecore';
import { Dispatcher } from '../dispatchers/dispatcher';
import { Destination } from '../dispatchers/destination';
import { getDispatchersForGaDestinations } from './ga';
import { getDispatchersForOracleDmpDestinations } from './oracleDmp';
import { getDispatchersForXdbDestinations } from './xdb';
import { GaDestination } from '../connectors/ga/destination';
import { OracleDmpDestination } from '../connectors/oracleDmp/destination';

export interface DestinationMapArgs {
    logger: Logger;
    loggerPrefix: string;
}

/**
 * Returns a map of destinations from tracking data, grouped by type.
 * @param trackingData 
 * @param args
 */
function getDestinationMapFromTrackingConfig(trackingConfig: any, args: DestinationMapArgs): Map<string, Destination[]> {
    const { logger, loggerPrefix } = args;
    const map = new Map<string, Destination[]>();

    if (!trackingConfig) {
        logger.warn(`${loggerPrefix} - No tracking config was specified, so no destinations will be added to the destination map.`, { args });
        return map;
    }

    const allDestinations = trackingConfig.destinations as Destination[];

    if (allDestinations?.length ?? 0 > 0) {
        allDestinations.forEach(destination => {
            const type = destination.type;
            const destinations = map.get(type) ?? [];
            if (destination.configId && destinations.some(d => d.configId == destination.configId)) {
                logger.debug(`${loggerPrefix} - Destination is included in the tracking data multiple times. It will not be added to the destination map more than once.`, destination);
                return;
            }
            destinations.push(destination);
            map.set(type, destinations);
        });
    }
    return map;
}

export interface GetDispatchersArgs {
    logger: Logger;
    loggerPrefix: string;
    ga?: {
        initializeGa: { (destination: GaDestination, logger: Logger): boolean }
    };
    oracleDmp?: {
        initializeOracleDmp?: { (destination: OracleDmpDestination, logger: Logger): boolean }
    };
    getCookie: { (name: string): any };
    removeCookie: { (name: string): void };
    setCookie: { (name: string, value:any, options?: any): void };
}

/**
 * When dispatchers are configured in Sitecore, they 
 * are exposed in tracking data as destinations. This 
 * function controls the process responsible for 
 * converting these destinations into dispatchers.
 * @param trackingConfig 
 * @param args 
 */
export function getDispatchersFromTrackingConfig(trackingConfig: any, args: GetDispatchersArgs): Dispatcher[] {
    const { logger, loggerPrefix } = args;
    const dispatchers: Dispatcher[] = [];
    const map = getDestinationMapFromTrackingConfig(trackingConfig, { logger, loggerPrefix });
    const keys = Array.from(map.keys());
    keys.forEach(key => {
        const destinations = map.get(key) ?? [];
        if (destinations.length == 0) {
            return;
        }
        switch(key) {
            case "ga":
                if (!args.ga) {
                    logger.error(`${loggerPrefix} - GA settings are missing from args so unable to configure GA dispatchers.`, args);
                    return;
                }
                const gaDispatchers = getDispatchersForGaDestinations(destinations, args) ?? [];
                if (gaDispatchers.length > 0) {
                    logger.debug(`${loggerPrefix} - GA dispatchers were registered.`, gaDispatchers);
                    appendArray(gaDispatchers, dispatchers);
                }
                return;
            case "oracleDmp":
                const oracleDmpDispatchers = getDispatchersForOracleDmpDestinations(destinations, args) ?? [];
                if (oracleDmpDispatchers.length > 0) {
                    logger.debug(`${loggerPrefix} - Oracle DMP dispatchers were registered.`, oracleDmpDispatchers);
                    appendArray(oracleDmpDispatchers, dispatchers);
                }
                return;
            case "xdb":
                const xdbDispatchers = getDispatchersForXdbDestinations(destinations, args) ?? [];
                if (xdbDispatchers.length > 0) {
                    logger.debug(`${loggerPrefix} - xDB dispatchers were registered.`, xdbDispatchers);
                    appendArray(xdbDispatchers, dispatchers);
                }
                return;
            default:
                logger.error(`${loggerPrefix} - The specified destination type is not supported.`, {type: key, destinations: destinations.length});
                return;
        }
    });
    return dispatchers;
}
