import { GaDestination } from "../connectors/ga/destination";
import { GaDispatcher, GaSetCustomDimensionValues, GaTrackedActivityConverter } from "../connectors/ga/dispatcher";
import { getGaTrackedActivityConverterForSitecore } from "../connectors/sitecore/gaEventConverter";
import { Destination, Dispatcher } from "../dispatchers";
import { TrackedActivityResults } from "../models/trackedActivity";
import { GetDispatchersArgs } from "./handleDestinations";

export function getDispatchersForGaDestinations(destinations: Destination[], args: GetDispatchersArgs): Dispatcher[] {
    const { logger, loggerPrefix } = args;
    const dispatchers: Dispatcher[] = [];
    destinations.forEach(destination => {
        const dispatcher = getDispatcher(destination as GaDestination, args);
        if (!dispatcher) {
            logger.error(`${loggerPrefix} - Unable to get dispatcher for GA destination.`, destination);
            return;
        }
        dispatchers.push(dispatcher);
    })
    return dispatchers;
}

function getDispatcher(destination: GaDestination, args: GetDispatchersArgs): Dispatcher | undefined {
    if (!destination) {
        return;
    }
    const { ga, logger, loggerPrefix } = args;
    if (!ga) {
        logger.debug(`${loggerPrefix} - No GA settings were specified on args so no GA dispatch will be performed.`, args);
        return;
    }
    const { initializeGa } = ga;
    if (!initializeGa) {
        logger.debug(`${loggerPrefix} - No function to initialize GA was specified on args so no GA dispatch will be performed.`, args);
        return;
    }
    if (!initializeGa(destination, logger)) {
        logger.debug(`${loggerPrefix} - Unable to initialize GA so no GA dispatch will be performed.`, destination);
        return;
    }
    const converters = getActivityConverters(destination, args);
    const dispatcher = new GaDispatcher(converters, destination);
    if (destination.mappings) {
        const mappings = destination.mappings;
        const setValues: GaSetCustomDimensionValues = (_results: TrackedActivityResults, values: Map<number, any>) => {
            mappings.forEach(mapping => {
                if (!mapping.action) {
                    return;
                }
                const action = new Function(mapping.action);
                const result = action();
                if (result) {
                    values.set(mapping.index, result);
                }
            });
        }
        dispatcher.setCustomDimensionValues = setValues;
    }
    return dispatcher;
}

/**
 * Gets the tracked activity converters for the specified destination.
 * @param destination 
 * @param logger 
 */
function getActivityConverters(destination: GaDestination, args: GetDispatchersArgs): GaTrackedActivityConverter[] {
    const { logger, loggerPrefix } = args;
    const converters: GaTrackedActivityConverter[] = destination.activityConverters ?? [];
    if (destination.doNotUseDefaultActivityConverter != true) {
        const converter = getGaTrackedActivityConverterForSitecore();
        if (converter) {
            if (converters.every(c => c.type != converter.type)) {
                converters.push(converter);
                logger.debug(`${loggerPrefix} - Added default GA tracked activity converter for Sitecore.`, {converters});
            }
        }
    }
    return converters;
}
