import { Visitor } from '../models/visitor';
import { Logger } from '@uniformdev/common';
import { StorageProvider } from './provider';

export class LocalStorageProvider implements StorageProvider {
    read(visitorId: string, _logger?: Logger): Visitor | undefined {
        if (visitorId) {
            const value = localStorage.getItem(visitorId);
            if (value) {
                return JSON.parse(value);
            }
        }
        return undefined;
    }

    write(visitor: Visitor, _logger?: Logger): void {
        if (visitor && visitor.id) {
            localStorage.setItem(visitor.id, JSON.stringify(visitor));
        }
    }
}

