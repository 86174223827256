import React from 'react';
import { RenderingProps } from './RenderingProps';

const esiComponents: any = {
    EsiChoose: function (data: any) {
        return `<esi:choose>${renderComponents(data.placeholders['esi-choose'])}</esi:choose>`;
    },
    EsiWhen: function (data: any) {
        return `<esi:when test="${data.params.test}">${renderComponents(
            data.placeholders['esi-when']
        )}</esi:when>`;
    },
    EsiOtherwise: function (data: any) {
        return `<esi:otherwise>${renderComponents(data.placeholders['esi-otherwise'])}</esi:otherwise>`;
    },
    EsiForEach: function (data: any) {
        return `<esi:foreach item="${data.params.item}" collection="${
            data.params.collection
        }">${renderComponents(data.placeholders['esi-for-each'])}</esi:foreach>`;
    },
    EsiAssign: function (data: any) {
        return `<esi:assign name="${data.params.name}" value="${data.params.value}"></esi:assign>`;
    },
};
function renderComponents(components: any[]) {
    return components
        .map((data: any) => {
            const fn = esiComponents[data.componentName];
            return typeof fn === 'function' ? fn(data) : '';
        })
        .join('');
}

export default function EsiNoOutput(props: RenderingProps) {
    const placeholders = props.rendering?.placeholders || {};
    const esidata = placeholders['esi-no-output'] ?? [];
    const esi = renderComponents(esidata);
    return <script type="text/esi" dangerouslySetInnerHTML={{ __html: esi }} />;
}
