import { FilteredActivities, getVisitorActivities, UniformWindow, Visitor } from '@uniformdev/tracking';

declare let window: UniformWindow;

function getVisitor(): Visitor | undefined {
    return window?.uniform?.visitor;
}


export function getVisitorProperty(property: string): any {
    if (property) {
        const visitor = getVisitor();
        if (visitor) {
            const i = Object.keys(visitor).findIndex(key => key == property);
            if (i != -1) {
                return Object.values(visitor)[i];
            }
        }
    }
    return undefined;
}

export function getVisitorProfilePatternMatch(profile: string, usePatternId?: boolean): string | undefined {
    if (profile) {
        const visitor = getVisitor();
        if (visitor) {
            const data = visitor.data?.patterns?.data;
            if (data) {
                if (data[profile]) {
                    if (usePatternId === true) {
                        return data[profile].patternId;
                    }
                    return data[profile].name;
                }
            }
        }
    }
    return undefined;
}

export function getVisitorGoals(visitor: Visitor): FilteredActivities {
    return getVisitorActivities('goal', visitor);
}
