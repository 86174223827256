import React from 'react';
import { RenderingProps } from './RenderingProps';

export default function EsiAssign(props: RenderingProps) {
  let {name, value} = props.params;
  if (name.startsWith('ESI_CONTEXT_personalization_rules{')) {
    name = `ESI_CONTEXT_personalization_rules_${value.replace(/['-]/g, "")}`;
    value = true;
  }
  return React.createElement('esi:assign', { name, value }, undefined);
}
