import { Logger, getNullLogger } from '@uniformdev/common';

/**
 * Settings for a specific test.
 */
export interface TestSettings {
    id: string;
    name: string;
    start?: string;
    end?: string;
    size: number;
}

/**
 * Provides access to the visitor's state with respect 
 * to the current test. For example, one implementation
 * might store the state in a cookie while another may
 * store state in an external state server.
 */
export interface TestStateManager {
    getIsTestingStateKnown: (settings: TestSettings) => boolean;
    getIsIncludedInTest: (settings: TestSettings) => boolean;
    setIsIncludedInTest: (value: boolean, settings: TestSettings) => void;
    setTestNotRunning: () => void;
}

/**
 * Settings that control how the test manager operates.
 */
export interface TestManagerOptions {
    disabled?: boolean;
    logger?: Logger;
}

/**
 * Provides the ability to determine whether or not
 * a visitor is included in a test.
 */
export interface TestManager {
    id: string;
    disabled: boolean;
    getIsIncludedInTest: () => boolean;
}

/**
 * Settings used to get a test manager.
 */
export interface GetTestManagerArgs {
    testSettings: TestSettings[];
    testStateManager: TestStateManager;
    options?: TestManagerOptions;
}

const disabledTestManager: TestManager = {
    id: "DEFAULT_TEST_MANAGER_DISABLED",
    disabled: true,
    getIsIncludedInTest: () => false,
}
const defaultTestManager: TestManager = {
    id: "DEFAULT_TEST_MANAGER",
    disabled: false,
    getIsIncludedInTest: () => false,
}

export function getNullTestManager(): TestManager {
    return defaultTestManager;
}
/**
 * Gets a test manager.
 * @param args 
 */
export function getTestManager(id: string, args: GetTestManagerArgs): TestManager {
    const { testSettings, testStateManager, options } = args;
    if (options?.disabled) {
        return disabledTestManager;
    }

    const logger = options?.logger || getNullLogger();

    function getIsIncludedInTest() {
        //
        //
        const currentTest = getCurrentTestSettings();
        if (!currentTest) {
            testStateManager.setTestNotRunning(); //delete cookie
            return false;
        }
        //
        //
        let isIncludedInTest = false;
        if (testStateManager.getIsTestingStateKnown(currentTest)) {
            isIncludedInTest = testStateManager.getIsIncludedInTest(currentTest)
        }
        else {
            isIncludedInTest = getNextIncludeInTest(currentTest);
            testStateManager.setIsIncludedInTest(isIncludedInTest, currentTest);
        }
        return isIncludedInTest;
    }

    function getCurrentTestSettings(): TestSettings|undefined {
        if (Array.isArray(testSettings)) {
            const now = new Date();
            return testSettings.find(s => {
                const isStarted = (!s.start) || (now > new Date(s.start));
                const notEnded = (!s.end) || (new Date(s.end) > now);
                return isStarted && notEnded;
            });
        }
        return;
    }

    function getNextIncludeInTest(testSettings: TestSettings) {
        //
        //The test sample size specifies the percentage of
        //visitors who are included in the test. If the
        //size is 15, that means 15% of the visitors
        //will not see personalization.
        const value = Math.floor(Math.random() * 100);
        const sampleSize = testSettings.size ?? 0;
        const include = sampleSize > value;
        logger.debug('Test manager - Visitor' + (include ? ' WILL ' : ' will NOT ') + 'be included in the test.', {
            value,
            testSettings,
        });
        return include;
    }

    return {
        id,
        disabled: false,
        getIsIncludedInTest,
    };
}