import { TrackedActivityResults } from '../models/trackedActivity';
import { ContextReader } from '../contextReader';
import { Tracker, TrackingSettings, TrackingEventType, TrackingEvent } from './tracker';
import { TrackedActivity, TrackedActivityType} from '../models/trackedActivity';
import { UniformUnsubscribe, UniformCallback, createUniformUnsubscribe } from '@uniformdev/optimize-common-sitecore';

const nullTracker: Tracker = {
    id: "TRACKER_NULL",
    async event(_type: TrackedActivityType, _e: TrackedActivity, _settings: TrackingSettings): Promise<TrackedActivityResults> {
        return new TrackedActivityResults();
    },
    async initialize(_settings: TrackingSettings): Promise<TrackedActivityResults> {
        return new TrackedActivityResults();
    },
    async track(_source: string | undefined, _context: any, _settings: TrackingSettings): Promise<TrackedActivityResults> {
        return new TrackedActivityResults();
    },
    subscribe(_type: TrackingEventType, _callback: UniformCallback<TrackingEvent>): UniformUnsubscribe { 
        return createUniformUnsubscribe(() => false);
    },
    contextReaders: new Map<string, ContextReader[]>(),
    state: "ready"
}

export function getNullTracker(): Tracker {
    return nullTracker;
}
