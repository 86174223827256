import { Logger } from '@uniformdev/common';

const DEFAULT_DECAY_RATE: number = 3;

/**
 * Algorithm used to calculate decay for a value.
 */
export type DecayType = 'simple' | 'compound' | 'default';

export type DecayTimeUnit = 'days' | 'hours' | 'minutes' | 'seconds' | 'default';

export type DecayRounding = 'up' | 'down' | 'closest' | 'none' | 'default';

/**
 * Settings that specify how decay is applied to a value. 
 */
export interface DecaySettings {
    /** Algorithm used to calculate decay for a value. */
    type?: DecayType;
    /** 
     * The unit of measure that describes the difference 
     * between two dates. For example, "day" means that
     * the difference between two dates is expressed in
     * the number of days.
     * */
    timeUnit?: DecayTimeUnit;
    /**
     * The number of time units that must pass before 
     * decay is applied. For example, if the increment
     * is 2 and the unit is days and 5 days have passed,
     * decay will apply 2 times.
     */
    timeIncrement?: number;
    /** 
     * Number from 0 to 100 that represents the percentage 
     * by which a value decays per unit/increment of time. 
     * */
    rate?: number;
    /** 
     * Decay is calculated using decimal values but may 
     * be need to be rounded when non-negative integers 
     * are required. This setting specifies how to handle 
     * rounding the value that is returned.
     * */
    round?: DecayRounding;
}

/**
 * Gets the default decay settings.
 */
export function getDefaultDecaySettings(): DecaySettings {
    return {
        rate: DEFAULT_DECAY_RATE,
        round: 'default',
        timeUnit: 'default',
        timeIncrement: 1,
        type: 'default'
    };
}

/**
 * Applies decay to the specified values.
 * @param value 
 * @param periods 
 * @param settings 
 * @param logger 
 */
export function doDecay(value: number, periods: number, settings: DecaySettings, logger: Logger): number {
    if (value <= 0 || periods <= 0) {
        return 0;
    }
    const decay = getDecay(periods, settings.type, settings.rate, logger);
    const valueWithDecay: number = value * decay;
    return doRounding(valueWithDecay, settings.round, logger);
}

/**
 * Calculates the difference between two dates
 * and then determines the number of intervals
 * that difference can be described with.
 * 
 * For example, if there are 36 hours between
 * the two dates and the settings specify the 
 * decay rate is every 4 hours, 9 is returned.
 * @param oldDate 
 * @param newDate 
 * @param settings 
 * @param logger 
 */
export function getDifferenceAsTimeIncrements(oldDate: (Date | string), newDate: (Date | string), settings: DecaySettings, logger: Logger): number {
    var time = 0;
    switch (settings.timeUnit ?? 'default') {
        case 'seconds':
            time = 1000;
            break;
        case 'minutes':
            time = 1000 * 60;
            break;
        case 'hours':
            time = 1000 * 60 * 60;
            break;
        case 'days':
        case 'default':
            time = 1000 * 60 * 60 * 24;
            break;
        default:
            logger.error("The specified decay unit is not supported. No decay will be used.", { settings });
    }
    if (time == 0) {
        return 0;
    }
    const difference = getDateDifference(oldDate, newDate);
    const diffByTime = Math.abs(difference/time);
    const increment = settings.timeIncrement ?? 1;
    const diffByIncrement = Math.floor(diffByTime/increment);
    return diffByIncrement;
}

function getDate(value: (Date | string)): Date {
    if (typeof value === 'string') {
        return new Date(value);
    }
    return value as Date;
}

function getDateDifference(oldDate: (Date | string), newDate: (Date | string)): number {
    return getDate(newDate).getTime() - getDate(oldDate).getTime();
}

function getDecay(periods: number, type: DecayType = 'default', rate: number = DEFAULT_DECAY_RATE, logger: Logger): number {
    switch (type) {
        case 'compound':
            return Math.pow((1-rate/100), periods);
        case 'simple':
        case 'default':
            return (1 - (rate/100 * periods));
        default:
            logger.error("The specified decay type is not supported. No decay will be used.", { type });
    }
    return 1;
}

function doRounding(value: number, rounding: DecayRounding = 'default', logger: Logger): number {
    switch (rounding) {
        case 'none':
            return value;
        case 'down':
            return Math.floor(value);
        case 'up':
            return Math.ceil(value);
        case 'closest':
        case 'default':
            break;
        default: 
            logger.error("The specified rounding option is not supported. Default rounding will be used.", {value, rounding});
    }
    return Math.round(value);
}
