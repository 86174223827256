'use strict';
import { Tracker, TrackerExtensionPoints, TrackingEvent } from './tracker';
import { getTrackingDataRepository } from '../repositories';
import { StorageProviderType, StorageProvider, getStorageProvider } from '../storage';
import { ContextReader } from '../contextReader';
import { Logger, getNullLogger } from '@uniformdev/common';
import { SubscriptionManager } from '@uniformdev/optimize-common-sitecore';
import { DefaultTracker, DefaultTrackerSettings } from './defaultTracker';
import { Dispatcher, DispatchEventHandler } from '../dispatchers';
/**
 * Settings used to get a reference to a tracker.
 */
export interface GetTrackerArgs {
    /**
     * If the storage type is set to "custom", 
     * this callback is used to resolve the 
     * storage provider.
     */
    getCustomStorageProvider?: () => StorageProvider;
    /**
     * Map of context readers that are able to read
     * data from different sources.
     */
    contextReaders?: Map<string, ContextReader[]>;
    /**
     * Components that handle tracking activity results, 
     * usually for the purpose of writing that activity 
     * to external storage.
     */
    dispatchers?: Dispatcher[];
    /**
     * Event handler for when tracker activity is ready 
     * to be dispatched.
     */
    onDispatch?: DispatchEventHandler;
    /**
     * Extension point handlers that extend the 
     * functionality of the tracker itself.
     */
    extensions?: TrackerExtensionPoints;
    logger?: Logger;
    /**
     * Names of the query string parameters that specify the campaign id.
     */
    campaignParameters?: string[];
    /**
     * Names of the query string parameters that specify the goal id.
     */
    goalParameters?: string[];
    /** 
     * Number of minutes a session will live 
     * without new activity before time out. 
     * */
    sessionTimeout?: number;
    storage: StorageProviderType;
    subscriptions?: SubscriptionManager<TrackingEvent>;
}

/**
 * Settings that control how the getDefaultTracker function works.
 */
export interface GetDefaultTrackerArgs {
    logger?: Logger
}

/**
 * This function is usually called by a custom tracker.
 * @param args - Settings that control the tracker that is retrieved.
 * @param args2 - Settings that control how this function works.
 */
export function getDefaultTracker(args: GetTrackerArgs, args2?: GetDefaultTrackerArgs): Tracker | undefined {
    //
    //Get the logger.
    const logger = args2?.logger ?? getNullLogger();
    //
    //Get the storage provider.
    const storageProvider = getStorageProvider(args.storage, args.getCustomStorageProvider, logger);
    if (!storageProvider) {
        logger.error("Tracker - No storage provider was resolved. This is required in order to create a tracker.", { settings: args });
        return undefined;
    }
    //
    //Get the repository.
    const repository = getTrackingDataRepository(storageProvider, {
        logger: logger,
        subscriptions: args.subscriptions
    });
    if (!repository) {
        logger.error("Tracker - No tracking data repository was resolved. This is required in order to create a tracker.", { settings: args });
        return undefined;
    }
    logger.debug("Tracker - Using repository type " + repository.type, repository);
    //
    //Get the session timeout value.
    if (!args.sessionTimeout || args.sessionTimeout < 0) {
        logger.debug("Tracker - Session timeout will be determined by the tracker.");
    }
    else {
        logger.debug(`Tracker - Session timeout ${args.sessionTimeout} minute(s).`);
    }
    //
    //Create the tracker.
    const settings3: DefaultTrackerSettings = {
        campaignParameters: args.campaignParameters,
        contextReaders: args.contextReaders,
        dispatchers: args.dispatchers,
        onDispatch: args.onDispatch,
        extensions: args.extensions,
        goalParameters: args.goalParameters,
        logger: logger,
        repository: repository,
        sessionTimeout: args.sessionTimeout,
        subscriptions: args.subscriptions
    };
    return new DefaultTracker("TRACKER", settings3);
}
