import React, { createContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { RenderingDefinition, SitecoreItem, SitecoreContextDataSource } from '@uniformdev/personalize';
import { getTestManager, TestManager, TestSettings, TestStateManager, GetTestManagerArgs } from '@uniformdev/personalize';
import { PersonalizationManager, PersonalizationManagerEvent, GetSitecorePersonalizationManagerArgs, getSitecorePersonalizationManager } from '@uniformdev/personalize';
import { getSitecoreEsiPersonalizationManager } from '@uniformdev/personalize';
import { Logger, getNullLogger } from '@uniformdev/common';
import { UniformCallback, UniformUnsubscribe, UniformWindow } from '@uniformdev/optimize-common-sitecore';
import { UniformCookieNames, getMaxExpirationDate } from '@uniformdev/tracking';

declare let window: UniformWindow;

export interface SitecorePersonalizationContextValue {
    item?: SitecoreItem;
    logger?: Logger;
    personalizationManager?: PersonalizationManager<RenderingDefinition, SitecoreItem>;
    timestamp: number;
}

export type PersonalizationMode = 'jss-esi' | 'default';

export const SitecorePersonalizationContext = createContext({} as SitecorePersonalizationContextValue);

export interface SitecorePersonalizationContextProviderProps {
    contextData: any;
    contextDataSource?: SitecoreContextDataSource;
    disabled?: boolean;
    logger?: Logger;
    personalizationMode?: PersonalizationMode;
    sitecoreApiKey?: string;
    sitecoreSiteName?: string;
    subscriptions?: (subscribe: (type: string | undefined, callback: UniformCallback<PersonalizationManagerEvent>) => UniformUnsubscribe) => void;
    testSettings?: TestSettings[];
}

function getTestManagerFromCookie(id: string, cookies: any, setCookie: any, deleteCookie: any, props: SitecorePersonalizationContextProviderProps, logger: Logger): TestManager {
    const {contextData, disabled} = props;
    const getValue = (settings: TestSettings): boolean|undefined => {
        if (!cookies) return;
        const cookie = cookies[UniformCookieNames.Testing];
        if (!cookie) return;
        const parts = cookie.split("|");
        if (parts.length != 2) return;
        if (parts[0] != settings.id) return;
        return (parts[1] === '1');
    }
    const testStateManager: TestStateManager = {
        getIsTestingStateKnown: (settings: TestSettings) => {
            return getValue(settings) != undefined;
        },
        getIsIncludedInTest: (settings: TestSettings) => {
            if (cookies) {
                const value = getValue(settings);
                return (value == true);
            }
            return false;
        },
        setIsIncludedInTest: (value: boolean, settings: TestSettings) => {
            if (settings?.id) {
                const value2 = `${settings.id}|${value}`;
                setCookie(UniformCookieNames.Testing, value2, {
                    path: "/",
                    expires: getMaxExpirationDate()
                });    
            }
        },
        setTestNotRunning: () => {
            if (cookies[UniformCookieNames.Testing]) {
                logger.debug("Sitecore personalization context -   * No personalization test is running so delete the testing cookie.", {cookie: cookies[UniformCookieNames.Testing]})
            }
            deleteCookie(UniformCookieNames.Testing);
        },
    }
    let testSettingsSource = "props";
    let testSettings = props.testSettings;
    if (!testSettings) {
        testSettingsSource = "context data";
        testSettings = contextData?.testing?.data;
    }
    logger.debug(`Sitecore personalization context -   * Using personalization test settings from ${testSettingsSource}.`, {testSettings, props, contextData});
    const args: GetTestManagerArgs = {
        testSettings: testSettings ?? [], 
        testStateManager,
        options: { disabled, logger }
    }
    return getTestManager(id, args);
}
function getPersonalizationManager(props: SitecorePersonalizationContextProviderProps, args: GetSitecorePersonalizationManagerArgs, logger: Logger): PersonalizationManager<RenderingDefinition, SitecoreItem> | undefined {
    const { personalizationMode = 'default', sitecoreApiKey = '', sitecoreSiteName = '' } = props;
    logger.debug("Sitecore personalization context -   * Getting personalization manager based on personalization mode.", { personalizationMode });
    if (personalizationMode == 'jss-esi') {
        return getSitecoreEsiPersonalizationManager({...args, sitecoreApiKey, sitecoreSiteName });
    }
    return getSitecorePersonalizationManager(args);
}

export const SitecorePersonalizationContextProvider: React.FC<SitecorePersonalizationContextProviderProps> = (props) => {
    const {
        children,
        contextData,
        contextDataSource,
        disabled = false,
        logger = getNullLogger(),
        subscriptions,
    } = props;
    const tracking = contextData?.tracking;
    const itemId = tracking?.item?.id;
    //
    //Create test manager.
    const [cookies, setCookie, deleteCookie] = useCookies([]);
    //
    //Create personalization manager.
    const [personalizationManager, setPersonalizationManager] = useState<PersonalizationManager<RenderingDefinition, SitecoreItem>>();
    useEffect(() => {
        if (!itemId) return;
        logger.debug("Sitecore personalization context - START EFFECT: Item id changed.", { itemId });
        const testManager = getTestManagerFromCookie("PERS_CONTEXT_TEST_MGR", cookies, setCookie, deleteCookie, props, logger);
        if (!testManager) {
            logger.debug("Sitecore personalization context -   * No test manager was resolved from cookie.", { cookies: {...cookies} });
        }
        else {
            logger.debug("Sitecore personalization context -   * Test manager was resolved from cookie.", { id: testManager.id });
        }
        const args2: GetSitecorePersonalizationManagerArgs = {
            contextData,
            contextDataSource,
            disabled,
            logger,
            testManager
        }
        //
        //   
        const manager = getPersonalizationManager(props, args2, logger);
        if (!manager) {
            logger.debug("Sitecore personalization context -   * No personalization manager was resolved.", { props, args: args2 });
            setPersonalizationManager(() => undefined);
        }
        else {
            logger.debug("Sitecore personalization context -   * Personalization manager was resolved.", { id: manager.id });
            if (!subscriptions) {
                logger.debug("Sitecore personalization context -   * No subscriptions are set on props, so no subscriptions will be added to personalization manager.", { props });
            }
            else {
                logger.debug("Sitecore personalization context -   * Adding subscriptions from props to personalization manager.", { manager: manager.subscriptions.id, props, subscriptionTypes: manager.subscriptions.getSubscriptionTypes() });
                subscriptions(manager.subscriptions.subscribe);
            }
            logger.debug("Sitecore personalization context -   * Personalization manager set in state on the context.", { id: manager?.id, manager: manager?.subscriptions.id, props, args2});
            setPersonalizationManager(() => manager);    
        }
        logger.debug("Sitecore personalization context - END EFFECT: Item id changed.");
    }, [itemId]);
    //
    //
    const value: SitecorePersonalizationContextValue = {
        item: personalizationManager?.page,
        logger,
        personalizationManager,
        timestamp: 0,
    }
    return (
        <SitecorePersonalizationContext.Provider value={value}>
            {children}
        </SitecorePersonalizationContext.Provider>
    );
};
