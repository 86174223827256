import { ComponentChangedResult, PersonalizationTriggers } from '../index';

/**
 * Represents a condition that, when true, results
 * in the data provided to a component changing.
 */
export interface PersonalizationRule {
    id: string | null | undefined;
    name: string;
    condition: string;
    dependencies?: string[];
    data: string;
    component?: string;
}

// /**
//  * Personalization may need to be deferred until some
//  * condition is met (e.g. external data is retrieved
//  * or tracking has finished). This type provides the
//  * ability to describe such a condition.
//  */
// export interface PersonalizationRuleDependency {
//     trigger: string;
// }

/**
 * Evaluates rule conditions and runs rule actions.
 */
export interface PersonalizationRuleManager<TContext> {
    getFirstMatchingRule: (context: TContext, triggers?: string[]) => Promise<PersonalizationRule | null>;
    getRulesForRendering: (context: TContext) => Array<PersonalizationRule> | null;
    renderingHasPersonalizationRules: (context: TContext) => boolean;
    runRuleActions: (
        rule: PersonalizationRule,
        context: TContext,
    ) => Promise<ComponentChangedResult>;
    triggers?: PersonalizationTriggers;
}


/**
 * Gets a manager that doesn't actually personalize.
 */
export function getDisabledPersonalizationRuleManager<TContext>(): PersonalizationRuleManager<TContext> {
    return {
        getFirstMatchingRule: async (_context, _triggers?: string[]) => null,
        getRulesForRendering: (_context) => null,
        renderingHasPersonalizationRules: (_context) => false,
        runRuleActions: async (rule, _context) => {
            return { 
                changed: false, 
                component: {
                    id: '',
                    description: ''
                },
                dataSource: '', 
                fields: null,
                rule
            }
        },
        triggers: undefined
    };
}

export * from './sitecore';