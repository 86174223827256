import { Visit } from '../models/visit';
import { Visitor } from '../models/visitor';
import { TrackedActivityResults } from '../models/trackedActivity';

export function getVisitChangesFromResults(activity: TrackedActivityResults, visit: Visit, _visitor: Visitor): Map<string, string[]> {
    const changes:string[] = [];
    if (activity?.visitActivities?.length > 0 || activity?.visitUpdates?.length > 0) {
        changes.push("activities");
    }
    if (activity?.visitUpdates?.length > 0) {
        activity.visitUpdates.forEach(update => {
            if (! changes.includes(update.type)) {
                changes.push(update.type);
            }
        });
    }
    const map = new Map<string, string[]>();
    map.set(visit.id, changes);
    return map;
}

export function getVisitChangesFromVisits(visits: Visit[], _visitor: Visitor): Map<string, string[]> {
    const map = new Map<string, string[]>();
    visits?.forEach(visit => {
        if (!visit?.data) return;
        const changes: string[] = [];
        if (Array.isArray(visit.data.activities)) {
            visit.data.activities.forEach((activity: { type?: string; }) => {
                if (activity?.type && changes.indexOf(activity.type) == -1) {
                    changes.push(activity.type);
                }
            });
        }
        if (visit.data.campaign) {
            changes.push("campaign");
        }
        map.set(visit.id, changes);
    })
    return map;
}

export function getVisitorChanges(activity: TrackedActivityResults, _visit: Visit, _visitor: Visitor): string[] {
    const changes: string[] = [];
    if (activity?.visitorUpdates?.length > 0) {
        activity.visitorUpdates.forEach(update => {
            if (! changes.includes(update.type)) {
                changes.push(update.type);
            }
        });
    }
    return changes;
}
