import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Logger, getNullLogger } from '@uniformdev/common';
import { flattenArray, UniformUnsubscribe } from '@uniformdev/optimize-common-sitecore';
import { PersonalizationManagerEvent, RenderingDefinition } from '@uniformdev/personalize';
import { SitecorePersonalizationContext } from './SitecorePersonalizationContext';
import { UniformContext } from '@uniformdev/optimize-react';
import { getNullTracker, PersonalizationEventData, Tracker, VisitActivity, TrackingSettings, TrackedActivityResults } from '@uniformdev/tracking';
import { TrackerContext } from '@uniformdev/tracking-react';

interface PersonalizationProps extends RenderingDefinition {
  rendering: RenderingDefinition;
  fields: any;
}

export interface GetPersonalizedProps { (data: any, logger: Logger): GetPersonalizedPropsResult }
export interface GetPersonalizedPropsResult { props?: any, event?: PersonalizationEventData }

interface GetPersonalizationArgs {
  /**
   * Collection of component names that support personalization.
   * If no values are specified, all components are supported.
   */
  allowed?: string[];
  components: Map<string, any>;
  getPersonalizedProps: GetPersonalizedProps;
}

function isPersonalizable(rendering: RenderingDefinition, args: GetPersonalizationArgs): boolean {
  const { allowed } = args;
  if (!allowed || allowed.length == 0) {
    return true;
  }
  return allowed.indexOf(rendering.componentName) > -1;
}

function doPersonalizationOnStateChange(rendering: RenderingDefinition, e: PersonalizationManagerEvent, _getPersonalizedProps: GetPersonalizedProps, tracker: Tracker, visitorId: string|undefined, logger: Logger): any {
  logger.debug("Personalizer component -   * Getting personalized props due to state change.", { rendering: rendering.uid, event: e });
  const result1: GetPersonalizedPropsResult = {
    props: e.data,
    event: e.activity
  }
  // const result2 = getPersonalizedProps(e.personalizedData, logger);
  // //
  // //TODO: More testing is needed to ensure the right values are being used in all cases.
  // logger.debug("Personalizer component - ********** TEMP **********", { result1, result2 });
  // // //
  // // //The event may have activity...
  // // if (e.activity && Object.keys(e.activity).length > 0) {
  // //   if (result.event && Object.keys(result.event).length > 0) {
  // //     logger.debug("Personalizer component - Activity is set on the state-change event and an event was retrieved with personalized props. Activity from the event will be used.", { "from event": e.activity, "from result": result.event });
  // //   }
  // //   else {
  // //     logger.debug("Personalizer component - Activity from the state-change event will be used.", { activity: e.activity });
  // //   }
  // //   result.event = e.activity;
  // // }
  //
  //
  return doPersonalization(rendering, result1, tracker, visitorId, logger);
}

function doPersonalizationOnRouteChange(rendering: RenderingDefinition, getPersonalizedProps: GetPersonalizedProps, tracker: Tracker, visitorId: string|undefined, logger: Logger): any {
  logger.debug("Personalizer component -   * Getting personalized props due to route change.", { rendering: rendering.uid });
  const result = getPersonalizedProps(rendering, logger);
  logger.debug("Personalizer component -   * Apply personalized props.", { rendering: rendering.uid, result });
  return doPersonalization(rendering, result, tracker, visitorId, logger);
}

function doPersonalization(rendering: RenderingDefinition, result: GetPersonalizedPropsResult, tracker: Tracker, visitorId: string|undefined, logger: Logger): any {
  if (!result?.props?.personalization) {
    logger.debug("Personalizer component -   * No personalization details are available on the rendering, so there is no personalization to track.", { rendering: rendering.uid, result });
  }
  else if (!visitorId) {
    logger.error("Personalizer component -   * No visitor id is available, so personalization cannot be tracked.", { rendering: rendering.uid });
  }
  else {
    const activity: VisitActivity = {
      type: "personalization",
      date: new Date().toISOString(),
      data: result?.props?.personalization
    }
    const settings: TrackingSettings = { silent: true, visitorId };
    logger.debug("Personalizer component -   START (async): track personalization activity.", { rendering: rendering.uid, activity, settings });
    tracker.event('visit-activity', activity, settings).then(function(eventResult: TrackedActivityResults) {
      logger.debug("Personalizer component -   END (async): track personalization activity.", { rendering: rendering.uid, eventResult });
    });
  }
  return result?.props;
}

export function getPersonalizer(args: GetPersonalizationArgs) {
  const { components, getPersonalizedProps } = args;
  const Personalizer = (props: PersonalizationProps) => {
    //
    //prop variables
    const rendering = props?.rendering;
    //
    //global context variables
    const gContext = useContext(UniformContext);
    //
    //personalization context variables
    const pContext = useContext(SitecorePersonalizationContext);
    const logger = pContext?.logger ?? getNullLogger();
    const personalizationManager = pContext?.personalizationManager;
    //
    //tracker context variables
    const tContext = useContext(TrackerContext);
    const tracker = tContext?.tracker ?? getNullTracker();
    const visitorId = tContext?.visitorId;
    //
    //state variables
    const [personalizedProps, setPersonalizedProps] = useState<any>(rendering);

    function onStateChanged(e: PersonalizationManagerEvent) {
      if (!e) return;
      if (e.component != rendering.uid) return;
      if ( (!e.changes?.data) && (!e.changes?.component) && (!e.data) ) return;
      //
      //
      logger.debug("Personalizer component - START: onStateChanged()", { event: e, rendering: rendering.uid });
      //
      //Handle client-side personalization.
      if (e.changes?.data || e.changes?.component) {
        logger.debug("Personalizer component -   * State-changed event received from personalization manager. Trigger personalization.", { rendering: rendering.uid, event: e, personalizationManager: personalizationManager?.id });
        const newProps = doPersonalizationOnStateChange(rendering, e, getPersonalizedProps, tracker, visitorId, logger);
        if (!newProps) {
          logger.debug("Personalizer component -   * No new props were returned from the trigger-initiated personalization process.", { rendering: rendering.uid, currentProps: personalizedProps });
        }
        else {
          logger.debug("Personalizer component -   * New props were returned from the trigger-initiated personalization process. Set these props in component state.", { rendering: rendering.uid, newProps, currentProps: personalizedProps });
          setPersonalizedProps(() => newProps);
        }
      }
      //
      //Handle server-side personalization.
      else if (e.data) {
        const newProps = e.data;
        logger.debug("Personalizer component -   * No changes were reported in the event, so do not trigger personalization, but personalized props should still be updated because server-side rendering may have updated the props. This can happen when server-side rendering results in personalization.", { rendering: rendering.uid, newProps, currentProps: personalizedProps });
        setPersonalizedProps(() => newProps);
      }
      logger.debug("Personalizer component - END: onStateChanged()", { event: e, rendering: rendering.uid });
    }

    function doUpdate() {
      if (!rendering?.uid) return;
      if (!personalizationManager) return;
      if (!visitorId) return;
      logger.debug("Personalizer component -   * Activate personalization for the component.", { rendering: rendering.uid, personalizationManager: personalizationManager.id, visitorId });
      if (!isPersonalizable(rendering, args)) {
        logger.debug("Personalizer component -   * The component is not on the whitelist of components that support personalization. Personalization will not be configured for this component.", { whitelist: args.allowed, rendering });
        return;
      }
      //
      //no triggers
      const triggersOnRendering = personalizationManager.triggers ? personalizationManager.triggers[rendering.uid] : undefined;
      if (!triggersOnRendering) {
        logger.debug("Personalizer component -   * No triggers are defined on the personalization manager. This probably means the component is either not personalized, or is personalized but none of the personalization rules has any dependencies. Will attempt to get personalized props that might have been set on props.", { rendering: rendering.uid, props, args });
        const newProps = doPersonalizationOnRouteChange(rendering, getPersonalizedProps, tracker, visitorId, logger);
        if (newProps) {
          logger.debug("Personalizer component -   * New props were returned during route-change personalization. Set these props in component state.", { rendering: rendering.uid, newProps });
          setPersonalizedProps(() => newProps);
        }
        return;
      }
      //
      //
      if (!Array.isArray(triggersOnRendering)) {
        logger.error("Personalizer component -   * Triggers defined on the personalization manager is not an array. This probably means the tracking data was corrupted or the service that generated the tracking data is out of sync with components used in the front-end code.", { rendering: rendering.uid, triggers: triggersOnRendering });
        return;
      }
      //
      //Add subscriptions to start the personalization
      //process when a trigger event happens.
      const gContextUnsubs: { type: string, unsubscribe: UniformUnsubscribe }[] = [];
      const triggers: string[] = [];
      flattenArray(triggersOnRendering, triggers);
      const gContextSubs = gContext.subscriptions;
      if (gContextSubs) {
        triggers.forEach(trigger => {
          logger.debug("Personalizer component -   * Subscribe to the trigger event on the global context subscription manager. When the event is published, start the personalization process.", { rendering: rendering.uid, manager: gContextSubs.id, trigger, triggers, personalizationManager: personalizationManager.id });
          const unsubscribe = gContextSubs.subscribe(trigger, () => {
            logger.debug("Personalizer component - Trigger event received from Uniform global context. Call handler on personalization manager.", { rendering: rendering.uid, manager: gContextSubs.id, trigger, personalizationManager: personalizationManager.id });
            personalizationManager.onTrigger(trigger, rendering);
          });
          gContextUnsubs.push({ type: trigger, unsubscribe });
        });
      }
      else {
        logger.error("Personalizer component -   * No subscription manager is set on the global context so trigger-based personalization cannot be activated.", { rendering: rendering.uid, "global context": gContext });
      }
      //
      //Add subscription to handle state changes on the rendering.
      //The subscription will be notified when the state of ANY
      //rendering changes, so conditional logic must check that
      //the notification applies to the rendering associated with
      //this personalizer.
      const pmUnsubs: { type: string, unsubscribe: UniformUnsubscribe }[] = [];
      logger.debug("Personalizer component -   * Subscribe to the state-changed event on the personalization manager subscription manager. When the component's state changes, handle personalized props.", { rendering: rendering.uid });
      const pmUnsubscribe = personalizationManager.subscriptions.subscribe('state-changed', onStateChanged);
      pmUnsubs.push({ type: 'state-changed', unsubscribe: pmUnsubscribe});
      //
      //
      return function cleanup() {
        logger.debug("Personalizer component - CLEANUP EFFECT: Rendering needs refresh.");
        gContextUnsubs.forEach(entry => {
          logger.debug("Personalizer component -   * Removing subscriber from global context subscription manager that handles trigger events.", {
            rendering: rendering.uid,
            trigger: entry.type,
            subscription: entry.unsubscribe.id,
            subscriptionManager: gContext.subscriptions?.id
          });
          entry.unsubscribe.unsub();
        });
        pmUnsubs.forEach(entry => {
          logger.debug("Personalizer component -   * Remove subscriber from personalization manager that handles component state changes.", {
            rendering: rendering.uid,
            type: entry.type,
            subscription: entry.unsubscribe.id,
            subscriptionManager: personalizationManager.subscriptions.id,
            personalizationManager: personalizationManager.id,
          });
          entry.unsubscribe.unsub();
        });
      }
    }
    //
    //route-change effect
    useEffect(() => {
      if (!rendering?.uid) return;
      if (!personalizationManager) return;
      if (!visitorId) return;
      logger.debug("Personalizer component - START EFFECT: Route changed.", { rendering: rendering.uid });
      const cleanup = doUpdate();
      logger.debug("Personalizer component - END EFFECT: Route changed.", { rendering: rendering.uid });
      return cleanup;
    }, [rendering?.uid, personalizationManager, visitorId]);
    //
    //
    useEffect(() => {
      if (tContext?.needsRefresh !== true) return;
      logger.debug("Personalizer component - START EFFECT: Rendering needs refresh.", { rendering: rendering.uid });
      //TODO: Update the content and capture the origin-based personalization but do not track other data.
      logger.debug("Personalizer component -   * Renderings were refreshed so reset the flag on the tracker context.", { rendering: rendering.uid });
      tContext.needsRefresh = false;
      logger.debug("Personalizer component - END EFFECT: Rendering needs refresh.", { rendering: rendering.uid });
    }, [tContext.needsRefresh]);
    //
    //
    const component = components.get(rendering.componentName)
    const PersonalizedComponent = component;

    // Merge new personalized rendering props with original
    const componentProps = useMemo(() => {
        return {
            ...props,
            ...personalizedProps,
            rendering: personalizedProps
        }
    }, [props, personalizedProps])

    return <PersonalizedComponent {...componentProps} />;
  };
  return Personalizer;
}
