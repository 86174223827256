import { getNullLogger, Logger } from '@uniformdev/common';

export interface ClientScript {
    id: string;
    url: string;
    notAsync?: boolean;
}

export interface ClientScripts {
    [scriptId: string]: string;
}

export interface LoadClientScriptsArgs {
    callback?: () => void;
    cachedScripts?: ClientScripts;
    logger?: Logger;
}

export interface ClientScriptLoader {
    load: (scripts: ClientScripts, args: LoadClientScriptsArgs) => Promise<boolean>;
    type: string;
}

export function getClientScriptLoader(): ClientScriptLoader {
    return {
        type: 'default',
        load: loadClientScripts,
    };
}

async function loadClientScripts(scripts: ClientScripts, args: LoadClientScriptsArgs): Promise<boolean> {
    const { cachedScripts = {}, callback, logger = getNullLogger() } = args;
    //
    //Return if no urls were specified.
    const ids = Object.keys(scripts);
    if (ids.length == 0) {
        return true;
    }
    //
    //Return if all urls are already cached.
    const idsNotCached: string[] = [];
    ids.forEach((id) => {
        const cachedUrl = cachedScripts[id];
        if (cachedUrl != scripts[id]) {
            idsNotCached.push(id);
        }
    });
    if (idsNotCached.length == 0) {
        return true;
    }
    //
    //
    const promises: Promise<ClientScript>[] = [];
    idsNotCached.forEach((id) => {
        const url = scripts[id];
        promises.push(load.js({ id, url }));
    });
    try {
        const loadedScripts = await Promise.all(promises);
        loadedScripts.forEach((script) => {
            cachedScripts[script.id] = script.url;
        });
        if (callback) {
            logger.debug('Client script loader - Scripts were loaded so invoking callback.', { scripts });
            callback();
        }
        logger.debug('Client script loader - Finished loading scripts.', { scripts: loadedScripts });
        return true;
    } catch (err: any) {
        logger.error('Client script loader - Error while loading scripts.', { scripts });
        return false;
    }
}

const load = (function () {
    return {
        css: _load('link'),
        js: _load('script'),
        img: _load('img'),
    };
})();

function _load(tag: string) {
    return function (script: ClientScript) {
        return new Promise<ClientScript>(function (resolve, reject) {
            var element = document.createElement(tag);
            var parent = document.body;
            var attr = 'src';
            element.onload = function () {
                resolve(script);
            };
            element.onerror = function () {
                element.remove();
                reject(script);
            };
            switch (tag) {
                case 'script':
                    (element as HTMLScriptElement).async = script.notAsync == true ? false : true;
                    parent = document.head;
                    break;
                case 'link':
                    (element as HTMLLinkElement).type = 'text/css';
                    (element as HTMLLinkElement).rel = 'stylesheet';
                    attr = 'href';
                    parent = document.head;
            }
            const attr2 = document.createAttribute(attr);
            attr2.value = script.url;
            element.attributes.setNamedItem(attr2);
            parent.appendChild(element);
        });
    };
}
