"use strict";

import { Logger } from "@uniformdev/common";
import { Dispatcher, DispatchSettings } from "../../dispatchers";
import { TrackedActivityResults, TrackedActivity } from '../../models/trackedActivity';

interface DoCallbackTag { (containerId: string, callbackName: string): void }
interface AddPageCtx { (key: string, value: any): void }

declare global {
  interface Window { 
    bk_doCallbackTag: DoCallbackTag; 
    bk_addPageCtx: AddPageCtx; 
    bk_allow_multiple_calls: boolean;
    bk_use_multiple_iframes: boolean;
  }
}

export interface OracleDmpPhints {
  [key: string]: any
}

export interface OracleDmpTrackedActivityConverter { 
  type: string;
  convert(activity: TrackedActivity): OracleDmpPhints | undefined 
}

export interface OracleDmpDispatcherSettings {
  containerIds?: string[];
  /**
   * If activities is undefined, all activities are dispatched.
   * If activities is an empty array, no activities are dispatched.
   */
  activities?: string[];
}

export function getOracleDmpCallbackName(containerId: string): string | undefined {
  if (!containerId) {
    return;
  }
  return `callback_${containerId}`;
}

export class OracleDmpDispatcher implements Dispatcher {
  constructor(converters: OracleDmpTrackedActivityConverter[], settings?: OracleDmpDispatcherSettings) {
    this.activities = settings?.activities;
    this.converters = converters;
    this.containerIds = settings?.containerIds;
  }

  activities: string[] | undefined;
  converters: OracleDmpTrackedActivityConverter[];
  setCustomDimensionValues?: (results: TrackedActivityResults, values: Map<number, any>) => void;
  requiresBrowser: boolean = true;
  containerIds: string[] | undefined;
  type: string = "oracleDmp";

  dispatchActivity(results: TrackedActivityResults, settings: DispatchSettings, logger: Logger): void {
    //
    //
    if (!results) {
      logger.debug("Oracle DMP dispatcher - No tracked activity results are available to dispatch.", { settings });
      return;
    }
    //
    //
    if (this.containerIds && this.containerIds.length == 0) {
      logger.debug("Oracle DMP dispatcher - An empty array of tracking ids means dispatch is disabled.", { activity: results, settings });
      return;
    }
    //
    //Oracle DMP script must be loaded.
    if (!window.bk_doCallbackTag) {
      logger.error("Oracle DMP dispatcher - Oracle DMP tracking script has not been loaded. The tracking script must be loaded before phints can be dispatched. Dispatch aborted.", { containerIds: this.containerIds, activity: results, settings });
      return;
    }
    //
    //
    if (this.activities && this.activities.length == 0) {
      logger.debug("Oracle DMP dispatcher - An empty array of activities was specified so no activities will be dispatched.", { settings });
      return;
    }
    //
    //
    const phintsArray: OracleDmpPhints[] = [];
    results.visitActivities.forEach(activity => {
      if (this.activities && this.activities.indexOf(activity.type) == -1) {
        logger.debug("Oracle DMP dispatcher - The activity type was not selected as an activity to dispatch.", { type: activity.type, allowed: this.activities, activity, settings });
        return;
      }
      for(let i=0; i<this.converters.length; i++) {
        const phints = this.converters[i].convert(activity);
        if (!phints) {
          logger.debug("Oracle DMP dispatcher - The activity was not converted into a format that can be dispatched.", { activity, settings });
          continue;
        }
        logger.debug("Oracle DMP dispatcher - The activity was converted into a format that can be dispatched.", { activity, phints, settings });
        phintsArray.push(phints);
      }
    });
    //
    //
    if (phintsArray.length == 0) {
      logger.debug("Oracle DMP dispatcher - No Oracle DMP phints were resolved, so no phints will be dispatched.", { settings });
      return;
    }
    //
    //
    window.bk_allow_multiple_calls = true;
    window.bk_use_multiple_iframes = true;
    this.containerIds?.forEach(containerId => {
      logger.debug("Oracle DMP dispatcher - Adding phints to page context for container " + containerId, { phintsArray, settings });
      phintsArray.forEach(phints => {
        Object.keys(phints).forEach(key => {
          window.bk_addPageCtx(key, phints[key]);
        });
      });
      const callbackName = getOracleDmpCallbackName(containerId);
      if (!callbackName) {
        logger.error("Oracle DMP dispatcher - Unable to determine callback name for container " + containerId, { settings });
        return;
      }
      logger.debug("Oracle DMP dispatcher - Dispatching phints to container " + containerId, { callbackName, phintsArray, settings });
      window.bk_doCallbackTag(containerId, callbackName!);
    });
  }
}
