import { XdbDestination } from "../connectors/sitecore/destination";
import { XdbDispatcher } from "../connectors/sitecore/dispatcher";
import { Destination, Dispatcher } from "../dispatchers";
import { GetDispatchersArgs } from "./handleDestinations";

export function getDispatchersForXdbDestinations(destinations: Destination[], args: GetDispatchersArgs): Dispatcher[] {
    const { logger, loggerPrefix } = args;
    const dispatchers: Dispatcher[] = [];
    destinations.forEach(destination => {
        const dispatcher = getDispatcher(destination as XdbDestination, args);
        if (!dispatcher) {
            logger.error(loggerPrefix + " - Unable to get dispatcher for xDB destination.", destination);
            return;
        }
        dispatchers.push(dispatcher);
    })
    return dispatchers;
}

function getDispatcher(destination: XdbDestination, _args: GetDispatchersArgs): Dispatcher|undefined {
    if (!destination) {
        return;
    }
    const dispatcher = new XdbDispatcher(destination);
    return dispatcher;
}
