import { getNullLogger, Logger } from "@uniformdev/common";
import { GetPersonalizedProps, GetPersonalizedPropsResult } from '@uniformdev/personalize-react';

const getPersonalizedProps: GetPersonalizedProps = (data: any, logger: Logger): GetPersonalizedPropsResult => {
  logger.debug("getPersonalizedProps - Getting personalized props from data.", { data });
  if (!logger) logger = getNullLogger();
  if (!data) {
    logger.debug('getPersonalizedProps - No data was specified, so unable to retrieve personalized props for component.', { data });
    return {};
  }
  const { personalization, ...rest} = data;
  if (personalization) {
    logger.debug('getPersonalizedProps - Personalization activity is available in the data. This activity was removed because it will be handled later.', { before: data, after: rest });
  }
  return { props: rest };
}

export default getPersonalizedProps;
