import { TrackingEvent } from "./tracker";
import { defaultGetCookieName, defaultGetCookieValueFromVisit, defaultGetCookieValueFromVisitor, GetCookie, GetCookieName, GetValueForCookie, RemoveCookie, SetCookie, TrackerCookieSettings } from "../connectors/sitecore/cookies";
import { Visitor } from "../models";
import { Logger } from "@uniformdev/common";
import { SubscriptionManager } from "@uniformdev/optimize-common-sitecore";
import { getCookie, getMaxExpirationDate } from "../cookies";

export interface TrackerCookieArgs {
    cookieSettings?: TrackerCookieSettings;
    getCookie: GetCookie;
    getCookieName?: GetCookieName;
    getCookieValueFromVisitor?: GetValueForCookie;
    getCookieValueFromVisit?: GetValueForCookie;
    logger: Logger;
    loggerPrefix: string;
    removeCookie: RemoveCookie;
    setCookie: SetCookie;
}

export function getTrackerCookieSettings(trackingConfig: any): TrackerCookieSettings {
    const settings: TrackerCookieSettings = {
        visit: trackingConfig?.settings?.cookies?.visit,
        visitor: trackingConfig?.settings?.cookies?.visitor
    };
    return settings;
}

/**
 * Certain tracker data can be configured to be set in cookies.
 * This function adds subscriptions to the tracker to set those
 * cookies.
 * @param subs 
 * @param args
 */
export function addSubscriptionsForTrackerCookies(subs: SubscriptionManager<TrackingEvent>, args: TrackerCookieArgs) {
    const { logger, loggerPrefix } = args;
    logger.debug(`${loggerPrefix} - START: adding subscriptions for tracker cookies.`, { manager: subs.id });
    //
    //If no cookies are specified, no subscriptions to set cookies are needed.
    const { cookieSettings } = args;
    const visitCookieTypes = cookieSettings?.visit ?? [];
    const visitorCookieTypes = cookieSettings?.visitor ?? [];
    if (visitCookieTypes.length == 0 && visitorCookieTypes.length == 0) {
        logger.debug(`${loggerPrefix} -   * No tracker cookies are specified to be set, so there are no subscriptions to add.`);
    }
    else {
        logger.debug(`${loggerPrefix} -   * Adding subscription for tracking event.`, { manager: subs.id, event: "visit-created" });
        subs.subscribe("visit-created", e => {
            removeVisitCookiesOnTrackingEvent(e, args);
        });
        logger.debug(`${loggerPrefix} -   * Adding subscription for tracking event.`, { manager: subs.id, event: "visit-updated" });
        subs.subscribe("visit-updated", e => {
            updateCookiesOnTrackingEvent(e, args);
        });
        logger.debug(`${loggerPrefix} -   * Adding subscription for tracking event.`, { manager: subs.id, event: "visitor-updated" });
        subs.subscribe("visitor-updated", e => {
            updateCookiesOnTrackingEvent(e, args);
        });
    }
    logger.debug(`${loggerPrefix} - END: adding subscriptions for tracker cookies.`, { manager: subs.id });
}

function removeVisitCookiesOnTrackingEvent(e: TrackingEvent, args: TrackerCookieArgs): void {
    const { cookieSettings, logger, loggerPrefix } = args;
    logger.debug(`${loggerPrefix} - START: tracking event handler to remove visit tracking cookies.`, { type: e.type, e, args });
    if (!e.visitor) {
        logger.error(`${loggerPrefix} -   * No visitor is set on the tracking event. Unable to remove visit tracking cookies.`, { e, args });
    }
    else {
        //
        //Remove visit cookies.
        const visitCookieTypes = cookieSettings?.visit ?? [];
        if (visitCookieTypes.length == 0) {
            logger.debug(`${loggerPrefix} -   * No visit cookie types were specified in the cookie settings, so no visit tracking cookies will be removed.`, { e, args });
        }
        else {
            logger.debug(`${loggerPrefix} -   START: removing cookies.`, { e, args, visitCookieTypes });
            visitCookieTypes.forEach(cookieType => {
                removeVisitCookie(cookieType, args);
            });
            logger.debug(`${loggerPrefix} -   END: removing cookies.`, { e, args, visitCookieTypes });
        }
    }
    logger.debug(`${loggerPrefix} - END: tracking event handler to remove visit tracking cookies.`, { type: e.type, e, args });
}

function removeVisitCookie(cookieType: string, args: TrackerCookieArgs): void {
    const { logger, loggerPrefix, getCookie, removeCookie, getCookieName = defaultGetCookieName } = args;
    const cookieName = getCookieName(cookieType);
    if (!cookieName) {
        logger.error(`${loggerPrefix} -     * No cookie name was resolved for the specified cookie type, so no visit tracking cookie will be removed.`, { type: cookieType }); 
        return;   
    }
    const currentValue = getCookie(cookieName);
    if (!currentValue) return;
    logger.debug(`${loggerPrefix} -     * Removing existing visit tracking cookie.`, {type: cookieType, cookie: cookieName, value: currentValue});
    removeCookie(cookieName);
}

function updateCookiesOnTrackingEvent(e: TrackingEvent, args: TrackerCookieArgs): void {
    const { cookieSettings, logger, loggerPrefix, getCookieName = defaultGetCookieName, getCookieValueFromVisitor = defaultGetCookieValueFromVisitor, getCookieValueFromVisit = defaultGetCookieValueFromVisit } = args;
    logger.debug(`${loggerPrefix} - START: tracking event handler to set tracking cookies.`, { type: e.type, e, args });
    if (!e.visitor) {
        logger.error(`${loggerPrefix} -   * No visitor is set on the tracking event. Unable to set tracking cookies.`, { e, args });
        logger.debug(`${loggerPrefix} - END: tracking event handler to set tracking cookies.`, { type: e.type, e, args });
        return;
    }
    //
    //Get visitor cookie types.
    const visitorCookieTypes = cookieSettings?.visitor ?? [];
    if (visitorCookieTypes.length == 0) {
        logger.debug(`${loggerPrefix} -   * No visitor cookie types were specified in the cookie settings, so no visitor tracking cookies will be updated.`, { e, args });
    }
    else {
        logger.debug(`${loggerPrefix} -   START: iterating visitor cookie types.`, { visitorCookieTypes });
        visitorCookieTypes.forEach(cookieType => {
            const cookieName = getCookieName(cookieType);
            if (!cookieName) {
                logger.error(`${loggerPrefix} -     * No cookie name was resolved for the cookie type. Unable to set visitor tracking cookie.`, { e, args, type: cookieType });
                return;
            }
            const cookieValue = getCookieValueFromVisitor(cookieType, e.visitor!, logger, loggerPrefix);
            logger.debug(`${loggerPrefix} -     * Cookie value retrieved from visitor.`, { cookieValue, cookieType });
            if (cookieValue && !cookieValue.unsupportedCookieType) {
                updateTrackingCookie(CookieTarget.Visitor, cookieValue.value, cookieName, e.visitor!, args);    
            }
        });
        logger.debug(`${loggerPrefix} -   END: iterating visitor cookie types.`, { visitorCookieTypes });        
    }
    //
    //Get visit cookie types.
    const visitCookieTypes = cookieSettings?.visit ?? [];
    if (visitCookieTypes.length == 0) {
        logger.debug(`${loggerPrefix} -   * No visit cookie types were specified in the cookie settings, so no visit tracking cookies will be updated.`, { e, args });
    }
    else {
        logger.debug(`${loggerPrefix} -   START: iterating visit cookie types.`, { visitCookieTypes });
        visitCookieTypes.forEach(cookieType => {
            const cookieName = getCookieName(cookieType);
            if (!cookieName) {
                logger.error(`${loggerPrefix} -     * No cookie name was resolved for the cookie type. Unable to set visit tracking cookie.`, { e, args, type: cookieType });
                return;
            }
            const cookieValue = getCookieValueFromVisit(cookieType, e.visitor!, logger, loggerPrefix);
            logger.debug(`${loggerPrefix} -     * Cookie value retrieved from visit.`, { cookieValue, cookieType });
            if (cookieValue && !cookieValue.unsupportedCookieType) {
                updateTrackingCookie(CookieTarget.Visit, cookieValue.value, cookieName, e.visitor!, args);    
            }
        });
        logger.debug(`${loggerPrefix} -   END: iterating visit cookie types.`, { visitorCookieTypes });        
    }
    logger.debug(`${loggerPrefix} - END: tracking event handler to set tracking cookies.`, { type: e.type, e, args });
}

enum CookieTarget {
    Visitor = "visitor",
    Visit = "visit"
}

function updateTrackingCookie(target: CookieTarget, cookieValue: any, cookieName: string, visitor: Visitor, args: TrackerCookieArgs) {
    const { logger, loggerPrefix, removeCookie, setCookie } = args;
    const currentValue = getCookie(cookieName);
    if (!cookieValue) {
        if (currentValue) {
            logger.debug(`${loggerPrefix} - Removing the ${target} tracking cookie.`, {cookie: cookieName, currentValue, visitor});
            removeCookie(cookieName);
        }
        return;
    }
    if (currentValue == cookieValue) {
        return;
    }
    logger.debug(`${loggerPrefix} - Updating the ${target} tracking cookie.`, {cookie: cookieName, newValue: cookieValue, currentValue, visitor});
    var date = getMaxExpirationDate();
    setCookie(cookieName, cookieValue, {expires: date, path: "/"});
}
