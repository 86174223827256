import { getSitecoreTracker, GetSitecoreTrackerArgs, UniformWindow, StorageProviderType, Tracker, getCookie, setCookie, removeCookie, UniformCookieNames, addSubscriptionsForTrackerCookies, ContextReader, getDispatchersFromTrackingConfig, getTrackerCookieSettings, GetValueForCookie, GetCookieName, DispatchEventHandler, TrackingSettings, OnVisitorInitialized, Visit, Visitor, addVisits as addVisitsToVisitor } from '@uniformdev/tracking';
import { Logger, getNullLogger } from '@uniformdev/common';
import { ClientScripts, getClientScriptLoader } from '@uniformdev/common-client';
import { getSubscriptionManager, UniformEvent, SubscriptionManager, getDefaultConsoleLogger } from '@uniformdev/optimize-common-sitecore';
import axios from 'axios';

interface UniformTrackerWindow extends UniformWindow {
    ga: any | undefined;
}

declare let window: UniformTrackerWindow;

function getLogger(prefix: string, settings: DoTrackingSettings): Logger {
    if (settings?.debug !== true) return getNullLogger();
    if (settings.logger) return settings.logger;
    const logger = getDefaultConsoleLogger("optimize-tracker");
    logger.debug(`Uniform tracking - ${prefix} - No logger was set in the settings but debug is enabled so use the default console logger.`, { settings });
    return logger;
}

interface DoTrackingSettings {
    /**
     * If true, the debug logger is assigned to the tracker.
     */
    debug?: boolean;
    /**
     * 
     */
    logger?: Logger;
    /**
     * Provides extra information that can be used to initialize the tracker. 
     */
    mode?: string;
    /**
     * Name of a function to call when tracker activity is available to be dispatched.
     */
    onDispatch?: string;
    /**
     * Name of a function to call when the tracking finished event is fired.
     */
    onTrackingFinished?: string;
    /**
     * Name of a function to call when the visit created event is fired.
     */
    onVisitCreated?: string;
    /**
     * Name of a function to call when the visit update event is fired.
     */
    onVisitUpdated?: string;
    /**
     * Name of a function to call after the visitor is initialized.
     */
    onVisitorInitialized?: string;
    /**
     * Name of a function to call when the visitor update event is fired.
     */
    onVisitorUpdated?: string;
    /**
     * Names of the query string parameters that specify the campaign id.
     */
    campaignParameters?: string[];
    /**
     * Names of the query string parameters that specify the goal id.
     */
    goalParameters?: string[];
    /**
     * Number of minutes between visit activity that will result in a new visit being created.
     */
    sessionTimeout?: number;
    /**
     * Storage location for the visitor data collected by the tracker.
     */
    storage?: StorageProviderType;
    /**
     * Tells the tracker the source of the tracking data so the tracker can determine how to handle the data.
     */
    source: string;
    /**
     * Context passed to the tracker.
     */
    context?: any;
    /**
     * Context reader used to read data from the context.
     */
    contextReader?: ContextReader;
    /**
     * If true, the tracker will not publish any events to its subscribers.
     */
    silent?: boolean;
    /**
     * This functionallows you to override the default function
     * that determines the cookie name for a specified cookie type.
     */
    getCookieName?: GetCookieName;
    /**
     * This function allows you to override the default function
     * that determines the cookie value from the current visit.
     */
    getCookieValueFromVisit?: GetValueForCookie;
    /**
     * This function allows you to override the default function
     * that determines the cookie value from the visitor.
     */
    getCookieValueFromVisitor?: GetValueForCookie;
}

function shouldAddSubscriptionsForContextCookies(settings: DoTrackingSettings): boolean {
    return settings?.mode == "mvc";
}

function getTrackingConfig(settings: DoTrackingSettings, logger: Logger): any {
    if (settings?.context?.tracking) {
        logger.debug("Uniform tracking - initializeTracker - Using tracking config from settings to determine tracker cookie types.", { settings });
        return settings.context;
    }
    if (window.uniform?.tracking) {
        logger.debug("Uniform tracking - initializeTracker - Using tracking config from global object to determine tracker cookie types.", { global: window.uniform });
        return window.uniform.tracking;
    }
}

function addSubscriptionsForContextCookies(trackingConfig: any, subs: SubscriptionManager<UniformEvent>, settings: DoTrackingSettings, logger: Logger) {
    const cookieSettings = getTrackerCookieSettings(trackingConfig);
    addSubscriptionsForTrackerCookies(subs, {
        cookieSettings, 
        getCookieName: settings?.getCookieName,
        getCookieValueFromVisitor: settings?.getCookieValueFromVisitor,
        getCookieValueFromVisit: settings?.getCookieValueFromVisit,
        logger, 
        loggerPrefix: "Uniform tracking - initializeTracker", 
        getCookie,
        removeCookie, 
        setCookie
    });
}

export var gaNewElem : any = {};
export var gaElems : any = {};
function gaInit(){
    var currdate: any = new Date();
    (function(i: any,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*currdate;a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    })(window,document,'script','//www.google-analytics.com/analytics.js','ga', gaNewElem, gaElems);
}

function getFunction(name: string|undefined): any {
    if (!name) {
        return;
    }
    const parts = name.split('.');
    if (parts.length > 1 && parts[0] == "window") {
        parts.splice(0, 1);
    }
    let callback: any = window;
    for (let i = 0; i < parts.length; i++) {
        if (!callback) {
            break;
        }
        callback = callback[parts[i] as any] as any;
    }
    if (callback == undefined || typeof callback !== "function") {
        return;
    }
    return callback;
}

/**
 * Prepares the tracker and makes it available as a global JavaScript object.
 * No data is tracked when this function is called.
 * @param settings 
 */
export async function initializeTracker(settings: DoTrackingSettings): Promise<Tracker | undefined> {
    const logger = getLogger("initializeTracker", settings);
    //
    //Get tracking config from context.
    const trackingConfig = getTrackingConfig(settings, logger);
    if (!trackingConfig) {
        logger.error("Uniform tracking - initializeTracker - Unable to resolve tracking config so tracker cannot be initialized.", { settings });
        return Promise.resolve(undefined);
    }
    //
    //Load required scripts.
    if (trackingConfig.settings?.scripts) {
        const scripts = { ...trackingConfig.settings.scripts } as ClientScripts;
        //
        //If the optimize script is included, remove it because this is that script.
        if (scripts.optimize) {
            delete scripts.optimize;
            logger.debug("Uniform tracking - initializeTracker - Optimize script was removed from the collection of client scripts to load because that script is running this code.", { scripts });
        }
        const scriptLoader = getClientScriptLoader();
        if (!scriptLoader) {
            logger.error("Uniform tracking - initializeTracker - Unable to resolve script loader so tracker cannot be initialized.", { trackingConfig });
            return Promise.resolve(undefined);
        }
        try {
            await scriptLoader.load(scripts, { logger });
            logger.debug("Uniform tracking - initializeTracker - Scripts finished loading.", { scripts });
        }
        catch(error) {
            logger.error("Uniform tracking - initializeTracker - Error occurred while loading scripts so tracker cannot be initialized.", { trackingConfig, scripts, error });
            return Promise.reject(error);
        }
    }
    //
    //Add subscriptions.
    const addSubscription = (name: string|undefined, type: string) => {
        if (!name) return;
        const callback = getFunction(name);
        if (callback == undefined || typeof callback !== "function") {
            logger.error("Uniform tracking - initializeTracker - Unable to add a tracker subscription because the specified function does not exist.", { name, type });
            return;
        }
        if (callback) {
            subscriptions.subscribe(type, callback);
        }    
    }
    const subscriptions = getSubscriptionManager<UniformEvent>("OPTIMIZE_JS_TRACKER_SUBS", false, logger);
    //
    //Add handlers for setting context data in cookies.
    if (shouldAddSubscriptionsForContextCookies(settings)) {
        logger.debug("Uniform tracking - initializeTracker - Adding subscriptions for context cookies.", { settings });
        addSubscriptionsForContextCookies(trackingConfig, subscriptions, settings, logger);
    }
    //
    //Add custom handlers specified on the settings.
    addSubscription(settings.onTrackingFinished, "tracking-finished");
    addSubscription(settings.onVisitCreated, "visit-created");
    addSubscription(settings.onVisitUpdated, "visit-updated");
    addSubscription(settings.onVisitorUpdated, "visitor-updated");
    const onDispatch: DispatchEventHandler = getFunction(settings.onDispatch);
    //
    //Get dispatchers.
    const dispatchers = getDispatchersFromTrackingConfig(trackingConfig, { 
        getCookie,
        logger,
        loggerPrefix: "Uniform tracking - initializeTracker",
        removeCookie,
        setCookie,
        ga: {
            initializeGa: (destination, logger) => {
                if (!window.ga) {
                    logger.error("Uniform tracking - initializeTracker - The global function ga is not defined, so initializing the GA library.", { destination });
                    gaInit();
                }
                if (!window.ga) {
                    logger.error("Uniform tracking - initializeTracker - The global function ga is not defined, suggesting the GA library has not been loaded.", { destination });
                    return false;
                }
                if (!destination.trackingIds) {
                    logger.debug("Uniform tracking - initializeTracker - No tracking ids set on GA destination so no GA tracker objects will be created.", { destination });
                    return false;
                }
                destination.trackingIds.forEach(trackingId => {
                    try {
                        window.ga("create", trackingId, "auto");
                        logger.debug("Uniform tracking - initializeTracker - GA tracker object was created.", { trackingId, destination });
                    }
                    catch(error) {
                        logger.error("Uniform tracking - initializeTracker - Error while creating GA tracker object.", { trackingId, error });
                    }
                });
                return true;
            }
        }
    });
    //
    //
    const campaignParameters = settings.campaignParameters ?? trackingConfig.settings?.campaigns?.parameters;
    const goalParameters = settings.goalParameters ?? trackingConfig.settings?.goals?.parameters;
    const sessionTimeout = settings.sessionTimeout ?? trackingConfig.settings?.session?.timeout;
    //
    //
    const args: GetSitecoreTrackerArgs = {
        campaignParameters,
        dispatchers,
        onDispatch,
        goalParameters,
        sessionTimeout: sessionTimeout ?? 20, 
        storage: settings.storage ?? "default",
        subscriptions,
        type: "js"
    }
    const tracker = getSitecoreTracker(args, logger);
    if (!tracker) {
        logger.error("Uniform tracking - initializeTracker - No tracker was returned from getSitecoreTracker.", { args });
        return Promise.resolve(undefined);
    }
    //
    //Set the tracker on the global object.
    if (!window.uniform) {
        window.uniform = {};
    }
    window.uniform.tracker = tracker;
    return Promise.resolve(tracker);
}

/**
 * Tracks using tracking data from the global JavaScript object.
 * If the tracker is not already initialized, this function
 * initializes it.
 * @param settings 
 */
export function doTracking(settings: DoTrackingSettings) {
    const logger = getLogger("doTracking", settings);
    if (!window.uniform) {
        return;
    }
    const source = settings.source;
    if (!source) {
        logger.error("Uniform tracking - doTracking - No source was specified.", { settings });
        return;
    }
    const context = settings.context ?? window.uniform;
    if (!context) {
        logger.warn("Uniform tracking - doTracking - No context was was resolved.", { settings });
        return;
    }
    const useTracker = async (tracker: Tracker | undefined) => {
        if (!tracker) {
            logger.error("Uniform tracking - doTracking - No tracker is available.", { settings });
            return;
        }
        logger.debug("Uniform tracking - doTracking - Tracker is available.", { settings });
        let visitorId = getCookie(UniformCookieNames.VisitorId);
        logger.debug("Uniform tracking - doTracking - Visitor id was retrieved from cookie.", { visitorId, cookie: UniformCookieNames.VisitorId });
        let visitId = getCookie(UniformCookieNames.VisitId);
        logger.debug("Uniform tracking - doTracking - Visit id was retrieved from cookie.", { visitId, cookie: UniformCookieNames.VisitId });
        const trackingSettings: TrackingSettings = { visitId: visitId, visitorId: visitorId, createVisitor: true, silent: settings.silent };
        if (settings.onVisitorInitialized) {
            const callback = (window as any)[settings.onVisitorInitialized];
            if (!callback) {
                logger.error("Uniform tracking - doTracking - Handler for visitor initialization was specified but the function does not exist.", { onVisitorInitialized: settings.onVisitorInitialized });
            }
            else {
                trackingSettings.onVisitorInitialized = callback as OnVisitorInitialized;
                logger.debug("Uniform tracking - doTracking - Handler for visitor initialization set on tracking settings.", { onVisitorInitialized: settings.onVisitorInitialized });
            }
        }
        const results = await tracker.track(source, context, trackingSettings);
        logger.debug("Uniform tracking - doTracking - Tracking results were returned.", { results });
        //if (results && results.visitor) {
        if (results) {
            if (results.visitor) {
                setCookie(UniformCookieNames.VisitorId, results.visitor.id, 3650);
                logger.debug("Uniform tracking - doTracking - Visitor id cookie was updated.", { visitorId, cookie: UniformCookieNames.VisitorId });
            }
            if (results.visit) {
                setCookie(UniformCookieNames.VisitId, results.visit.id);
                logger.debug("Uniform tracking - doTracking - Visit id cookie was updated.", { visitorId, cookie: UniformCookieNames.VisitId });
            }
        }
    };
    if (window.uniform.tracker) {
        logger.debug("Uniform tracking - doTracking - Using the tracker set on the global object.", { settings });
        useTracker(window.uniform.tracker);
    }
    else {
        initializeTracker(settings).then(tracker => {
            logger.debug("Uniform tracking - doTracking - Using the newly initialized tracker.", { settings });
            useTracker(tracker).then(() => {
                logger.debug("Uniform tracking - doTracking - Tracking is finished.", { settings });
            });
        })
    }
}

export function addVisits(visits: Visit[], visitor: Visitor): Map<string, string[]> {
    return addVisitsToVisitor(visits, visitor);
}

export interface VisitorHistory {
    contact: {
        id: string;
    };
    visits: Visit[];
}

export async function getHistory(siteName: string, visitor: Visitor, logger: Logger): Promise<VisitorHistory> {
    if (!siteName) return Promise.reject("Sitecore site name must be specified.");
    if (!(visitor?.id)) return Promise.reject("Uniform visitor must be specified.");
    const response = await axios.get(`/uniform/api/content/${siteName}/xdb/history/${visitor.id}`);
    logger.debug("Uniform tracking - getHistory - Data retrieved.", { data: response.data });
    return Promise.resolve(response.data);
}
