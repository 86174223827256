type GuidFormat = 'N' | 'D' | 'B' | 'BU';

/**
 * Format N
 * 32 digits and lowercase
 * a0000000000000000000000000000000
 *
 * Format D
 * 32 digits separated by hyphens and lowercase
 * a0000000-0000-0000-0000-000000000000
 *
 * Format B
 * 32 digits separated by hyphens, enclosed in braces and lowercase
 * {a0000000-0000-0000-0000-000000000000}
 *
 * Format BU
 * 32 digits separated by hyphens, enclosed in braces and uppercase
 * {A0000000-0000-0000-0000-000000000000}
 * */
export function tryFormatGuid(value: string | undefined, format: GuidFormat): string {
    if (!value) return '';

    switch (format) {
        case 'N':
            return toFormatN(value.toLowerCase());
        case 'D':
            return toFormatD(value.toLowerCase());
        case 'B':
            return toFormatB(value.toLowerCase());
        case 'BU':
            return toFormatBU(value);
        default:
            throw new Error('format arg: unknown guid format');
    }
}

function toFormatN(value: string): string {
    let result = value.replace(/[{}-]/g, '');

    if (result.length !== 32) {
        return '';
    }

    return result;
}

function toFormatD(value: string): string {
    if (value.length === 36 && value[8] === '-') {
        return value;
    }

    const fN = toFormatN(value);
    if (!fN) return '';

    return `${fN.substring(0, 8)}-${fN.substring(8, 12)}-${fN.substring(12, 16)}-${fN.substring(
        16,
        20
    )}-${fN.substring(20, 32)}`;
}

function toFormatB(value: string): string {
    if (value.length === 38 && value[0] === '{' && value[9] === '-') {
        return value;
    }

    const fD = toFormatD(value);
    if (!fD) return '';

    return `{${fD}}`;
}

function toFormatBU(value: string): string {
    if (value.length === 38 && value[0] === '{' && value[9] === '-') {
        return value.toUpperCase();
    }

    const fD = toFormatD(value).toUpperCase();
    if (!fD) return '';

    return `{${fD}}`;
}
