import { getNullLogger, Logger } from '@uniformdev/common';
import { Visit, Visitor } from '../../models';
import { UniformWindow } from '../../trackers';

declare let window: UniformWindow;

export interface FilteredActivities {
    [activityId: string]: Date[];
}

export function getVisitActivities(type: string, visit: Visit, logger: Logger = getNullLogger(), loggerPrefix?: string): FilteredActivities {
    const result: FilteredActivities = {};
    if (visit) {
        const activities = visit.data?.activities;
        if (activities) {
            const filtered: any[] = activities.filter((a: any) => a.type == type);
            filtered.forEach(activity => {
                if (!activity.data?.id) {
                    logger.debug(`${loggerPrefix} - Activity was found on the visit but no id is set, so unable to include this activity in the activity results.`, { type, activity, visit: visit.id, visitor: visit.visitorId });
                    return;
                }
                if (!activity.date) {
                    logger.debug(`${loggerPrefix} - Activity was found on the visit but no date is set, so unable to include this activity in the activity results.`, { type, activity, visit: visit.id, visitor: visit.visitorId });
                    return;
                }
                const id = activity.data?.id;
                const date = new Date(activity.date);
                if (id && date) {
                    const dates = result[id] ?? [];
                    dates.push(date);
                    result[id] = dates;
                }
            });
        }
    }
    return result;
}

export function getVisitorActivities(type: string, visitor: Visitor, logger: Logger = getNullLogger(), loggerPrefix?: string): FilteredActivities {
    const result: FilteredActivities = {};
    if (visitor?.visits) {
        visitor.visits.forEach(visit => {
            const activities = getVisitActivities(type, visit, logger, loggerPrefix);
            if (activities) {
                Object.keys(activities).forEach(id => {
                    const dates = activities[id];
                    if (dates) {
                        if (!result[id]) {
                            result[id] = dates;
                        }
                        else {
                            const currentDates = result[id];
                            dates.forEach(date => {
                                if (currentDates.findIndex(d => d.getTime() === date.getTime()) == -1) {
                                    currentDates.push(date);
                                }
                            })
                        }
                    }
                })
            }
        })
    }
    return result;
}
