'use strict';

import { TrackingDataRepository, GetCurrentVisitResult, GetCurrentVisitSettings } from './repository';
import { Visit } from '../models/visit';
import { Visitor } from '../models/visitor';
import { getActiveVisits } from '../models/utils';
import { StorageProvider } from '../storage';
import { TrackingEvent } from "../trackers/tracker";
import { Logger, getNullLogger } from '@uniformdev/common';
import { SubscriptionManager } from '@uniformdev/optimize-common-sitecore';
import { v4 as uuid } from 'uuid';

export interface GetTrackingDataRepositorySettings {
    logger?: Logger;
    subscriptions?: SubscriptionManager<TrackingEvent>;
}

export function getTrackingDataRepository(storage: StorageProvider, settings?: GetTrackingDataRepositorySettings) : TrackingDataRepository | undefined {
    return new DefaultTrackingDataRepository(storage, settings?.subscriptions, settings?.logger);
}

class DefaultTrackingDataRepository implements TrackingDataRepository {
    constructor(storageProvider: StorageProvider, subscriptions?: SubscriptionManager<TrackingEvent>, logger?: Logger) {
        this.logger = logger ?? getNullLogger();
        this.storageProvider = storageProvider;
        this.subscriptions = subscriptions;
    }

    getNewVisitId(): string {
        return uuid();
    }
    
    getNewVisitorId(): string {
        return uuid();
    }
    
    type: string = "default";
    logger: Logger;
    storageProvider: StorageProvider;
    subscriptions?: SubscriptionManager<TrackingEvent>

    /**
     *
     * @param visitor - 
     * @param sessionTimeout - Number of minutes of inactivity before a new visit is created.
     * @param logger
     */
    getCurrentVisit(settings: GetCurrentVisitSettings): GetCurrentVisitResult {
        const { visitor, visitId, sessionTimeout } = settings;
        //
        //
        let previousVisit: Visit | undefined;
        const now = new Date();
        const activeVisits = getActiveVisits(visitor);
        if (activeVisits?.length > 0) {
            //
            //End any visits that have timed out.
            const visitsTimedOut: Visit[] = [];
            const visitsEnded: Visit[] = [];
            let currentVisit: Visit | undefined;
            activeVisits.forEach(visit => {
                //
                //Handle timed-out visits
                const timeoutDate = calculateTimeout(visit.updated, sessionTimeout);
                if (now >= timeoutDate) {
                    visit.end = timeoutDate.toISOString();
                    visitsTimedOut.push(visit);
                    if (visit.id == visitId) {
                        previousVisit = visit;
                    }
                    return;
                }
                //
                //Handle visits that should be ended because they aren't the current visit
                if (!visitId || visit.id != visitId) {
                    visit.end = visit.updated;
                    visitsEnded.push(visit);
                    return;
                }
                //
                //Handle the current visit
                currentVisit = visit;
                const diff = getDifference(timeoutDate, now);
                this.logger.debug('Default tracking data repository - Current visit is still active.', {
                    ttl: diff,
                    visit: visit,
                    now: now,
                    end: timeoutDate,
                });
            });
            //
            //Save the visitor, if needed
            if (visitsEnded.length > 0 || visitsTimedOut.length > 0) {
                this.saveVisitorNoUpdate(visitor);
            }
            //
            //Publish events.
            visitsEnded.forEach(visit => {
                this.logger.debug('Default tracking data repository - Visit ended.', visit);
                if (this.subscriptions) {
                    this.subscriptions.publish({
                        type: "visit-end",
                        when: now,
                        visit,
                        visitor,
                    });
                }
            });
            visitsTimedOut.forEach(visit => {
                this.logger.debug('Default tracking data repository - Visit timed out.', visit);
                if (this.subscriptions) {
                    this.subscriptions.publish({
                        type: "visit-timeout",
                        when: now,
                        visit,
                        visitor,
                    });
                }
            });
            if (currentVisit) {
                return {
                    current: currentVisit, 
                    previous: undefined, 
                    isNewVisit: false
                }
            }
        }
        //
        //Create a new visit.
        var newVisit = this.addVisit(visitor, now);
        //
        //Publish event.
        if (this.subscriptions) {
            this.subscriptions.publish({
                type: "visit-created",
                when: new Date(),
                visit: newVisit,
                visitor
            });
        }
        return {
            current: newVisit,
            previous: previousVisit,
            isNewVisit : true,
        }
    }

    /**
     * Get the specified visitor from persistent storage.
     * @param visitorId - Id of the visitor to retrieve.
     * @param logger
     */
    getVisitor(visitorId: string): Visitor | undefined {
        if (!this.storageProvider) {
            this.logger.error('Default tracking data repository - No storage provider is available, so unable to get data for visitor ' + visitorId);
            return undefined;
        }
        if (visitorId != undefined && visitorId != '') {
            const visitor = this.storageProvider.read(visitorId, this.logger);
            if (visitor) {
                return visitor;
            }
        }
        return undefined;
    }

    createVisitor(): Visitor | undefined {
        return this.doCreateVisitor(this.getNewVisitorId());
    }

    doCreateVisitor(visitorId: string): Visitor | undefined {
        const now = new Date();
        const visitor = new Visitor(visitorId, { updated: now.toISOString() });
        this.storageProvider.write(visitor, this.logger);
        this.logger.debug('Default tracking data repository - New visitor created.', { visitor });
        //
        //Publish event.
        if (this.subscriptions) {
            this.subscriptions.publish({
                type: "visitor-created",
                when: new Date(),
                visitor
            });
        }
        return visitor;
    }

    /**
     * Save the visitor but do not treat it as an update. 
     * No events are fired and no updated timestamp is
     * set. This is used in cases like when a visit is
     * determined to have timed out.
     * @param visitor 
     */
    saveVisitorNoUpdate(visitor: Visitor): void {
        this.storageProvider.write(visitor);
        this.logger.debug('Default tracking data repository - Visitor saved to the repository but no update events were fired.', visitor);
    }

    /**
     *
     * @param date
     * @param visitor
     * @param visitChanges
     * @param visitorChanges
     * @param logger
     */
    saveVisitor(date: Date, visitor: Visitor, visitChanges?: Map<string, string[]>, visitorChanges?: string[], silent?: boolean): void {
        //
        //
        visitor.updated = date.toISOString();
        this.storageProvider.write(visitor);
        this.logger.debug('Default tracking data repository - Visitor saved to the repository.', { visitor });
        //
        //
        if (!this.subscriptions) return;
        if (silent === true) {
            this.logger.debug("Default tracking data repository - Visitor saved in silent mode, so no events will be published.", { visitor });
            return;
        }
        const now = new Date();
        if (visitChanges && visitChanges.size > 0) {
            this.subscriptions.publish({
                type: "visit-updated",
                when: now,
                changes: visitChanges,
                visitor: visitor
            });
        }
        if (visitorChanges && visitorChanges.length > 0) {
            this.subscriptions.publish({
                type: "visitor-updated",
                when: now,
                changes: visitorChanges,
                visitor
            });
        }
    }

    /**
     * Creates a new visit and associates it with the specified visitor.
     * @param visitor - Visitor to associate with the visit.
     * @param when - 
     * @param logger - 
     */
    addVisit(visitor: Visitor, when: Date): Visit {
        const updated = when.toISOString();
        visitor.updated = updated;
        const visit: Visit = {
            id: this.getNewVisitId(),
            visitorId: visitor.id,
            start: updated,
            updated: updated,
            data: {}
        };
        this.logger.debug('Default tracking data repository - New visit created.', visit);
        if (!visitor.visits) {
            visitor.visits = [];
        }
        visitor.visits.push(visit);
        this.storageProvider.write(visitor);
        return visit;
    }
}

/**
 * Returns a new Date by adding the timeout to an existing date.
 * @param date - The date.
 * @param timeout - Number of minutes to add to the date.
 */
function calculateTimeout(date: Date | string, timeout: number): Date {
    if (typeof date === 'string') {
        date = new Date(date);
    }
    return new Date(date.getTime() + timeout * 1000 * 60);
}

/**
 *
 * @param date1
 * @param date2
 */
function getDifference(date1: Date | string, date2: Date | string): DateDiff {
    if (typeof date1 === 'string') {
        date1 = new Date(date1);
    }
    if (typeof date2 === 'string') {
        date2 = new Date(date2);
    }
    const diff = date1.getTime() - date2.getTime();
    const ms = diff % 1000;
    const sec = Math.floor((diff / 1000) % 60);
    const min = Math.floor((diff / 1000 / 60) % 60);
    const hours = Math.floor((diff / 1000 / 60 / 60) % 60);
    const days = Math.floor(diff / 1000 / 60 / 60 / 24);
    return { diff: diff, milliseconds: ms, seconds: sec, minutes: min, hours: hours, days: days };
}

interface DateDiff {
    diff: number;
    milliseconds: number;
    seconds: number;
    minutes: number;
    hours: number;
    days: number;
}
