'use strict';

import { Visit } from '../models/visit';
import { Visitor } from '../models/visitor';
import { TrackedActivityResults, TrackedActivity, TrackedActivityType } from '../models/trackedActivity';
import { ContextReader } from '../contextReader';
import { Logger } from '@uniformdev/common';
import { UniformUnsubscribe, UniformCallback, UniformEvent, Description, Change } from '@uniformdev/optimize-common-sitecore';
import { Dispatcher } from '../dispatchers';

export interface GetTrackingUrl { (): URL | undefined }
export interface OnVisitorInitialized { (visitor: Visitor, saveVisitor: (date: Date, visitor: Visitor, visitChanges?: Map<string, string[]>, visitorChanges?: string[]) => void, logger?: Logger): Promise<void> }
export type TrackerState = 'unknown' | 'ready' | 'tracking';

/**
 * Provides the ability to track visitor activity.
 */
export interface Tracker {
    /**
     * 
     */
    id: string;
    /**
     * 
     */
    version?: string;
    state: TrackerState;
    /**
     * Writes a tracked activity event directly to the tracker.
     * @param type Specifies the type of event being tracked so it can be assigned to the results correctly.
     * @param e The tracked activity event to track.
     * @param settings Values that affect how a specific track event is executed.
     */
    event(type: TrackedActivityType, e: TrackedActivity, settings: TrackingSettings): Promise<TrackedActivityResults>;
    /**
     * Initializes the tracker.
     * @param settings Values that affect how the tracker initialization is executed.
     */
    initialize(settings: TrackingSettings): Promise<TrackedActivityResults>;
    /**
     * 
     * @param type 
     * @param callback 
     */
    subscribe(type: TrackingEventType, callback: UniformCallback<TrackingEvent>): UniformUnsubscribe;
    /**
     * Uses information in the context to track visitor behavior.
     * @param source Identifies which context readers should be used to handle the context data.
     * @param context Data that context readers use to determine what to track.
     * @param settings Values that affect how a specific track event is executed.
     */
    track(source: string | undefined, context: any, settings: TrackingSettings): Promise<TrackedActivityResults>;
    /**
     * Provides the ability to read data from the context.
     * When the tracker receives data, it uses the readers
     * subscribed to the specified source.
     */
    contextReaders: Map<string, ContextReader[]>;
    /**
     * Provides the ability to dispatch activity to external systems.
     */
    dispatchers?: Dispatcher[];
    /**
     * Provides the ability to execute additional logic
     * at certain points during the tracking process.
     */
    extensions?: TrackerExtensionPoints;
}

/**
 * Settings used during the tracking process.
 */
export interface TrackingSettings {
    visitId?: string;
    visitorId?: string;
    createVisitor?: boolean;
    getUrl?: GetTrackingUrl;
    /**
     * This function is called after the tracker initializes 
     * the visitor. It enables code to run before the visit
     * is initialized (and before tracking happens). This
     * can be used to do things such as loading external
     * data that can be associated with the visitor.
     */
    onVisitorInitialized?: OnVisitorInitialized;
    /**
     * If true, the tracker will not publish any events to its subscribers.
     */
    silent?: boolean;
    /**
     * If true, the tracker was invoked to capture a specific event. 
     * This information is provided to dispatchers so they can determine
     * how to dispatch the activity.
     */
    event?: boolean;
}
export type TrackingEventType = 'visit-created' | 'visit-timeout' | 'visit-end' | 'visit-updated' | 'visitor-created' | 'visitor-updated' | 'tracking-finished';

export interface TrackingEvent extends UniformEvent {
    visit?: Visit;
    visitor?: Visitor;
    changes?: any;
}

export interface PersonalizationChanges {
    component?: Change;
    data?: Change;
}

export interface PersonalizationEventData {
    changes: PersonalizationChanges,
    component:Description,
    isIncludedInTest?: boolean,
    page: Description,
    rule: Description
}

export interface PersonalizationDetail {
    activity?: PersonalizationEventData;
    dependencies?: string[];
}

export interface PersonalizationDetails {
    [key: string]: PersonalizationDetail;
}

/**
 * Event handlers that are called by trackers to handle. 
 * tracker-specific requirements. Client applications
 * should not use them.
 */
export interface TrackerExtensionPoints {
    onNewVisitCreated?: (date: Date, visitor: Visitor, oldVisit: Visit | undefined, newVisit: Visit, logger: Logger) => void;
    onBeforeVisitorSaved?: (date: Date, visitor: Visitor, visitChanges: Map<string, string[]>, visitorChanges: string[], logger: Logger) => void;
}

export enum UniformCookieNames {
    Testing = "UNIFORM_TRACKER_testing",
    VisitId = "UNIFORM_TRACKER_visit_id",
    VisitorId = "UNIFORM_TRACKER_visitor_id",
    VisitCount = "UNIFORM_TRACKER_visit_count"
}
