import { getCookie, setCookie, removeCookie } from '@uniformdev/tracking';

export { getCookie, setCookie, removeCookie };

export function getCookieValues(name: string): string[] {
    const value = getCookie(name);
    if (value) {
        return value.split(",");
    }
    return [];
}