import { getSubscriptionManager, UniformUnsubscribe, UniformCallback } from './subscriptions';
import { UniformEvent } from './index';

/**
 * First-in first-out data structure that can notify 
 * subscribers when new entries are added.
 * 
 * An example of where the queue is used is in 
 * personalization and tracking. When personalization
 * runs, the tracker may not yet be initialized, but
 * personalization may result in trackable activity.
 * Personalization can add its activity to the queue.
 * When the tracker is available, it can look in the
 * queue for any activity that was created before it 
 * was initialized.
 */
export interface UniformQueue {
    id: string;
    add(type: string, entry: any): void;
    get(type: string): any;
    count(type: string): number;
    subscribe(type: string, callback: UniformCallback<QueueEvent>): UniformUnsubscribe;
}

/**
 * Event that is published when an entry is added to a queue.
 */
export interface QueueEvent extends UniformEvent {
    entry: any;
}

/**
 * Gets a new queue object.
 */
export function getUniformQueue(id: string): UniformQueue {
    const map = new Map<string, any[]>();
    const subscriptions = getSubscriptionManager<QueueEvent>(`${id}_SUBS`);
    
    return {
        id,
        add(type: string, entry: any) {
            const values = map.get(type) ?? [];
            if (values.indexOf(entry) == -1) {
                values?.push(entry);
                subscriptions.publish({
                    type,
                    when: new Date(),
                    entry
                });
            }
            map.set(type, values);
        },
        get(type: string): any {
            const values = map.get(type);
            if (!values) {
                return undefined;
            }
            return values.shift();
        },
        count(type: string): number {
            const values = map.get(type);
            if (!values) {
                return 0;
            }
            return values.length;
        },
        subscribe(type: string, callback: UniformCallback<QueueEvent>): UniformUnsubscribe {
            return subscriptions.subscribe(type, callback);
        }
    }
}