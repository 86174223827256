export interface ProfileDefinition {
    type: string;
    decay: number;
    keys: ProfileKeysDefinition;
}

export interface ProfileKeysDefinition {
    [profileKeyId: string]: ProfileKeyDefinition
}

export interface ProfileKeyDefinition {
    name: string;
    value: number;
}

export interface ProfileScores {
    profileId: string;
    keys: ProfileKeyScores;
    updateCount: number;
    scoresChanged: boolean;
}

export interface ProfileKeyScores {
    [profileKeyId: string]: number
}

export interface Scorer { (currentScores: ProfileKeyScores, profileId: string, profile: ProfileDefinition, updateCount: number): ProfileScores }

export function getScorer(type: string): Scorer | undefined {
    switch (type) {
        case "Average":
            return getScorerAverage();
        case "Sum":
            return getScorerSum();
        case "Percentage":
            return getScorerPercentage();
        default:
            return undefined;
    }
}

export function getScorerSum(): Scorer {
    return (currentScores: ProfileKeyScores, profileId: string, profile: ProfileDefinition, updateCount: number) => {
        const newScores = getNormalizedScores(currentScores, profile.keys);
        let scoresChanged = false;
        Object.keys(profile.keys).forEach(profileKeyId => {
            const profileKey = profile.keys[profileKeyId];
            if (profileKey.value != 0) {
                scoresChanged = true;
            }
            newScores[profileKeyId] += profileKey.value;
        })
        return {
            profileId,
            keys: newScores,
            updateCount: updateCount + 1,
            scoresChanged
        }
    }
}

export function getScorerAverage(): Scorer {
    return (currentScores: ProfileKeyScores, profileId: string, profile: ProfileDefinition, updateCount: number) => {
        const newScores = getNormalizedScores(currentScores, profile.keys);
        let scoresChanged = false;
        Object.keys(profile.keys).forEach(profileKeyId => {
            const profileKey = profile.keys[profileKeyId];
            if (profileKey.value != 0) {
                scoresChanged = true;
            }
            newScores[profileKeyId] = ((newScores[profileKeyId] * updateCount) + profileKey.value)/(updateCount + 1);
        })
        return {
            profileId,
            keys: newScores,
            updateCount: updateCount + 1,
            scoresChanged
        }
    }
}

export function getScorerPercentage(): Scorer {
    return (currentScores: ProfileKeyScores, profileId: string, profile: ProfileDefinition, updateCount: number) => {
        let sum = 0;
        Object.keys(profile.keys).forEach(profileKeyId => {
            const profileKey = profile.keys[profileKeyId];
            sum += profileKey.value;
        })
        const newScores = getNormalizedScores(currentScores, profile.keys);
        const newUpdateCount = sum > 0 ? updateCount + 1 : updateCount;
        let scoresChanged = false;
        if (sum > 0) {
            Object.keys(profile.keys).forEach(profileKeyId => {
                const profileKey = profile.keys[profileKeyId];
                if (profileKey.value != 0) {
                    scoresChanged = true;
                }
                newScores[profileKeyId] = ((newScores[profileKeyId] * updateCount) + (profileKey.value/sum))/newUpdateCount;
            })
        }
        return {
            profileId,
            keys: newScores,
            updateCount: newUpdateCount,
            scoresChanged
        }
    }
}

export function getNormalizedScores(scores: ProfileKeyScores, profileKeys: ProfileKeysDefinition): ProfileKeyScores {
    const normalizedScores = {} as ProfileKeyScores;
    Object.keys(profileKeys).forEach(profileKeyId => {
        const currentScore = scores[profileKeyId] ?? 0;
        normalizedScores[profileKeyId] = currentScore;
    });
    return normalizedScores;
}

