"use strict";

import { TrackedActivity } from '../../models/trackedActivity';
import { PageEvent, XdbTrackedActivityConverter } from "./dispatcher";


/**
 * Converts Sitecore-specific events into events for Sitecore xDB.
 */
export function getXdbTrackedActivityConverterForSitecore() : XdbTrackedActivityConverter {
  return {
    type: "default",
    convert(activity: TrackedActivity): PageEvent | undefined {
      switch(activity.type) {
        case "page event":
        case "goal":
          return convertEvent(activity);
        default:
          return undefined;
      }
    }
  }
}

/**
 * Converts page event and goals.
 * @param activity 
 */
function convertEvent(activity: TrackedActivity): PageEvent {
  return {
    date: activity.date,
    pageEventId: activity.data.id
  };
}
