import { createContext } from 'react';
import { Tracker } from '@uniformdev/tracking';
import { Logger } from '@uniformdev/common';
import { SubscriptionManager, getSubscriptionManager, UniformEvent } from '@uniformdev/optimize-common-sitecore';


export type TrackerContextEventType = 'tracker-set';

export interface TrackerContextEvent extends UniformEvent {
    tracker?: Tracker;
}

export interface TrackerContextValue {
    clientSideRouting?: boolean;
    needsRefresh?: boolean;
    logger?: Logger;
    subscriptions: SubscriptionManager<TrackerContextEvent>;
    tracker?: Tracker;
    visitorId?: string;
}

export const TrackerContext = createContext<TrackerContextValue>({
    subscriptions: getSubscriptionManager<TrackerContextEvent>("TRACKER_CONTEXT_SUBS")
});
