import { getCurrentVisit, Visitor } from '../../models/visitor';
import { Visit } from '../../models/visit';
import { FilteredActivities, getVisitActivities, getVisitorActivities } from './activities';
import { SitecoreCookieNames } from './tracker';
import { UniformCookieNames } from '../../trackers/tracker';
import { getNullLogger, Logger } from '@uniformdev/common';

export interface GetCookie { (name: string): string|undefined }
export interface SetCookie { (name: string, value:any, options?: any): void }
export interface RemoveCookie { (name: string): void }
export interface GetCookieName { (type: string): string|undefined }
export interface GetValueForCookie { (cookieType: string, visitor: Visitor, logger?: Logger, loggerPrefix?: string): GetValueForCookieResult|undefined }
export interface GetValueForCookieResult {
    value?: any,
    unsupportedCookieType?: boolean;
}

export type VisitPropertyType = 'campaign' | 'goals';
export type VisitorPropertyType = VisitPropertyType | 'patterns' | 'profiles' | 'testing' | 'visitCount';

export interface TrackerCookieSettings {
    visitor?: VisitorPropertyType[],
    visit?: VisitPropertyType[]
}

export const defaultGetCookieName: GetCookieName = (type: string): string|undefined => {
    switch (type) {
        case "campaign":
            return SitecoreCookieNames.Campaign;
        case "campaigns":
            return SitecoreCookieNames.Campaigns;
        case "goals":
            return SitecoreCookieNames.Goals;
        case "patterns":
            return SitecoreCookieNames.PatternMatches;
        case "profiles":
            return SitecoreCookieNames.ProfileScores;
        case "visitCount":
            return UniformCookieNames.VisitCount;
    }
    return;
}

export const defaultGetCookieValueFromVisit: GetValueForCookie = (type: string, visitor: Visitor, logger?: Logger, loggerPrefix?: string): GetValueForCookieResult|undefined => {
    if (!logger) logger = getNullLogger();
    //
    //Get value from latest visit.
    const visit = getCurrentVisit(visitor);
    if (!visit) {
        return;
    }
    switch(type) {
        case "campaign":
            return getValueForCampaignCookie([visit], logger, loggerPrefix);
        case "campaigns":
            const campaigns = getVisitActivities('campaign', visit, logger, loggerPrefix);
            return getValueForActivitiesCookie(campaigns);
        case "goals":
            const goals = getVisitActivities('goal', visit, logger, loggerPrefix);
            return getValueForActivitiesCookie(goals);
        case "patterns":
            return getValueForPatternsCookie(visit);
        case "profiles":
            return getValueForProfilesCookie(visit);
    }
    return { unsupportedCookieType: true };
}

export const defaultGetCookieValueFromVisitor: GetValueForCookie = (type: string, visitor: Visitor, logger?: Logger, loggerPrefix?: string): GetValueForCookieResult|undefined => {
    if (!logger) logger = getNullLogger();
    //
    //Get value for visitor cookie.
    switch(type) {
        case "campaign":
            return getValueForCampaignCookie(visitor.visits, logger, loggerPrefix);
        case "campaigns":
            const campaigns = getVisitorActivities('campaign', visitor, logger, loggerPrefix);
            return getValueForActivitiesCookie(campaigns);
        case "goals":
            const goals = getVisitorActivities('goal', visitor, logger, loggerPrefix);
            return getValueForActivitiesCookie(goals);
        case "patterns":
            return getValueForPatternsCookie(visitor);
        case "profiles":
            return getValueForProfilesCookie(visitor);
        case "visitCount":
            return { value: visitor.visits?.length ?? 0 };
    }
    return undefined;
}

function getValueForCookie(data: any, getValue: (obj: any)=>string|undefined): string|undefined {
    if (data) {
        const values: string[] = [];
        Object.keys(data).forEach(key => {
            const value = getValue(data[key]);
            if (value) {
                values.push(`${key}=${value}`);
            }
        });
        return values.join(",");
    }
    return undefined;
}

function getValueForCampaignCookie(visits:Visit[], logger: Logger, loggerPrefix?: string): GetValueForCookieResult|undefined {
    const campaigns: string[] = [];
    visits.forEach(visit => {
        const campaign = visit.data.campaign;
        if (!campaign) return;
        const id = visit.data.campaign?.data?.id;
        if (!id) {
            logger.debug(`${loggerPrefix} - Campaign was found on the visit but no id is set, so unable to include this campaign in the cookie value.`, { campaign, visit: visit.id, visitor: visit.visitorId });
            return;
        }
        if (!campaigns.includes(id)) {
            campaigns.push(id);
        }
    });
    return { value: campaigns.join(',') };
}

function getValueForActivitiesCookie(activities: FilteredActivities): GetValueForCookieResult|undefined {
    const value = getValueForCookie(activities, dates => {
        return (dates as Date[]).map(date => date.getTime()).join("|");
    });
    return { value };
}

function getValueForPatternsCookie(source:Visit|Visitor): GetValueForCookieResult|undefined {
    const value = getValueForCookie(source.data.patterns?.data, p => p.patternId);
    return { value };
}

function getValueForProfilesCookie(source:Visit|Visitor): GetValueForCookieResult|undefined {
    const value = getValueForCookie(source.data.profiles?.data, p => {
        if (p.keys) {
            var values: string[] = [];
            Object.keys(p.keys).forEach(key => {
                var value = p.keys[key]?.value ?? 0;
                if (value > 0) {
                    values.push(`${key}_${value}`);
                }
            });
            if (values.length > 0) {
                return values.join("|");
            }
        }
        return undefined;
    });
    return { value };
}