import { getNullLogger, Logger } from "@uniformdev/common";
import { UniformWindow } from "../util/global";
import { UniformEvent } from './index';
import { v4 as uuid } from 'uuid';

declare let window: UniformWindow;

export interface UniformUnsubscribe { 
    unsub:() => boolean;
    id: string;
}

export function createUniformUnsubscribe(unsub:() => boolean): UniformUnsubscribe {
    return {
        id: uuid(),
        unsub
    }
}

export interface UniformCallback<T> { (data: T): void }

export interface SubscriptionManager<TData extends UniformEvent | UniformEvent> {
    /**
     * 
     */
    id: string;
    /**
     * Add a callback that runs when a specific event type is published.
     * @param type 
     * @param callback 
     */
    subscribe(type: string | undefined, callback: UniformCallback<TData>): UniformUnsubscribe;
    /**
     * Publish an event to all subscribers for the event type.
     * @param type 
     * @param data 
     */
    publish(data: TData): void;
    /**
     * Gets the subscribers for the specified event type. 
     * @param type 
     */
    getSubscribers(type: string): UniformCallback<TData>[];
    /**
     * Gets the types of events the manager has subscriptions for.
     */
    getSubscriptionTypes(): string[];
}

export function getSubscriptionManager<TData extends UniformEvent | UniformEvent>(id: string, isGlobal: boolean = false, logger: Logger = getNullLogger()): SubscriptionManager<TData> {
    logger.debug("getSubscriptionManager - Getting subscription manager.", { id, isGlobal });
    const map = new Map<string | undefined, UniformCallback<TData>[]>();   
    return {
        id,
        getSubscribers: (type: string): UniformCallback<TData>[] => {
            return map.get(type) ?? [];
        },
        getSubscriptionTypes: (): string[] => {
            const types: string[] = [];
            Array.from(map.keys()).forEach(type => {
                if (type) types.push(type);
            })
            return types;
        },
        publish: (data: TData) => {
            logger.debug("Default subscription manager - Publish", { id, data });
            if (data.silent === true) {
                return;
            }
            const callbacks = map.get(data.type);
            if (callbacks) {
                callbacks.forEach(callback => callback(data));
            }
            const callbacks2 = map.get(undefined);
            if (callbacks2) {
                callbacks2.forEach(callback => callback(data));
            }
            if (isGlobal != true) {
                if (window.uniform?.subscriptions) {
                    window.uniform.subscriptions.publish(data);
                }
            }
        },
        subscribe: (type: string | undefined, callback: UniformCallback<TData>): UniformUnsubscribe => {
            logger.debug("Default subscription manager - Subscribe", { id, type, callback });
            let callbacks = map.get(type);
            if (!callbacks) {
                callbacks = [];
                map.set(type, callbacks);
            } 
            if (callbacks.indexOf(callback) == -1) {
                callbacks.push(callback);
                map.set(type, callbacks);
            }
            const unsub = () => {
                const position = callbacks!.indexOf(callback);
                if (position == -1) {
                    return false;
                }
                callbacks!.splice(position, 1);
                return true;
            };
            return createUniformUnsubscribe(unsub);
        }
    };
}
