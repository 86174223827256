"use strict";

import { Logger } from "@uniformdev/common";
import { Dispatcher, DispatchSettings } from "../../dispatchers";
import { TrackedActivityResults, TrackedActivity } from '../../models/trackedActivity';

declare global {
  interface Window { ga: any; }
}

export interface GaEvent {
  category: string;
  action?: string;
  label?: string;
  value?: number;
}

export interface GaTrackedActivityConverter { 
  type: string;
  convert(activity: TrackedActivity): GaEvent | undefined 
}

export interface GaSetCustomDimensionValues { (results: TrackedActivityResults, values: Map<number, any>): void }

export interface GaDispatcherSettings {
  /**
   * Sets the values that are written as custom dimensions onto 
   * a map whose key is used to identify the position of the 
   * custom dimension. These values are added to every event 
   * that is written to Google Analytics.
   */
  setCustomDimensionValues?: GaSetCustomDimensionValues;
  /**
   * If undefined, all available trackers are used.
   * If an empty array is specified, no trackers are used.
   */
  trackingIds?: string[];
  /**
   * If activities is undefined, all activities are dispatched.
   * If activities is an empty array, no activities are dispatched.
   */
  activities?: string[];
}

export class GaDispatcher implements Dispatcher {
  /**
   * 
   * @param converter
   * @param trackingIds
   * @param setCustomDimensionValues Sets the values that are written 
   * as custom dimensions onto a map whose key is used to identify the 
   * position of the custom dimension. These values are added to every 
   * event that is written to Google Analytics.
   */
  constructor(converters: GaTrackedActivityConverter[], settings?: GaDispatcherSettings) {
    this.activities = settings?.activities;
    this.converters = converters;
    this.trackingIds = settings?.trackingIds;
    this.setCustomDimensionValues = settings?.setCustomDimensionValues;
  }

  activities: string[] | undefined;
  converters: GaTrackedActivityConverter[];
  setCustomDimensionValues?: (results: TrackedActivityResults, values: Map<number, any>) => void;
  requiresBrowser: boolean = true;
  trackingIds: string[] | undefined;
  type: string = "ga";

  getCustomDimensionFields(results: TrackedActivityResults, logger: Logger): any {
    const fields: any = {};
    const map: Map<number, any> = new Map<number, any>();
    if (this.setCustomDimensionValues) {
      this.setCustomDimensionValues(results, map)
    }
    map.forEach((value, key) => {
      fields[`dimension${key}`] = value;
    });  
    logger.debug("GA dispatcher - Converted tracking results into Google Analytics custom dimensions.", { map, fields })
    return fields;
  }

  dispatchActivity(results: TrackedActivityResults, settings: DispatchSettings, logger: Logger): void {
    //
    //
    if (!results) {
      logger.debug("GA dispatcher - No tracked activity results are available to dispatch.", { settings });
      return;
    }
    //
    //Google Analytics tracking script must be loaded.
    if (!window.ga) {
      logger.error("GA dispatcher - GA tracking script has not been loaded. The tracking script must be loaded before the GA dispatcher can dispatch events to GA. Dispatch aborted.", { trackingIds: this.trackingIds, activity: results, settings });
      return;
    }
    //
    //
    if (this.activities && this.activities.length == 0) {
      logger.debug("GA dispatcher - An empty array of activities was specified so no activities will be dispatched.", { settings });
      return;
    }
    //
    //
    const events: GaEvent[] = [];
    results.visitActivities.forEach(activity => {
      if (this.activities && this.activities.indexOf(activity.type) == -1) {
        logger.debug("GA dispatcher - The activity type was not selected as an activity to dispatch.", { type: activity.type, allowed: this.activities, activity, settings });
        return;
      }
      for(let i=0; i<this.converters.length; i++) {
        const gaEvent = this.converters[i].convert(activity);
        if (!gaEvent) {
          logger.debug("GA dispatcher - The activity was not converted into a format that can be dispatched.", { activity, settings });
          continue;
        }
        logger.debug("GA dispatcher - The activity was converted into a format that can be dispatched.", { activity, gaEvent, settings });
        events.push(gaEvent);
      }
    });
    //
    //
    const fields: any = this.getCustomDimensionFields(results, logger);
    //
    //
    if (events.length == 0) {
      logger.debug("GA dispatcher - No GA events were resolved, so no events will be dispatched.", { settings });
      if (fields && Object.keys(fields).length > 0) {
        logger.debug("GA dispatcher - Since no events will be dispatched, no custom dimensions will be dispatched.", { fields, settings });
      }
      return;
    }
    //
    //Create trackers for the specified tracking ids.
    this.trackingIds?.forEach(id => {
      logger.debug("GA dispatcher - Creating tracker for tracking id " + id, { settings });
      window.ga('create', id, 'auto', id);
    });
    const trackingIds = this.trackingIds;
    //
    //
    window.ga(function(_tracker: any) {
      const trackers: any[] = [];
      if (trackingIds) {
        //
        //Only use the trackers that are specified.
        logger.debug("GA dispatcher - Events will be dispatched to the specified Google Analytics tracker(s).", { trackingIds, settings });
        trackingIds?.forEach(id => {
          const tracker = window.ga.getByName(id);
          if (tracker) {
            trackers.push(tracker);
          }
        });
      }
      else {
        //
        //Since no trackers were specified, use them all.
        logger.debug("GA dispatcher - No tracking ids were specified, so events will be dispatched to all Google Analytics trackers.", { settings });
        window.ga.getAll().forEach(function(tracker: any) {
          trackers.push(tracker);
        })
      }
      if (trackers.length == 0) {
        logger.debug("GA dispatcher - No trackers were resolved, so no events will be dispatched to Google Analytics.", { trackingIds, settings });
        return;
      }
      logger.debug("GA dispatcher - Ready to dispatch events to Google Analytics.", {events, trackers: trackers.map(t => t.get("name")), settings });
      trackers.forEach(tracker => {
        events.forEach(event => {
          doSendEvent(tracker, event, fields, settings, logger);
        })
      })
    })
  }
}

function doSendEvent(tracker: any, e: GaEvent, fields: any, settings: DispatchSettings, logger: Logger): void {
  fields.nonInteraction = true;
  const trackingId = tracker.get('trackingId');
  tracker.send('event', e.category, e.action, e.label, e.value, fields);
  logger.debug("GA dispatcher - Event dispatched to Google Analytics.", { trackingId, ...e, fields: fields, settings });
}

