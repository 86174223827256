"use strict";

import { TrackedActivity } from '../../models/trackedActivity';
import { OracleDmpPhints, OracleDmpTrackedActivityConverter } from "../oracleDmp/dispatcher";

/**
 * Converts Sitecore-specific events into phints for Oracle DMP.
 */
export function getOracleDmpTrackedActivityConverterForSitecore(): OracleDmpTrackedActivityConverter {
  return {
    type: "default",
    convert(activity: TrackedActivity): OracleDmpPhints | undefined {
      switch(activity.type) {
        case "page view":
          return convertPageView(activity);
        case "campaign":
          return convertCampaign(activity);
        case "page event":
          return convertEvent(activity);
        case "goal":
          return convertEvent(activity);
        case "personalization":
          return convertPersonalization(activity);
        default:
          return undefined;
      }
    }  
  }
}

/**
 * Converts campaigns.
 * @param activity 
 */
function convertCampaign(activity: TrackedActivity): OracleDmpPhints | undefined {
  const campaignName = activity?.data?.name;
  if (!campaignName) {
    return;
  }
  return {
    campaigns: campaignName
  };
}

/**
 * Converts page event and goals.
 * @param activity 
 */
function convertEvent(activity: TrackedActivity): OracleDmpPhints | undefined {
  const eventName = activity?.data?.name;
  // const engagementValue = activity?.data?.points ?? 0;
  if (!eventName) {
    return;
  }
  return {
    events: eventName
  };
}

/**
 * Converts page view event.
 * @param activity 
 */
function convertPageView(activity: TrackedActivity): OracleDmpPhints | undefined {
  const itemId = activity?.data?.item?.id;
  const itemName = activity?.data?.item?.name;
  return {
    itemId,
    itemName
  }
}

/**
 * Converts personalization event.
 * @param activity 
 */
function convertPersonalization(_activity: TrackedActivity): OracleDmpPhints | undefined {
  // const pageName = activity?.data?.page?.name;
  // const componentName = activity?.data?.component?.name;
  // const isIncludedInTest = activity?.data?.isIncludedInTest ?? false != true;
  return;
}
