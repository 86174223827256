'use strict';
import { getVisitChangesFromResults, getVisitorChanges } from './utils';
import { Tracker, TrackerExtensionPoints, TrackingSettings, TrackingEvent, TrackerState } from './tracker';
import { TrackingDataRepository } from '../repositories';
import { Visitor } from '../models/visitor';
import { Visit } from '../models/visit';
import { TrackedActivityResults, TrackedActivity, TrackedActivityType, VisitUpdate, VisitorUpdate } from '../models/trackedActivity';
import { ContextReader, ContextReaderContext } from '../contextReader';
import { Logger, getNullLogger} from '@uniformdev/common';
import { SubscriptionManager, getSubscriptionManager, UniformUnsubscribe, UniformCallback } from '@uniformdev/optimize-common-sitecore';
import { Dispatcher, DispatchEventHandler, DispatchSettings } from '../dispatchers';
import { UniformWindow } from './global';
const version = require('../build/version.json');


declare let window: UniformWindow;

/**
 * Settings used to specify how the default tracker works.
 */
export interface DefaultTrackerSettings {
    campaignParameters?: string[];
    contextReaders?: Map<string, ContextReader[]>;
    dispatchers?: Dispatcher[];
    onDispatch?: DispatchEventHandler;
    extensions?: TrackerExtensionPoints;
    goalParameters?: string[];
    logger?: Logger;
    onVisitorInitialized?: string;
    repository: TrackingDataRepository;
    sessionTimeout?: number;
    subscriptions?: SubscriptionManager<TrackingEvent>;
}

/**
 * Default implementation of the Uniform tracker.
 */
export class DefaultTracker implements Tracker {
    id: string;
    contextReaders = new Map<string, ContextReader[]>();
    dispatchers: Dispatcher[];
    onDispatch?: DispatchEventHandler;
    extensions?: TrackerExtensionPoints;
    logger: Logger;
    repository: TrackingDataRepository;
    sessionTimeout: number;
    state: TrackerState = 'unknown';
    subscriptions: SubscriptionManager<TrackingEvent>;
    version?: string;

    constructor(id: string, settings: DefaultTrackerSettings) {
        this.id = id;
        this.contextReaders = settings.contextReaders ?? new Map<string, ContextReader[]>();
        this.dispatchers = settings.dispatchers ?? [];
        this.onDispatch = settings.onDispatch;
        this.logger = settings.logger ?? getNullLogger();
        this.extensions = settings.extensions;
        this.repository = settings.repository;
        this.sessionTimeout = settings.sessionTimeout ?? 20;
        this.subscriptions = settings.subscriptions ?? getSubscriptionManager<TrackingEvent>(`${id}_SUBS`, false, this.logger);
        this.version = version.version;
    }

    async event(type: TrackedActivityType, e: TrackedActivity, settings: TrackingSettings): Promise<TrackedActivityResults> {
        this.logger.debug('Default tracker - START: event() handling.', { event: e, settings });
        const callback = (_date: string, activity: TrackedActivityResults) => {
            switch(type) {
                case "visit-activity":
                    activity.visitActivities.push(e);
                    break;
                case "visit-update":
                    activity.visitUpdates.push(e);
                    break;
                case "visitor-update":
                    activity.visitorUpdates.push(e);
                    break;
                default:
                    this.logger.error("Default tracker - Specified event type is not supported. Event will not be captured.", {type, event: e});
            }
        }
        const results = await this.doWork("tracking-finished", { ...settings, event: true }, callback);
        this.logger.debug('Default tracker - END: event() handling.', { results });
        return results;
    }

    async initialize(settings: TrackingSettings): Promise<TrackedActivityResults> {
        this.logger.debug('Default tracker - START: initialize() handling.', { settings });
        const results = await this.doWork("tracking-intialized", settings, (_date: string, _activity: TrackedActivityResults) => {});
        this.logger.debug('Default tracker - END: initialize() handling.', { results });
        return results;
    }

    subscribe(type: string | undefined, callback: UniformCallback<TrackingEvent>): UniformUnsubscribe {
        this.logger.debug("Default tracker - Add subscription.", { manager: this.subscriptions.id, type });
        return this.subscriptions.subscribe(type, callback);
    }

    async track(source: string | undefined, context: any, settings: TrackingSettings): Promise<TrackedActivityResults> {
        this.logger.debug('Default tracker - START: track() handling.', { source, context, settings });
        const callback = (date: string, activity: TrackedActivityResults) => {
            const { visit, visitor } = activity;
            //
            //Get the url
            const url = new URL(window?.location.href);
            //
            //Make sure at least one context reader is available.
            const readers = source ? this.contextReaders.get(source) : undefined;
            if (!readers) {
                this.logger.warn('Default tracker -   * No context readers are registered for the source. No tracking data will be created.', { source });
                return;
            }
            //
            //Use the context reader to determine the tracked activity.
            const readerContext: ContextReaderContext = {
                date, context, visit: visit!, visitor: visitor!, url, logger: this.logger
            }
            this.logger.debug("Default tracker -   * Iterate context readers to read activity from context.", { context });
            this.contextReaders.forEach((readers, id) => {
                this.logger.debug('Default tracker -   * Reading activity from context using context readers.', { id, readers });
                readers.forEach(reader => {
                    const activity2 = reader.getTrackedActivity(source, readerContext); 
                    this.logger.debug('Default tracker -      * Activity read from reader.', { type: reader.type, activity: activity2 })
                    activity.append(activity2);
                });
            });
        }
        const results = await this.doWork("tracking-finished", settings, callback);
        this.logger.debug('Default tracker - END: track() handling.', { results });
        return results;
    }

    doDispatch(activity: TrackedActivityResults, settings: DispatchSettings, visitor: Visitor) {
        //
        //Dispatch the results if any dispatchers are specified.
        if (this.dispatchers && this.dispatchers.length > 0) {
            this.logger.debug('Default tracker -   * Dispatchers are defined, so call them.', { visitorId: visitor.id, dispatchers: this.dispatchers });
            const isRunningInBrowser = (typeof window !== 'undefined' && window.document != undefined);
            this.dispatchers.forEach(dispatcher => {
                if (!dispatcher.requiresBrowser || isRunningInBrowser) {
                    this.logger.debug("Default tracker -   START: activity dispatch.", { visitorId: visitor!.id, type: dispatcher.type, activity });
                    dispatcher.dispatchActivity(activity, settings, this.logger);
                    this.logger.debug("Default tracker -   END: activity dispatch.", { visitorId: visitor!.id, type: dispatcher.type, activity });
                }
            });
        }
        //
        //Dispatch using the event handler if the handler is specified.
        if (this.onDispatch) {
            this.logger.debug("Default tracker -   START: dispatch event handler.", { activity, settings });
            this.onDispatch(activity, settings, this.logger);
            this.logger.debug("Default tracker -   END: dispatch event handler.");
        }
    }

    doVisitUpdate(date: string, activity: TrackedActivityResults, visit: Visit) {
        //
        //Update the visit.
        if (activity.visitActivities.length > 0 || activity.visitUpdates.length > 0) {
            visit.updated = date;
        }
        if (!visit.data) {
            visit.data = {};
        }
        if (!visit.data["activities"]) {
            visit.data["activities"] = [];
        }
        //
        //
        if (activity.visitActivities.length === 0) {
            this.logger.debug('Default tracker -   * No visit activities to add to visit.', { visit, activities: activity.visitActivities });
        }
        else {
            this.logger.debug('Default tracker -   * Adding visit activities to visit.', { visit, activities: activity.visitActivities });
            activity.visitActivities.forEach(activity => {  
                visit!.data["activities"].push(activity);
            });    
            this.logger.debug('Default tracker -   * Visit activities added to visit.', { visit, activities: activity.visitActivities });
        }
        //
        //
        if (activity.visitUpdates.length === 0) {
            this.logger.debug('Default tracker -   * No visit data to add to visit.', { visit, data: activity.visitUpdates });
        }
        else {
            this.logger.debug('Default tracker -   * Apply visit updates.', { visit: visit.id, count: activity.visitUpdates.length });
            this.applyUpdatesToTarget(date, activity.visitUpdates, visit);
        }
        //
        //
        if (activity.visitUpdateCommands.length === 0) {
            this.logger.debug('Default tracker -   * No visit update commands to run.', { visit: visit.id });
        }
        else {
            this.logger.debug('Default tracker -   * Run visit update commands.', { visit: visit.id, commands: activity.visitUpdateCommands });
            activity.visitUpdateCommands.forEach(command => {
                command(visit!);
            });
        }
    }

    doVisitorUpdate(date: string, activity: TrackedActivityResults, visitor: Visitor) {
        //
        //Update the visitor.
        if (!visitor!.data) {
            visitor!.data = {};
        }
        if (activity.visitorUpdates.length === 0) {
            this.logger.debug('Default tracker -   * No visitor updates to apply.', { visitor: visitor.id });
        }
        else {
            this.logger.debug('Default tracker -   * Apply visitor updates.', { visitor: visitor.id, count: activity.visitorUpdates.length });
            this.applyUpdatesToTarget(date, activity.visitorUpdates, visitor);
        }
        if (activity.visitorUpdateCommands.length === 0) {
            this.logger.debug('Default tracker -   * No visitor update commands to run.', { visitor: visitor.id });
        }
        else {
            this.logger.debug('Default tracker -   * Run visitor update commands.', { visitor: visitor.id, commands: activity.visitorUpdateCommands });
            activity.visitorUpdateCommands.forEach(command => {
                command(visitor!);
            });
        }
    }

    /**
     * Update values for profiles, patterns and other properties of the target.
     * @param date 
     * @param updates 
     * @param target 
     */
    applyUpdatesToTarget(date: string, updates: VisitorUpdate[]|VisitUpdate[], target: Visitor|Visit) {
        updates.forEach(update => {
            this.logger.debug('Default tracker -     * Applying update to target.', { target: target!.id, update });
            if (target!.data[update.type]) {
                //
                //target.data["profile"].["key"] exists, update it
                target!.data[update.type].date = date;
                Object.keys(update.data).forEach(profileOrPatternKey => {
                    target!.data[update.type].data[profileOrPatternKey] = update.data[profileOrPatternKey];
                })
            }
            else {
                //
                //target.data["profile"].["key"] doesn't exist, add it
                target!.data[update.type] = {
                    date,
                    data: update.data
                }
            }
        });
    }

    async doWork(eventType: string, settings: TrackingSettings, callback: (date: string, activity: TrackedActivityResults) => void): Promise<TrackedActivityResults> {
        const { visitorId, visitId, createVisitor = false, onVisitorInitialized } = settings;
        let visitor:(Visitor | undefined) = undefined;
        let visit:(Visit | undefined) = undefined;
        //
        //
        const activity = new TrackedActivityResults();
        const date = new Date().toISOString();
        //
        //
        this.logger.debug('Default tracker -   START: doWork.', { eventType, settings });
        //
        //
        try {  
            //
            //Get the visitor.
            if (!visitorId) {
                this.logger.debug('Default tracker -   * No visitor id was set on the tracking settings, so will try to create new visitor.', { settings });
            }
            else {
                this.logger.debug('Default tracker -   * Get visitor from the repository using visitor id set on the tracking settings.', { visitorId });
                visitor = this.repository.getVisitor(visitorId);
                if (!visitor) {
                    this.logger.debug('Default tracker -   * No visitor was returned from the repository.', { visitorId });
                }
            }
            if (!visitor) {
                if (!createVisitor) {
                    this.logger.warn('Default tracker -   * No visitor was resolved and the create-visitor flag was not set on the tracking settings. Tracking will abort.', { settings });
                    return activity;
                }
                //
                //Create a new visitor.
                visitor = this.repository.createVisitor();
                if (!visitor) {
                    this.logger.error('Default tracker -   * Unable to create new visitor. Tracking will abort.');
                    return activity;
                }
                this.logger.debug('Default tracker -   * New visitor was created.', { visitorId: visitor.id });
            }
            else {
                this.logger.debug('Default tracker -   * Visitor was retrieved from the repository.', { visitorId });
            }
            //
            //Run the event handler if specified.
            if (onVisitorInitialized) {
                this.logger.debug('Default tracker -   * Event handler for visitor-initialized event was specified on the tracking settings, so call it.');
                const saveVisitor = (date: Date, visitor: Visitor, visitChanges?: Map<string, string[]>, visitorChanges?: string[]) => {
                    this.logger.debug('Default tracker - Save visitor was called by the visitor-initialized event handler specified on the tracking settings.');
                    this.repository.saveVisitor(date, visitor, visitChanges, visitorChanges, settings?.silent);
                };
                await onVisitorInitialized(visitor, saveVisitor, this.logger);
                this.logger.debug('Default tracker -   * Event handler for visitor-initialized event specified on the tracking settings finished.');
            }
            //
            //Get the visit.
            const result = this.repository.getCurrentVisit({visitId, visitor, sessionTimeout: this.sessionTimeout});
            visit = result.current;
            if (!visit) {
                this.logger.error('Default tracker -   * No visit was returned from the repository. Tracking will abort.');
                return activity;
            }
            if (result.isNewVisit && this.extensions?.onNewVisitCreated) {
                this.logger.debug('Default tracker -   * Event handler for new-visit-created event was specified on extensions on the tracking settings, so call it.', { settings });
                const now = new Date();
                this.extensions.onNewVisitCreated(now, visitor, result.previous, visit, this.logger);
            }
            //
            //
            activity.visit = visit;
            activity.visitor = visitor;
            //
            //
            callback(date, activity);
            //
            //
            //Determine whether the visit or the visitor changed so
            //handlers can be called. This should be called before
            //any changes are applied to the visit or visitor in
            //case the current state of either is needed.
            const visitChanges = getVisitChangesFromResults(activity, visit, visitor);
            this.logger.debug('Default tracker -   * Resolved visit changes from results.', { visitChanges, activity, visit, visitor });
            const visitorChanges = getVisitorChanges(activity, visit, visitor);
            this.logger.debug('Default tracker -   * Resolved visitor changes from results.', { visitorChanges, activity, visit, visitor });
            //
            //
            if (visitChanges.size == 0 && visitorChanges.length == 0) {
                this.logger.debug('Default tracker -   * No changes were made to the visit or the visitor, so there is nothing to track.', { activity });
                return activity;
            }
            this.doVisitUpdate(date, activity, visit);
            this.doVisitorUpdate(date, activity, visitor);
            //
            //Provide a way to perform tasks like recalculating 
            //pattern matches. This logic should be handled by
            //the tracker, not the repository.
            const when = new Date();
            if (this.extensions && this.extensions.onBeforeVisitorSaved) {
                this.logger.debug('Default tracker -   * Event handler for before-visitor-saved event was specified on extensions on the tracking settings, so call it.', { settings });
                this.extensions.onBeforeVisitorSaved(when, visitor, visitChanges, visitorChanges, this.logger);
            }
            //
            //
            const hasChanges = visitorChanges.length > 0 || visitChanges.size > 0;
            if (!hasChanges) {
                this.logger.debug('Default tracker -   * No visitor or visit changes were resolved, so no changes will be saved to the repository.', { visitor: visitor.id, visit: visit.id });
            }
            else {
                //
                //Save the visitor to persistent storage using the 
                //repository. The repository may trigger events to 
                //notify subscribers that something has changed.
                this.logger.debug('Default tracker -   START: saving visitor.', { visitorId: visitor.id, when, visitorChanges, visitChanges });
                this.repository.saveVisitor(
                    when,
                    visitor,
                    visitChanges,
                    visitorChanges,
                    settings?.silent
                );
                this.logger.debug('Default tracker -   END: saving visitor.', { visitorId: visitor.id });
            }
            //
            //Update global objects.
            if (!window.uniform) {
                this.logger.debug("Default tracker -   * Global Uniform object does not exist, so creating it.", {visit, visitor});
                window.uniform = {};
            }
            this.logger.debug("Default tracker -   * Updating global Uniform object.", { tracker: this.id, visitor: visitor.id, visit: visit.id });
            window.uniform.tracker = this;
            window.uniform.visit = visit;
            window.uniform.visitor = visitor;
            if (settings.silent !== true) {
                //
                //Notify subscribers that tracking is finished.
                const trackingFinishedEvent: TrackingEvent = {
                    type: eventType,
                    when: new Date(),
                    visit: activity.visit,
                    visitor: activity.visitor
                }
                const types = this.subscriptions.getSubscriptionTypes();
                if (!types.includes(trackingFinishedEvent.type)) {
                    this.logger.debug("Default tracker -   * An event will be fired, but the subscription manager is not listening for it.", { manager: this.subscriptions.id, event: trackingFinishedEvent, types: this.subscriptions.getSubscriptionTypes() });
                }
                this.logger.debug("Default tracker -   * Notify subscribers that tracking is finished.", { manager: this.subscriptions.id, event: trackingFinishedEvent, types: this.subscriptions.getSubscriptionTypes(), settings });
                this.subscriptions.publish(trackingFinishedEvent);
            }
            else {
                this.logger.debug("Default tracker -   * Tracking settings indicate silent mode. Tracking is finished but subscribers will not be notified.", { manager: this.subscriptions.id });
            }
            //
            //Do dispatch
            if (hasChanges) {
                const dispatchSettings: DispatchSettings = {
                    event: settings.event
                }
                this.doDispatch(activity, dispatchSettings, visitor);    
            }
        } catch (ex: any) {
            this.logger.error('Default tracker -   * Error thrown.', { ex });
        } finally {
            this.logger.debug('Default tracker -   END: doWork.', { settings });
            this.state = 'ready';
        }
        return activity;
    }
}
