import { getVisitChangesFromVisits } from '../trackers/utils';
import { Visit } from './visit';

const DEFAULT_DATE: string = new Date(0).toISOString();

export class Visitor {
    constructor(id: string, init?: Partial<Visitor>) {
        this.id = id;
        Object.assign(this, init);
    }
    id: string;
    updated: string = DEFAULT_DATE;
    visits: Visit[] = [];
    data: any;
}

export function getCurrentVisit(visitor: Visitor): Visit|undefined {
    if (visitor?.visits) {
        const filtered = visitor.visits.filter(v => !v.end);
        if (filtered.length == 0) {
            return;
        }
        if (filtered.length == 1) {
            return filtered[0];
        }
        return filtered.reduce((prev, current) => (new Date(prev.start) > new Date(current.start)) ? prev : current);
    }
    return;
}

/**
 * Adds the specified visits to the visitor's array of visits.
 * If the visit is already associated with the visitor, the
 * visit is ignored.
 * @param visits Array of visits to add to the visitor.
 * @param visitor Visitor whom the visits are added to.
 * @returns Map of the changes to the visitor. 
 */
export function addVisits(visits: Visit[], visitor: Visitor): Map<string, string[]> {
    if (visits && visitor) {
        const addedVisits: Visit[] = [];
        visits.forEach(visit => {
            if (visitor.visits.findIndex(v => v.id == visit.id) == -1) {
                addedVisits.push(visit);
            }
        });
        if (addedVisits.length > 0) {
            addedVisits.forEach(visit => visitor.visits.push(visit));
            const changes = getVisitChangesFromVisits(visits, visitor);
            return changes;
        }
    }
    return new Map<string, string[]>();
}