import React from 'react';
import { RenderingProps } from './RenderingProps';

export default function EsiText(props: RenderingProps) {
  //return React.createElement('esi:text', { value: props.params.value }, undefined);
  if (!props.params.value) {
    return;
  }
  return <span
    dangerouslySetInnerHTML={{
      __html: props.params.value
    }}
  />
}
