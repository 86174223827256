import { SubscriptionManager, getSubscriptionManager } from '../events/subscriptions';
import { UniformEvent } from '../events/index';
import { UniformQueue, getUniformQueue } from '../events/queue';
import { Logger } from '@uniformdev/common';

export interface UniformWindow extends Window {
    uniform?: UniformGlobal;
}

declare let window: UniformWindow;

export interface UniformGlobal {
    /**
     * Set the event type to undefined in order to subscribe to all events.
     */
    subscriptions?: SubscriptionManager<UniformEvent>;
    queue?: UniformQueue;
}

export function initializeGlobalObject(logger: Logger) {
    if (!window) {
        logger.error("initializeGlobalObject - Window global object is not available so Uniform global object cannot be initialized.");
        return;
    }
    if (!window.uniform) {
        window.uniform = {};
        logger.debug("initializeGlobalObject - Uniform global object was created.");
    }
    if (!window.uniform.subscriptions) {
        window.uniform.subscriptions = getSubscriptionManager<UniformEvent>("GLOBAL_SUBS", true);
        logger.debug("initializeGlobalObject - Subscription manager was added to Uniform global object.", { subscriptionManager: window.uniform.subscriptions.id });
    }
    if (!window.uniform.queue) {
        window.uniform.queue = getUniformQueue("GLOBAL_QUEUE");
        logger.debug("initializeGlobalObject - Queue was added to Uniform global object.", { queueId: window.uniform.queue.id });
    }
}