import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { RenderingProps } from './RenderingProps';
import { PersonalizationEventData } from '@uniformdev/tracking';
import { getEnv } from '@uniformdev/common';

const UNIFORM_SSR_ENGINE = getEnv(process.env, 'UNIFORM_SSR_ENGINE', '');

export default function EsiWhen(props: RenderingProps) {
  const placeholder = <Placeholder name="esi-when" rendering={props.rendering} componentFactory={props.componentFactory} />;
  //const wrappedPlaceholder = React.createElement('esi:text', null, placeholder);
  const activities = getPersonalizationEsi(props);

  // NOTE: need to use `React.createElement` to create namespaced elements, e.g. `<esi.choose>`
  // JSX isn't really capable of handling namespaced elements. There are some hacks for SVG,
  // but it's easier to just use `createElement`.

  let { test } = props.params;
  
  if (UNIFORM_SSR_ENGINE == "nextjs") {
    if (test.startsWith("$(ESI_CONTEXT_personalization_rules{")) {
      const componentId = test.match(/{(.*?)}/)[0];
      const ruleId = test.match(/'(.*?)'/g)[1];
      test = test.replace(componentId, "_" + ruleId.replace(/['-]/g, ""));
      test = test.replace(ruleId, "true");
    }
    return React.createElement('esi:when', { test }, placeholder, activities);  
  }
  return React.createElement('esi:when', { test: props.params.test }, placeholder, activities);
}

const SCRIPT_TEMPLATE = "if (window) { if (!window.uniform) window.uniform = {}; if (!window.uniform.tracking) window.uniform.tracking = {}; if (!window.uniform.tracking.personalization) window.uniform.tracking.personalization = {}; window.uniform.tracking.personalization['RENDERING_UID'] = RULE_JSON;}";

/**
 * If personalization details are assigned to the rendering, it
 * means the rendering was personalized. The details of the
 * personalization are written to a global js object so the
 * tracker can translate them into activities on the client.
 * 
 * Similar logic is also implemented for non-SSR rendering.
 * See AddPersonalizationDataToWhenNode.
 * @param props 
 */
function getPersonalizationEsi(props: RenderingProps): any {
  const personalization = (props.rendering as any).personalization as PersonalizationEventData;
  const uid = (props.rendering as any).uid;
  if (personalization?.rule?.id) {
    return <script
          dangerouslySetInnerHTML={{ __html:
            SCRIPT_TEMPLATE.replace("RENDERING_UID", uid).replace("RULE_JSON", JSON.stringify(personalization))
          }}
        />
  }
  return undefined;
}
