import { Visit } from './visit';
import { Visitor } from './visitor';

export class TrackedActivity {
    constructor(type: string, date: string, init?: Partial<TrackedActivity>) {
        this.type = type;
        this.date = date;
        Object.assign(this, init);
    }
    type: string;
    date: string;
    data: any;
}

export type TrackedActivityType = "visit-activity" | "visit-update" | "visitor-update";

export class VisitActivity extends TrackedActivity {
}

export class VisitUpdate extends TrackedActivity {
}

export interface VisitUpdateCommand { (visit: Visit): void }

export class VisitorUpdate extends TrackedActivity {
}

export interface VisitorUpdateCommand { (visitor: Visitor): void }

export class TrackedActivityResults {
    constructor(visit?: Visit, visitor?: Visitor) {
        this.visit = visit;
        this.visitor = visitor;
    }
    visit?: Visit;
    visitor?: Visitor;
    visitUpdates: VisitUpdate[] = [];
    visitUpdateCommands: VisitUpdateCommand[] = [];
    visitorUpdates: VisitorUpdate[] = [];
    visitorUpdateCommands: VisitorUpdateCommand[] = [];
    visitActivities: VisitActivity[] = [];

    /**
     * Copies activities and updates from the source into this object.
     * The visit and visitor objects are NOT copied.
     * @param source 
     * @param target 
     */
    append(source: TrackedActivityResults): void {
        if (!source) {
            return;
        }
        source.visitActivities.forEach(a => this.visitActivities.push(a));
        source.visitUpdates.forEach(a => this.visitUpdates.push(a));
        source.visitUpdateCommands.forEach(a => this.visitUpdateCommands.push(a));
        source.visitorUpdates.forEach(a => this.visitorUpdates.push(a));
        source.visitorUpdateCommands.forEach(a => this.visitorUpdateCommands.push(a));
    }
}

/**
 * Entries written to the global queue for the tracker
 * (i.e. the type on the queue event is "tracker")
 * must be this type of object.
 */
export interface TrackerQueueEntry {
    type: TrackedActivityType;
    e: TrackedActivity;
}

export const QUEUE_ENTRY_TYPE_TRACKER = "tracker";