"use strict";

import { Logger } from "@uniformdev/common";
import { Dispatcher, DispatchSettings } from "../../dispatchers";
import { Endpoint, Endpoints, HttpHeaders, QueryStringParameters } from "./destination";
import { TrackedActivity, TrackedActivityResults } from '../../models/trackedActivity';
import axios from 'axios';
import { getXdbTrackedActivityConverterForSitecore } from "./xdbEventConverter";

export interface PageEvent {
  date: string;
  // itemId: string;
  pageEventId: string;
  data?: string;
  dataKey?: string;
  name?: string;
  text?: string;
}

export interface XdbTrackedActivityConverter { 
  type: string;
  convert(activity: TrackedActivity): PageEvent | undefined 
}

export interface XdbDispatcherSettings {
  endpoints?: Endpoints;
  httpHeaders?: HttpHeaders;
  queryStringParameters?: QueryStringParameters;
}

export class XdbDispatcher implements Dispatcher {
  constructor(settings?: XdbDispatcherSettings) {
    this.endpoints = settings?.endpoints;
    this.httpHeaders = settings?.httpHeaders;
    this.queryStringParameters = settings?.queryStringParameters;
  }

  requiresBrowser: boolean = true;
  type: string = "xdb";
  endpoints?: Endpoints;
  httpHeaders?: HttpHeaders;
  queryStringParameters?: QueryStringParameters;

  dispatchActivity(results: TrackedActivityResults, settings: DispatchSettings, logger: Logger): void {
    const converter = getXdbTrackedActivityConverterForSitecore();
    //
    //If the activities were tracked as events, dispatch them.
    if (settings.event) {
      logger.info("XdbDispatcher - Activity was dispatched in event mode.", { activities: results.visitActivities, settings });
      const endpoint = this.endpoints?.event;
      if (!endpoint) {
        logger.error("XdbDispatcher - No event endpoint is specified, so unable to dispatch activities.", { endpoints: this.endpoints, activities: results.visitActivities });
        return;
      }
      results.visitActivities.forEach(activity => {
        const pageEvent = converter.convert(activity);
        if (!pageEvent) {
          logger.error("XdbDispatcher - Activity was not converted into a page event, so unable to dispatch activity.", { activity });
          return;
        }
        dispatchPageEvent(pageEvent, endpoint, this.httpHeaders, this.queryStringParameters, settings, logger);
      });
      return;
    }
    //
    //If the activities were tracked as a result of a page view, 
    //only dispatch the page view events. When the Sitecore 
    //tracking process runs, those activities will be captured.
    const pageViews = results.visitActivities.filter(activity => activity.type === "page view");
    pageViews.forEach(pageView => {
      const url = pageView.data?.url;
      if (!url) {
        logger.error("XdbDispatcher - No url is set on the page view activity, so unable to dispatch.", { activity: pageView, settings });
        return;
      }
      dispatchPageView(url, this.httpHeaders, this.queryStringParameters, settings, logger);
    });
  }
}

function appendQueryStringParameters(url: string, queryStringParameters: QueryStringParameters): string {
  if (!queryStringParameters) return url;
  var url2 = new URL(url);
  var search2 = Object.keys(queryStringParameters).map(key => {
    const value = queryStringParameters[key];
    return `${key}=${value}`;
  }).join('&');
  var url3 = url2.href;
  if (!url2.search) {
    url3 += "?";
  }
  url3 += search2;
  return url3;
}

function dispatchPageView(url: string, httpHeaders: HttpHeaders|undefined, queryStringParameters: QueryStringParameters|undefined, settings: DispatchSettings, logger: Logger): void {
  if (queryStringParameters) {
    url = appendQueryStringParameters(url, queryStringParameters);
  }
  if (!httpHeaders) {
    httpHeaders = {};
  }
  httpHeaders["X-Referer"] = document.referrer;
  axios
  .get(url, {
    method: 'GET',
    headers: httpHeaders,
    withCredentials: true
  })
  .then((response) => logger.debug("XdbDispatcher - Response received after sending request to Sitecore CD instance.", { response, requestModifications: { httpHeaders, queryStringParameters, settings }}))
  .catch((err) => logger.error("XdbDispatcher - Error sending request to Sitecore CD instance.", { url, requestModifications: { httpHeaders, queryStringParameters }, err, settings }));
}

function dispatchPageEvent(pageEvent: PageEvent, endpoint: Endpoint, httpHeaders: HttpHeaders|undefined, queryStringParameters: QueryStringParameters|undefined, settings: DispatchSettings, logger: Logger): void {
  let url = endpoint.url;
  if (queryStringParameters) {
    url = appendQueryStringParameters(url, queryStringParameters);
  }
  logger.debug("XdbDispatcher - Sending request to Uniform endpoint.", { url, method: endpoint.method, pageEvent });
  axios(url, {
    data: pageEvent,
    headers: httpHeaders,
    method: endpoint.method,
    withCredentials: true
  })
  .then((response) => logger.debug("XdbDispatcher - Response received after sending request to Uniform endpoint.", { response, endpoint, requestModifications: { httpHeaders, queryStringParameters, settings }}))
  .catch((err) => logger.error("XdbDispatcher - Error sending request to Uniform endpoint.", { url, endpoint, requestModifications: { httpHeaders, queryStringParameters }, err, settings }));
}