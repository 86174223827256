import { Logger, getNullLogger } from '@uniformdev/common';
import {
    getSubscriptionManager,
    Description,
    createUniformUnsubscribe,
} from '@uniformdev/optimize-common-sitecore';
import { PersonalizationChanges, PersonalizationEventData } from '@uniformdev/tracking';
import { PersonalizationManager, PersonalizationManagerEvent, PersonalizationTriggers, SitecoreItem, RenderingDefinition, GetSitecorePersonalizationManagerArgs } from './index';
import { RenderingPersonalizationContext } from '../ruleManagers/sitecore';
import {
    getSitecoreContextDataReader,
    DependencyBasedRenderingPersonalizationDefinition, 
    SitecoreContextDataSource
} from './contextReaders';
import { getNullTestManager, TestManager } from '../testManagers';
import { ComponentChangedResult } from '../';
const axios = require('axios').default;

export type GetSitecoreEsiPersonalizationManagerArgs = GetSitecorePersonalizationManagerArgs & {
    dataFetcher?: EsiJsonFetcher;
    dataFetcherReader?: EsiJsonReader;
    getDataFetcherUrl?: (item: string) => string;
    sitecoreApiKey: string;
    sitecoreSiteName: string;
}

export interface EsiHttpResponse {
    /** HTTP status code, i.e. 200, 404 */
    status: number;
    /** HTTP status text i.e. 'OK', 'Bad Request' */
    statusText: string;
    /** Parsed JSON response data from server */
    data: any;
}

/**
 * Interface to a HTTP fetcher that you want to use.
 * This interface conforms to Axios' public API, but should be adaptable
 * to other HTTP libraries or fetch polyfills. This HTTP implementation must:
 * - Support SSR
 * - Return non-HTTP 200 responses as status codes, not thrown exceptions (i.e. be a proper REST client)
 * - Parse response values as JSON and return them into <EsiHttpResponse>
 * - Send HTTP POST requests if `data` param is specified; GET is suggested but not required for data-less requests
 */
export type EsiJsonFetcher = (url: string, data?: { [key: string]: any }) => Promise<EsiHttpResponse>;
export type GetEsiJsonFetcherResultReader = (contextDataSource: SitecoreContextDataSource) => EsiJsonReader | undefined;

export interface SitecoreComponentData {
    uid: string;
    componentName: string;
    dataSource: string;
    params: { [key: string]: string };
    fields: {
        [key: string]: any;
    };
}

export type SitecoreComponentEsiData = SitecoreComponentData & {
    personalization?: PersonalizationEventData; 
};

function getDefaultDataFetcher(): EsiJsonFetcher {
    const fetcher = (url: string, data?: { [key: string]: any }) => {
        return axios({
            url,
            method: data ? 'POST' : 'GET',
            headers: {
                'Accept-ESI': 'true'
            },
            data,
            // note: axios needs to use `withCredentials: true` in order for Sitecore cookies to be included in CORS requests
            // which is necessary for analytics and such
            withCredentials: true,
        });
    }
    return fetcher;
}

export type EsiJsonReader = (rendering: RenderingDefinition, data: any, logger?: Logger) => SitecoreComponentEsiData|undefined;

function getDefaultDataFetcherReader(contextDataSource: SitecoreContextDataSource, logger?: Logger): EsiJsonReader|undefined {
    if (!logger) logger = getNullLogger();
    if (contextDataSource == 'jss-esi') {
        const reader: EsiJsonReader = (rendering: RenderingDefinition, data: any, logger?: Logger): SitecoreComponentEsiData|undefined => {
            if (!logger) logger = getNullLogger();
            if (!data) {
                logger.debug('Sitecore ESI personalization manager - No data was provided to the data fetcher result reader.');
                return;
            }
            const placeholders = data.sitecore?.route?.placeholders ?? {};
            const keys = Object.keys(placeholders);
            for (let i=0; i<keys.length; i++) {
                const placeholder = placeholders[keys[i]] as SitecoreComponentEsiData[];
                const index = placeholder.findIndex(r => r.uid == rendering.uid);
                if (index != -1) {
                    return placeholder[index];
                }
            }
            return;
        }
        return reader;
    }
    logger.error('Sitecore ESI personalization manager - No data fetcher reader can be resolved for the specified context data source.', {contextDataSource});
    return;
}

function getTriggers(definitions: DependencyBasedRenderingPersonalizationDefinition) {
    const triggers: PersonalizationTriggers = {};
    let hasTriggers = false;

    Object.keys(definitions).forEach(renderingUid => {
        const dependencies = definitions[renderingUid].dependencies;
        if (Array.isArray(dependencies)) {
            hasTriggers = true;
            triggers[renderingUid] = dependencies;
        }
    })
    return hasTriggers ? triggers : undefined;
}

function flattenArrays(source:any[], target:any[]) {
    if (!Array.isArray(source)) {
        return;
    }
    for (let i=0; i<source.length; i++) {
        const value = source[i];
        if (value == undefined || value == null) {
            continue;
        }
        if (Array.isArray(value)) {
            flattenArrays(value, target);
            continue;
        }
        if (target.indexOf(value) == -1) {
            target.push(value);
        }
    }
}

/**
 * Gets a Sitecore personalization manager.
 * @param args
 */
export function getSitecoreEsiPersonalizationManager(args: GetSitecoreEsiPersonalizationManagerArgs): PersonalizationManager<RenderingDefinition, SitecoreItem> {
    const id = "SC_ESI_PERS_MGR";
    const { disabled = false, contextData, contextDataSource = "jss-esi", logger = getNullLogger(), dataFetcher = getDefaultDataFetcher(), getDataFetcherUrl = getDefaultGetDataFetcherUrl } = args;
    logger.debug("Get sitecore ESI personalization manager - START.", { args });
    //
    //
    const reader = getSitecoreContextDataReader(contextDataSource);
    const page = reader?.getPageDetails(contextData);
    //
    //
    const definitions = reader?.getDefinitions(contextData) as DependencyBasedRenderingPersonalizationDefinition;
    if (!definitions) {
        logger.debug("Get sitecore ESI personalization manager -   * No dependency-based personalization definitions were resolved from the context data. Only origin-based personalization will be used. Returning a disabled personalization manager.", { args });
        logger.debug("Get sitecore ESI personalization manager - END.", { args });
        return getDisabledSitecoreEsiPersonalizationManager();
    }
    logger.debug("Get sitecore ESI personalization manager -   * Dependency-based personalization definitions were resolved, so get the appropriate personalization triggers.", { definitions });
    const triggers: | PersonalizationTriggers | undefined = getTriggers(definitions);
    if (!triggers) {
        logger.debug("Get sitecore ESI personalization manager -   * No personalization triggers were resolved. All personalization will be origin-based.", { definitions });
    }
    //
    //
    const testManager: TestManager = args.testManager ?? getNullTestManager();
    if (!args.testManager) {
        logger.debug("Get sitecore ESI personalization manager -   * No test manager was specified in args, so create a dummy manager. Testing is effectively disabled.", { args });
    }
    else {
        logger.debug("Get sitecore ESI personalization manager -   * Test manager was resolved.", { testManager });
    }
    //
    //
    const subscriptions = getSubscriptionManager<PersonalizationManagerEvent>(`${id}_SUBS`, false, logger);
    //
    //
    function getDefaultGetDataFetcherUrl(item: string, rendering: string): string {
        const { sitecoreApiKey, sitecoreSiteName } = args;
        const url = `/sitecore/api/layout/render/jss?item=${item}&sc_apikey=${sitecoreApiKey}&sc_site=${sitecoreSiteName}&rendering=${rendering}`;
        if (!sitecoreApiKey) {
            logger.error("Sitecore ESI personalization manager - The default getDataFetcherUrl is used in the personalization manager, but no Sitecore API key was specified. Layout Service calls will likely fail.", { url });
        }
        if (!sitecoreSiteName) {
            logger.error("Sitecore ESI personalization manager - The default getDataFetcherUrl is used in the personalization manager, but no Sitecore site name was specified. Layout Service may call if Sitecore depends on this value for site resolution.", { url });
        }
        return url;
    }
    //
    //
    async function doPersonalize(rendering: RenderingDefinition, triggeredTriggers?: string[]) {
        if (!rendering) {
            logger.error('Sitecore ESI personalization manager -   * No rendering was specified.', { id });
            return;
        }
        if (disabled) {
            logger.debug('Sitecore ESI personalization manager -   * Personalization is disabled.', { id, rendering: rendering.uid });
            return;
        }
        if (!triggers) {
            logger.debug('Sitecore ESI personalization manager -   * No rendering triggers map were specified.', { id, rendering: rendering.uid });
            return;
        }
        if (!dataFetcher) {
            logger.error('Sitecore ESI personalization manager -   * No dataFetcher implementation was provided.', { id, rendering: rendering.uid });
            return;
        }
        const dataFetcherReader = args.dataFetcherReader ?? getDefaultDataFetcherReader(contextDataSource);
        if (!dataFetcherReader) {
            logger.error('Sitecore ESI personalization manager -   * No dataFetcher reader implementation was provided.', { id, rendering: rendering.uid });
            return;
        }
        if (!triggeredTriggers) {
            logger.debug('Sitecore ESI personalization manager -   * No triggers provided for personalization.', { id, rendering: rendering.uid });
            return;
        }
        //
        //With client-side personalization, all of the dependencies 
        //must be met in order for personalization to be performed
        //because personalization can be performed on a per-rule
        //basis.
        //
        //ESI personalization is different because it is performed
        //on a per-component basis. This means that as long as at
        //least one dependency is met, ESI personalization should
        //run.
        const dependencies:string[] = [];
        flattenArrays(triggers[rendering.uid], dependencies);
        //
        //
        if (dependencies.length > 0) {
            const atLeastOneDependencyIsMet = dependencies.some(dependency => triggeredTriggers.includes(dependency));
            if (!atLeastOneDependencyIsMet) {
                logger.debug(
                    'Sitecore ESI personalization manager -   * At least one rendering dependency must be met in order to fetch personalized data.',
                    { id, dependencies, triggers, page, rendering: rendering.uid }
                );
                return;
            }
            logger.debug(
                'Sitecore ESI personalization manager -   * At least one rendering dependency was met, so personalized data will be fetched.',
                { id, dependencies, triggers, page, rendering: rendering.uid }
            );
        }
        logger.debug('Sitecore ESI personalization manager -   * Setting rendering state to loading.', { id, page, rendering: rendering.uid });
        //Set to loading before applying the rules
        subscriptions.publish({
            component: rendering.uid,
            isLoading: true,
            page,
            type: 'state-changed',
            when: new Date(),
        });

        try {
            //
            //Get the data fetch url
            if (!page?.id) {
                logger.error('Sitecore ESI personalization manager -   * No page id is available, so no personalized data will be fetched.', { id, page, rendering: rendering.uid });
                return;
            }
            const fetchUrl = getDataFetcherUrl(page.id, rendering.uid);
            logger.debug('Sitecore ESI personalization manager -   * Fetching personalized data.', { id, fetchUrl, rendering: rendering.uid });

            const dataFetcherResult = await dataFetcher(fetchUrl);
            const pzData = dataFetcherReader(rendering, dataFetcherResult?.data, logger);
            if (!pzData) {
                logger.error('Sitecore ESI personalization manager -   * Data fetcher reader returned no data.', { id, fetchUrl, pzData, rendering: rendering.uid });
                return;
            }
            //
            //
            const esiData = pzData;
            const originActivity = pzData?.personalization;

            if (!originActivity) {
                logger.debug('Sitecore ESI personalization manager -   * Data fetcher returned data without origin-based personalization.', { id, fetchUrl, pzData, rendering: rendering.uid });
                return;
            }
            logger.debug('Sitecore ESI personalization manager -   * Data fetcher returned data with origin-based personalization.', { id, fetchUrl, pzData, rendering: rendering.uid });
            const context: RenderingPersonalizationContext = rendering;

            const result = evaluatePersonalization({
                activity: originActivity,
                data: esiData,
                logger,
                page,
                personalizationContext: context,
            });
            const includedInTest = testManager?.getIsIncludedInTest() ?? false;

            //
            //Publish a state-changed event to notify components that
            //personalization is done loading and that there may be
            //changes to render.
            const e = {
                activity: originActivity,
                changes: result.changes,
                component: rendering.uid,
                data: esiData,
                includedInTest,
                isLoading: false,
                page,
                personalizedData: result.fields,
                type: 'state-changed',
                when: new Date(),
            };
            logger.debug('Sitecore ESI personalization manager -   * Personalized data is available for the rendering. Publish the state-changed event so the component can be updated.', {
                id, 
                manager: subscriptions.id,
                result, 
                rendering: rendering.uid,
                event: e,
                me: manager
            });
            subscriptions.publish(e);
        } catch (error) {
            logger.error('Sitecore ESI personalization manager -   * Error occured while loading personalized data. Publish the state-changed event to indicate loading is finished.', { id, error, rendering: rendering.uid });
            subscriptions.publish({
                component: rendering.uid,
                error,
                isLoading: false,
                page,
                type: 'state-changed',
                when: new Date(),
            });
        }
    }
    const manager: PersonalizationManager<RenderingDefinition, SitecoreItem> = {
        id,
        disabled,
        page,
        subscriptions,
        triggers,
        testManager: args.testManager,
        onTrigger: async (trigger, rendering) => {
            logger.debug("Sitecore ESI personalization manager - START: onTrigger", { id, trigger, rendering: rendering.uid });
            await doPersonalize(rendering, [trigger]);    
            logger.debug("Sitecore ESI personalization manager - END: onTrigger", { id, trigger, rendering: rendering.uid });
        },
        personalize: async (rendering) => {
            logger.debug("Sitecore ESI personalization manager - START: personalize", { id, rendering: rendering.uid });
            await doPersonalize(rendering);
            logger.debug("Sitecore ESI personalization manager - END: personalize", { id, rendering: rendering.uid });
        }
    };
    logger.debug("Get sitecore ESI personalization manager - END.", { args, manager });
    return manager;
}

function evaluatePersonalization({
    // activity,
    data,
    logger,
    page,
    personalizationContext,
}: {
    activity?: any,
    data?: SitecoreComponentEsiData;
    logger: Logger;
    page?: SitecoreItem;
    personalizationContext: RenderingPersonalizationContext;
}): ComponentChangedResult {
    const changes: PersonalizationChanges = data?.personalization?.changes ?? {};
    const component = getComponent(personalizationContext);
    const fieldsAfter = (data as any).fields;

    const result: ComponentChangedResult = {
        changes,
        component,
        data: data,
        fields: fieldsAfter,
    };

    logger.debug('Sitecore ESI personalization manager -   * Component should be personalized.', {
        ...result,
        item: page,
    });

    return result;
}

function getComponent(context: RenderingPersonalizationContext): Description {
    return {
        id: context.uid,
        description: context.componentName,
    };
}

function getDisabledSitecoreEsiPersonalizationManager(): PersonalizationManager<
    RenderingDefinition,
    SitecoreItem
> {
    const id = "SC_ESI_PERS_MGR_DISABLED";
    return {
        id,
        disabled: true,
        onTrigger: async () => {},
        page: {},
        personalize: async () => {},
        subscriptions: {
            id: `${id}_SUBS`,
            publish: (_data) => null,
            subscribe: (_type, _callback) => createUniformUnsubscribe(() => false),
            getSubscribers: (_type) => [],
            getSubscriptionTypes: () => []
        },
        triggers: {}
    };
}
