export function flattenArray(source:any[], target:any[]) {
    if (!Array.isArray(source)) {
        return;
    }
    for (let i=0; i<source.length; i++) {
        const value = source[i];
        if (value == undefined || value == null) {
            continue;
        }
        if (Array.isArray(value)) {
            flattenArray(value, target);
            continue;
        }
        if (target.indexOf(value) == -1) {
            target.push(value);
        }
    }
}

export function appendArray(source: (any[] | undefined), target: any[]): void {
    if (!source) {
        return;
    }
    source.forEach(value => {
        if (target.indexOf(value) == -1) {
            target.push(value);
        }
    })
}
