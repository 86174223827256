import { Logger, getNullLogger } from '@uniformdev/common';
import { Visit } from '../../models/visit';
import { Visitor } from '../../models/visitor';
import { SitecoreContextReaderType, getSitecoreContextReader, CONTEXT_SOURCE_SITECORE } from './contextReaders';
import { DecaySettings, getDifferenceAsTimeIncrements, doDecay, getDefaultDecaySettings } from '../../decay';
import { ContextReader } from '../../contextReader';
import { Tracker, GetTrackerArgs, getDefaultTracker } from '../../trackers';

export enum SitecoreCookieNames {
    Campaign = "UNIFORM_TRACKER_SITECORE_campaign",
    Campaigns = "UNIFORM_TRACKER_SITECORE_campaigns",
    Goals = "UNIFORM_TRACKER_SITECORE_goals",
    PatternMatches = "UNIFORM_TRACKER_SITECORE_pattern_matches",
    ProfileScores = "UNIFORM_TRACKER_SITECORE_profile_scores",
}

/**
 * Settings that are specific for when the 
 * tracker is used with Sitecore data.
 */
export interface GetSitecoreTrackerArgs extends GetTrackerArgs {
    type?: SitecoreContextReaderType;
    decay?: DecaySettings;
    doNotIncludeDefaultContextReaders?: boolean;
}

/**
 * Gets a map of context readers that are able 
 * to read Sitecore tracking data.
 * @param args 
 * @param logger 
 */
function getDefaultContextReadersForSitecoreTracker(args: GetSitecoreTrackerArgs, logger: Logger): Map<string, ContextReader[]> {
    const reader = getSitecoreContextReader(args.type ?? 'default', logger);
    const readers = new Map<string, ContextReader[]>();
    readers.set(CONTEXT_SOURCE_SITECORE, [reader]);
    return readers;
}

/**
 * Adds default context readers to args based on settings in args.
 * @param args 
 * @param logger 
 */
function addDefaultContextReadersIfNeeded(args: GetSitecoreTrackerArgs, logger: Logger) {
    if (args.doNotIncludeDefaultContextReaders == true) {
        logger.debug("getSitecoreTracker - Will not add default context readers.", args);
        return;
    }
    logger.debug("getSitecoreTracker - Adding default context readers.", args);
    const defaultMap = getDefaultContextReadersForSitecoreTracker(args, logger);
    if (!defaultMap) {
        logger.debug("getSitecoreTracker - No default default context readers were retrieved.", args);
        return;
    }
    if (!args.contextReaders) {
        args.contextReaders = defaultMap;
        return;
    }
    Array.from(defaultMap.keys()).forEach(key => {
        const defaultReaders = defaultMap.get(key) ?? [];
        if (defaultReaders.length == 0) {
            return;
        }
        const specifiedReaders = args.contextReaders!.get(key) ?? [];
        defaultReaders.forEach(defaultReader => {
            if (specifiedReaders?.findIndex(specifiedReader => specifiedReader.type == defaultReader.type) != -1) {
                specifiedReaders.push(defaultReader);
            }
        });
        args.contextReaders!.set(key, specifiedReaders);
    });
    args.contextReaders = getDefaultContextReadersForSitecoreTracker(args, logger);
}

/**
 * This function simplifies the process of creating
 * a tracker for a Sitecore site by adding default
 * settings that are needed when tracking data is
 * provided from Sitecore (whether the data comes
 * from JSS Layout Service or the Uniform Page 
 * Service).
 * @param args 
 * @param logger 
 */
export function getSitecoreTracker(args: GetSitecoreTrackerArgs, logger: Logger): Tracker | undefined {
    if (!logger) {
        logger = getNullLogger();
    }
    addDefaultContextReadersIfNeeded(args, logger);
    if (!args.contextReaders) {
        logger.error("getSitecoreTracker - No context readers were resolved for the Sitecore tracker. Without a context reader, the tracker is unable to read trackable data. The null tracker will be used. This tracker does not track anything; it simply writes to the log.", args);
        return undefined;
    }
    logger.debug("getSitecoreTracker - Context reader(s) resolved for Sitecore tracker.", args.contextReaders);
    logger.debug("getSitecoreTracker - Using Sitecore profile decay settings.", args.decay);
    args.extensions = {
        onNewVisitCreated: (date: Date, visitor: Visitor, oldVisit: Visit | undefined, newVisit: Visit, logger: Logger): void => {
            const referrer = window?.document?.referrer;
            newVisit.data.referrer = referrer;
            logger.debug("Sitecore tracker extensions - New visit created, so set referrer.", { referrer });
            if (oldVisit) {
                const settings: DecaySettings = args.decay ?? getDefaultDecaySettings();
                applyProfilesFromOldVisit(settings, date, visitor, oldVisit, newVisit, logger);
            }
        }
    }
    return getDefaultTracker(args, { 
        logger
    });
}

function getProfileDataWithDecay(oldData: any, differenceForDecay: number, settings: DecaySettings, logger: Logger): any {
    //
    //Add the decayed profile scores into a buffer.
    const newData: any = {};
    Object.keys(oldData).forEach(profileId => {
        const profile = oldData[profileId];
        const keys: any = {};
        //
        //If, after applying decay, the profile still has values,
        //the update count should be set to 1 to indicate
        let hasValues: boolean = false;
        Object.keys(profile.keys).forEach(profileKeyId => {
            const oldProfileKey = profile.keys[profileKeyId];
            const newProfileKey = JSON.parse(JSON.stringify(oldProfileKey));
            newProfileKey.value = doDecay(newProfileKey.value ?? 0, differenceForDecay, settings, logger);
            if (!hasValues && newProfileKey.value > 0) {
                hasValues = true;
            }
            keys[profileKeyId] = newProfileKey;
        });
        //
        //Since the update count is decayed at the same rate as the 
        //profile, it is possible that the update count will be zero
        //while the decayed profile still has values. In this case,
        //the update count should be set to 1.
        let decayedUpdateCount = doDecay(profile.updateCount ?? 0, differenceForDecay, settings, logger);
        if (hasValues && decayedUpdateCount == 0) {
            logger.debug("Sitecore tracker extensions - After decay was applied, the update count for the profile was zero. But since the decayed profile still has values, the update count will be set to one.", {profile: profileId, keys});
            decayedUpdateCount = 1;
        }
        //
        //Set the decayed values on the buffer.
        newData[profileId] = {
            keys: keys,
            name: profile.name,
            updateCount: doDecay(profile.updateCount, differenceForDecay, settings, logger)
        }
    });
    return newData;
}

/**
 * Applies the profile scores from an old visit to
 * a new visit and the associated visitor. This is
 * where decay logic is applied to the profile scores.
 * @param settings 
 * @param date 
 * @param oldVisit 
 * @param newVisit 
 * @param visitor
 * @param logger 
 */
function applyProfilesFromOldVisit(settings: DecaySettings, date: Date, visitor: Visitor, oldVisit: Visit, newVisit: Visit, logger: Logger): void {
    if (!oldVisit) {
        return;
    }
    if (!newVisit) {
        logger.error("Sitecore tracker extensions - Cannot copy profiles to new visit when no new visit is provided.", { oldVisitId: oldVisit.id });
        return;
    }
    logger.debug("Sitecore tracker extensions - New visit created, so apply profiles from the old visit.", {date, visitorId: visitor.id, oldVisit, newVisit});
    //
    //Get values from the old visit
    const profileDataFromOldVisit = oldVisit.data?.profiles?.data;
    if (!profileDataFromOldVisit) {
        logger.debug("Sitecore tracker extensions - No profile data is set on the old visit, so there are no profiles to copy.", { oldVisitId: oldVisit.id, newVisitId: newVisit.id });
        return;
    }
    //
    //Get the decayed values
    const differenceForDecay = getDifferenceAsTimeIncrements(oldVisit.updated, newVisit.updated, settings, logger);
    logger.debug("Sitecore tracker extensions - Profile decay values were determined.", { unit: settings.timeUnit, increments: differenceForDecay });
    const profileDataForNewVisit = getProfileDataWithDecay(profileDataFromOldVisit, differenceForDecay, settings, logger);
    logger.debug("Sitecore tracker extensions - Decayed profile was determined.", { withDecay: profileDataForNewVisit, withoutDecay: profileDataFromOldVisit });
    //
    //Set the decayed values on the new visit
    if (!newVisit.data) {
        newVisit.data = {};
    }
    if (newVisit.data.profiles) {
        logger.debug("Sitecore tracker extensions - The current profiles on the new visit will be replaced with decayed profile from the old visit.", { newVisitId: newVisit.id, current: newVisit.data.profiles });
    }
    logger.debug("Sitecore tracker extensions - The decayed profiles from the old visit will be applied to the new visit.", { oldVisitId: oldVisit.id, newVisitId: newVisit.id, decay: settings, differenceForDecay, newProfiles: profileDataForNewVisit });
    const newProfiles = { 
        data: profileDataForNewVisit, 
        date: date.toISOString() 
    };
    newProfiles.date = date.toISOString();
    newVisit.data.profiles = newProfiles;
    //
    //Set the decayed values on the visitor
    if (!visitor) {
        logger.debug("Sitecore tracker extensions - No visitor was specified so the decayed profiles cannot be assigned to the visitor.", { visitorId: oldVisit.visitorId, newProfiles });
        return;
    }
    if (!visitor.data) {
        visitor.data = {};
    }
    logger.debug("Sitecore tracker extensions - The decayed profiles from the old visit will be applied to visitor.", { oldVisitId: oldVisit.id, visitorId: visitor.id, decay: settings, differenceForDecay, newProfiles: profileDataForNewVisit });
    visitor.data.profiles = JSON.parse(JSON.stringify(newProfiles));
}
