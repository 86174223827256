import { throwException } from '..';

export function getEnv(env: any, name: string, defaultValue: string | undefined = undefined): string {
    return getEnvEx(env[name], name, defaultValue);
}

export function getBoolEnv(env: any, name: string, defaultValue: boolean | undefined = undefined): boolean {
    let value = env[name];
    if (value === undefined) {
        return defaultValue === undefined
            ? throwException(`FATAL .env file lacks ${name} bool value`)
            : defaultValue;
    }

    value = value.toString().trim().toLowerCase();
    return value === 'true' || value === '1' || value === 'yes';
}

export function getEnvEx(value: string, name: string, defaultValue: string | undefined = undefined) {
    if (value === undefined) {
        return defaultValue === undefined
            ? throwException(`FATAL .env file lacks ${name} value`)
            : defaultValue;
    }
    if (Object.prototype.toString.call(value) === '[object String]') {
        return value.trim();
    }
    return value;
}
