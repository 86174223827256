import { Visit } from '../../models/visit';
import { Visitor } from '../../models/visitor';
import { TrackedActivityResults, VisitActivity } from '../../models/trackedActivity';
import { getPatternMatcher, PatternMatcher } from './patternMatchers';
import { ContextReader, ContextReaderContext } from '../../contextReader';
import { ProfilesStored, ProfileStored, ProfileDefinitions, PatternMatches, PatternMatch, ProfileKeyDefinition, ProfileDefinition } from './profiles';
import { Logger, getNullLogger , tryFormatGuid } from '@uniformdev/common';
import { getScorer, ProfileKeyScores, ProfileScores } from './scoring';
import { UniformWindow } from '../../trackers/global';
import { PersonalizationDetails } from '../../trackers'
import { GetTrackedActivitySettings } from 'index';

declare let window: UniformWindow;

export const CONTEXT_SOURCE_SITECORE = "sitecore";

export type SitecoreContextReaderType = 'cookie' | 'js' | 'jss' | 'uniform' | 'default';

export function getSitecoreContextReader(type: SitecoreContextReaderType, logger: Logger = getNullLogger()): ContextReader {
    switch (type) {
        case 'cookie':
            return getNotImplementedContextReader(type);
        case 'js':
            return getJsContextReader();
        case 'jss':
            return getJssContextReader();
        case 'default':
        case 'uniform':
            break;
        default:
            logger.info("Sitecore context reader - The specified Sitecore context reader type is not supported. The default type will be used.", {source: type});
    }
    return getUniformContextReader();
}

function getNotImplementedContextReader(type: string): ContextReader {
    return {
        type,
        getTrackedActivity(source: string | undefined, context: ContextReaderContext, _settings?: GetTrackedActivitySettings): TrackedActivityResults {
            const { visit, visitor, logger = getNullLogger() } = context;
            const activity = new TrackedActivityResults(visit, visitor);
            if (source !== "sitecore") {
                return activity;
            }
            logger.error("Sitecore context reader - Context reader not implemented.", { source, type: this.type });
            return activity;
        }
    }
}

function getSitecoreTrackingContext(url: URL|undefined, tracking: any): SitecoreTrackingContext {
    return {
        campaigns: tracking.campaigns,
        campaignParameters: tracking.settings?.campaigns?.parameters,
        goals: tracking.goals,
        goalParameters: tracking.settings?.goals?.parameters,
        page: {
            item: tracking.item,
            url
        },
        pageEvents: tracking.events,
        personalization: tracking.personalization,
        profiles: tracking.profiles
    };
}

function getJsContextReader(): ContextReader {
    return {
        type: "js",
        getTrackedActivity(source: string | undefined, readerContext: ContextReaderContext, settings?: GetTrackedActivitySettings): TrackedActivityResults {
            const { url, context, visit, visitor, date, logger = getNullLogger() } = readerContext;
            const activity = new TrackedActivityResults(visit, visitor);
            if (source !== "sitecore") {
                return activity;
            }
            const tracking = context?.tracking ?? {};
            logger.debug("JS context reader - Reading tracking activity from context.", {tracking, context, settings});
            const context2 = getSitecoreTrackingContext(url, tracking);
            doSetActivityResults(context2, date, activity, settings ?? {}, logger);
            return activity;
        }
    }
}

function getTrackingForJss(readerContext: ContextReaderContext, logger: Logger): any {
    const { context } = readerContext;
    if (context?.sitecore?.context?.tracking) {
        logger.debug("JSS context reader - Tracking data retrieved from context.sitecore.context.tracking.", context);
        return context.sitecore.context.tracking;
    }
    if (context?.tracking) {
        logger.debug("JSS context reader - Tracking data retrieved from context.tracking.", context);
        return context.tracking;
    }
    if (context) {
        logger.debug("JSS context reader - Tracking data retrieved from context.", context);
        return context;
    }
    return undefined;
}

function getJssContextReader(): ContextReader {
    return {
        type: "jss",
        getTrackedActivity(source: string | undefined, readerContext: ContextReaderContext, settings?: GetTrackedActivitySettings): TrackedActivityResults {
            const { url, context, visit, visitor, date, logger = getNullLogger() } = readerContext;
            const activity = new TrackedActivityResults(visit, visitor);
            if (source !== "sitecore") {
                return activity;
            }
            
            const tracking = getTrackingForJss(readerContext, logger) ?? {};
            logger.debug("JSS context reader - Reading tracking activity from context.", {tracking, context, settings});
            const context2 = getSitecoreTrackingContext(url, tracking);
            doSetActivityResults(context2, date, activity, settings ?? {}, logger);
            return activity;
        }
    }
}

function getUniformContextReader(): ContextReader {
    return {
        type: "uniform",
        getTrackedActivity(source: string | undefined, readerContext: ContextReaderContext, settings?: GetTrackedActivitySettings): TrackedActivityResults {
            const { url, context, visit, visitor, date, logger = getNullLogger() } = readerContext;
            const activity = new TrackedActivityResults(visit, visitor);
            if (source !== "sitecore") {
                return activity;
            }
            const tracking = context.tracking;
            logger.debug("Uniform context reader - Reading tracking activity from context.", {tracking, context, settings});
            const context2 = getSitecoreTrackingContext(url, tracking);
            doSetActivityResults(context2, date, activity, settings ?? {},logger);
            return activity;
        }
    }
}

/**
 * The tracking data that can be configured in Sitecore.
 */
interface SitecoreTrackingContext {
    campaigns: any;
    campaignParameters?: string[];
    goals: any;
    goalParameters?: string[];
    page: any;
    pageEvents: any;
    personalization?: PersonalizationDetails;
    profiles: ProfileDefinitions;
}

function doSetActivityResults(context: SitecoreTrackingContext, date: string, activity: TrackedActivityResults, settings: GetTrackedActivitySettings, logger: Logger) {
    logger.debug("Sitecore context reader - START: reading activity results.", { context, date, activity, settings });
    if (!context.page) {
        logger.debug("Sitecore context reader -   * No page was found in the context so page view will not be tracked.", context);
    }
    else {
        handlePageView(context.page, activity, date, logger);
        if (!context.page.url) {
            logger.debug("Sitecore context reader -   * No page url was found in the context so no query string parameters will be tracked.", context);
        }
        else {
            handleQueryStringParameters(context.page.url.searchParams, context, activity, date, logger);
        }
    }
    //
    //
    if (!context.goals) {
        logger.debug("Sitecore context reader -   * No goals were found in the context so none will be tracked.", context);
    }
    else {
        handleGoals(context.goals, activity, date, logger);
    }
    //
    //
    if (!context.pageEvents) {
        logger.debug("Sitecore context reader -   * No page events were found in the context so none will be tracked.", context);
    }
    else {
        handlePageEvents(context.pageEvents, activity, date, logger);
    }
    //
    //
    if (!context.campaigns) {
        logger.debug("Sitecore context reader -   * No campaigns were found in the context so none will be tracked.", context);
    }
    else {
        handleCampaigns(context.campaigns, activity, date, logger);
    }
    //
    //
    const points = getEngagementValue(context);
    if (points == 0) {
        logger.debug("Sitecore context reader -   * No changes to engagement value were found in the context so it will not be updated.", context);
    }
    else {
        handleEngagementValue(points, activity, date, logger);
    }
    //
    //
    if (!context.profiles) {
        logger.debug("Sitecore context reader -   * No profiles were found in the context so none will be tracked.", context);
    }
    else {
        handleProfiles(context.profiles, activity, date, logger);
    }
    //
    //
    const personalization = context.personalization;
    if (!personalization) {
        logger.debug("Sitecore context reader -   * No personalization details were found, so no origin-generated personalization activity will be tracked. Note: Personalization details will only be included when origin-based personalization occurs. If no origin-based personalization is used, this message indicates things are working as expected.", context);
    }
    else {
        handlePersonalization(personalization, activity, date, settings, logger);
    }
    logger.debug("Sitecore context reader - END: reading activity results.", { context, date, activity, settings });
}

function getEngagementValue(context: SitecoreTrackingContext): number {
    if (!context?.goals) {
        return 0;
    }
    let points = 0;
    Object.keys(context.goals).forEach(key => {
        const goal = context.goals[key];
        if (!isNaN(goal?.points)) {
            points += goal.points;
        }
    });
    Object.keys(context.pageEvents).forEach(key => {
        const pageEvent = context.pageEvents[key];
        if (!isNaN(pageEvent?.points)) {
            points += pageEvent.points;
        }
    });
    return points;
}

function handleEngagementValue(points: number, activity: TrackedActivityResults, date: string, _logger: Logger) {
    if (isNaN(points) || points <= 0) {
        return;
    }
    const doUpdateValue = (source: any):void => {
        if (!source.data) {
            source.data = {};
        }
        const currentValue = source.data.value?.data ?? 0;
        source.data.value = {
            date,
            data: currentValue + points
        }
    }
    activity.visitUpdateCommands.push((visit: Visit) => {
        doUpdateValue(visit);
    });
    activity.visitorUpdateCommands.push((visitor: Visitor) => {
        doUpdateValue(visitor);
    });
}

function handleGoals(goals: any, activity: TrackedActivityResults, date: string, _logger: Logger) {
    if (!goals) {
        return;
    }
    Object.keys(goals).forEach(key => {
        const goal = goals[key];
        activity.visitActivities.push({
            type: "goal",
            date,
            data: { id: key, ...goal }
        });
    });
}

function handlePageEvents(pageEvents: any, activity: TrackedActivityResults, date: string, _logger: Logger) {
    if (!pageEvents) {
        return;
    }
    Object.keys(pageEvents).forEach(key => {
        const pageEvent = pageEvents[key];
        activity.visitActivities.push({
            type: "page event",
            date,
            data: { id: key, ...pageEvent }
        });
    });
}

function handlePageView(page: any, activity: TrackedActivityResults, date: string, _logger: Logger) {
    if (!page) {
        return;
    }
    activity.visitActivities.push({
        type: "page view",
        date,
        data: page
    });
}

function handleQueryStringParameters(params: URLSearchParams, context: SitecoreTrackingContext, activity: TrackedActivityResults, date: string, logger: Logger) {
    if (!params) {
        return;
    }
    //
    //Handle campaigns.
    if (context?.campaignParameters) {
        context.campaignParameters.forEach(paramName => {
            const campaignIds = params.getAll(paramName);
            if (campaignIds.length > 0) {
                const campaigns: any = {};
                campaignIds.forEach(id => {
                    //
                    //If the campaign id is a guid, format it properly.
                    const id2 = tryFormatGuid(id, "D");
                    //
                    //If the value isn't a valid id, assume that
                    //the value specified is the campaign name.
                    const name = id2 ? null : id;
                    //
                    //TODO: If the campaign name is required then the 
                    //      value must be read from somewhere.
                    campaigns[id2] = { name };
                });
                handleCampaigns(campaigns, activity, date, logger);
            }
        });
    }
    //
    //Handle goals.
    if (context?.goalParameters) {
        context.goalParameters.forEach(paramName => {
            const goalIds = params.getAll(paramName);
            if (goalIds.length > 0) {
                const goals: any = {};
                goalIds.forEach(id => {
                    //
                    //If the goal id is a guid, format it properly.
                    const id2 = tryFormatGuid(id, "D");
                    //
                    //If the value isn't a valid id, assume that
                    //the value specified is the goal name.
                    const name = id2 ? null : id;
                    //
                    //TODO: If the goal name is required then the 
                    //      value must be read from somewhere.
                    goals[id2] = { name };
                });
                handleGoals(goals, activity, date, logger);
            }
        });
    }
}


function getUniqueValues(values: any): string[] {
    if (!values) return [];
    if (!Array.isArray(values)) return [(values as string)];
    const uniqueValues: string[] = [];
    if (Array.isArray(values)) {
        values.forEach(value => {
            const values2 = getUniqueValues(value);
            values2.forEach(value2 => {
                if (uniqueValues.indexOf(value2) == -1) uniqueValues.push(value2);
            });
        });
    }
    return uniqueValues;
}

function handlePersonalization(personalization: PersonalizationDetails, activity: TrackedActivityResults, date: string, settings: GetTrackedActivitySettings, logger: Logger) {
    if (!personalization) {
        return;
    }
    //
    //
    logger.debug("Sitecore context reader -   * Looking for unmet personalization dependencies.", { personalization, settings });
    const dependencies: string[] = [];
    Object.keys(personalization).forEach(key => {
        const value = personalization[key];
        const dependencies2 = getUniqueValues(value?.dependencies);
        dependencies2.forEach(dependency2 => {
            if (dependencies.indexOf(dependency2) == -1) dependencies.push(dependency2);
        });
    });
    if (dependencies.length > 0) {
        const dependenciesToIgnore = settings?.personalization?.dependenciesToIgnore ?? [];
        const unmetDependencies: string[] = [];
        dependencies.forEach(dependency => {
            if (dependenciesToIgnore.indexOf(dependency) == -1) {
                unmetDependencies.push(dependency);
            }
        });
        if (unmetDependencies.length > 0) {
            logger.debug("Sitecore context reader -   * Unmet personalization dependencies were found, so do not add origin-based personalization.", { dependencies, unmetDependencies, personalization, settings });
            return;
        }
    }
    logger.debug("Sitecore context reader -   * No unmet personalization dependencies were found.", { dependencies, personalization, settings });
    //
    //
    const activities: VisitActivity[] = [];
    Object.keys(personalization).forEach(key => {
        const value = personalization[key];
        if (!value?.activity) return;
        const e = {
            type: "personalization",
            date,
            data: value.activity
        };
        logger.debug("Sitecore context reader -   * Adding personalization activity from origin-based personalization to tracked activity results.", { rendering: key, event: e });
        activity.visitActivities.push(e);
        activities.push(e);
    });
    if (activities.length == 0) {
        logger.debug("Sitecore context reader -   * No origin-based personalization events were resolved.", { personalization });
    }
    else {
        logger.debug("Sitecore context reader -   * Origin-based personalization events were added to tracked activity results.", { activities, personalization });
    }

    // Object.keys(personalization).forEach(key => {
    //     const obj = personalization[key];
    //     if (!obj) {
    //         logger.error("Sitecore context reader - Key was included in personalization data but no object was set. Personalization data is corrupt.", { key, personalization });
    //         return;
    //     }
    //     let data = obj.activity as PersonalizationEventData;
    //     if (!data) {
    //         logger.debug("Sitecore context reader - No activity property is set on the personalization object, but the object itself might be activity.", { key, personalization });
    //         if ((obj as PersonalizationEventData).changes) {
    //             data = obj as PersonalizationEventData;
    //         }
    //     }
    //     if (!data) {
    //         logger.debug("Sitecore context reader - Key was included in personalization data but no activity was set. This usually means the component has personalization but none of the personalization rules were activated.", { key, personalization });
    //         return;
    //     }
    //     //
    //     //With server-side rendering, two separate tasks are performed:
    //     // 1. Conditional logic determines what content to display.
    //     // 2. Personalization events are created for the client tracker.
    //     //
    //     //The code that creates the personalization events does not know
    //     //whether the visitor is in a test. The personalization event is
    //     //the same regardless of whether the visitor is in a test, with
    //     //the exception of the isIncludedInTest value. 
    //     //
    //     //The following code sets this value on the personalization event
    //     //to the value from the testing cookie. This must happen before 
    //     //the event is associated with the visit.
    //     const cookie = getCookie(UniformCookieNames.Testing);
    //     if (cookie) {
    //         const parts = cookie.split('|');
    //         if (parts.length == 2) {
    //             const included = parts[1] == 'T' ? true : false;
    //             if (data.isIncludedInTest != true && included) {
    //                 logger.debug("Sitecore context reader - Setting the isIncludedInTest value on the personalization event to true in order to match the testing cookie value.", { key, data });
    //                 data.isIncludedInTest = true;
    //             }
    //         }
    //     }
    //     const e = {
    //         type: "personalization",
    //         date,
    //         data
    //     };
    //     logger.debug("Sitecore context reader - Adding personalization activity from origin-based personalization.", { event: e });
    //     activity.visitActivities.push(e);
    //     logger.debug("Sitecore context reader - Origin-based personalization event was handled, so remove the definition from the collection.", { key, personalization: { ...personalization } });
    //     delete personalization[key];
    // });
}

function getStoredProfile(profileId: string, source: Visit|Visitor): ProfileStored | undefined {
    const profilesData = source.data?.profiles?.data;
    if (profilesData) {
        return profilesData[profileId] as ProfileStored;
    }
    return undefined;
}

function getCurrentProfileScoresFromSource(profileId: string, source: Visit|Visitor): ProfileKeyScores {
    const scores = {} as ProfileKeyScores;
    const profile = getStoredProfile(profileId, source);
    if (profile) {
        scores.updateCount = profile.updateCount;
        Object.keys(profile.keys).forEach(profileKeyId => {
            scores[profileKeyId] = profile.keys[profileKeyId].value;
        });
    }
    return scores;
}

function getProfileUpdateCountFromSource(profileId: string, source: Visit|Visitor): number {
    const profile = getStoredProfile(profileId, source);
    if (profile) {
        return profile.updateCount;
    }
    return 0;
}

function updateProfileKeys(updatedScores: ProfileScores, currentProfile: ProfileStored, profileDefinition: ProfileDefinition) {
    Object.keys(updatedScores.keys).forEach(profileKeyId => {
        const updatedProfileKeyValue = updatedScores.keys[profileKeyId];
        //
        //Add the profile key to the current profile if needed.
        if (!currentProfile.keys) {
            currentProfile.keys = {};
        }
        if (!currentProfile.keys[profileKeyId]) {
            currentProfile.keys[profileKeyId] = {} as ProfileKeyDefinition;
        }
        //
        //Update the profile name on the current profile.
        if (profileDefinition.name) {
            currentProfile.name = profileDefinition.name;
        }
        //
        //Get the updated profile key name if available.
        let updatedProfileKeyName: string|undefined = undefined;
        if (profileDefinition.keys) {
            const profileKeyDefinition = profileDefinition.keys[profileKeyId];
            if (profileKeyDefinition) {
                updatedProfileKeyName = profileKeyDefinition.name;
            }
        }
        //
        //Update the profile key on the current profile.
        const currentProfileKey = currentProfile.keys[profileKeyId];
        if (updatedProfileKeyName) {
            currentProfileKey.name = updatedProfileKeyName;
        }
        currentProfileKey.value = updatedProfileKeyValue;
    });
}

function applyPatternMatching(matcher: PatternMatcher, profileDefinition: ProfileDefinition, updatedScores: ProfileScores, patternMatches: PatternMatches, profileId: string) {
    if (matcher && profileDefinition.patterns) {
        const match = matcher.match(updatedScores.keys, profileDefinition);
        if (match) {
            const pattern = profileDefinition.patterns[match.patternId];
            const patternMatch: PatternMatch = {
                profileName: profileDefinition.name,
                patternId: match.patternId,
                distance: match.distance,
                name: pattern.name
            };
            patternMatches[profileId] = patternMatch;
        }
        else {
            delete patternMatches[profileId];
        }
    }
}

function getUpdatesForTrackedActivityResults(date: string, profiles: ProfilesStored, patternMatches: PatternMatches): any[] {
    //
    //
    const updates: any[] = [];
    if (Object.keys(profiles).length > 0) {
        //
        //Add a visit activity to indicate the profile values were updated.
        if (Object.keys(profiles).length > 0) {
            updates.push({
                type: "profiles",
                date,
                data: profiles
            });
        }
        //
        //Add a visit activity to indicate the pattern matches were updated.
        if (Object.keys(patternMatches).length > 0) {
            updates.push({
                type: "patterns",
                date,
                data: patternMatches
            });
        }
    }
    return updates;
}

function handleProfiles(profileDefinitions: ProfileDefinitions, activity: TrackedActivityResults, date: string, logger: Logger) {
    if (!profileDefinitions || ! activity.visit) {
        return;
    }
    const matcher = getPatternMatcher();
    if (!matcher) {
        logger.warn("Sitecore context reader -   * No pattern matcher was resolved, so no pattern matches will be tracked.");
    }
    //
    //Create buffers to store the changes to the visit and visitor.
    //These changes are written to the visit and visitor after all
    //of the profiles are processed.
    const profilesVisit: ProfilesStored = {};
    const profilesVisitor: ProfilesStored = {};
    const patternMatchesVisit: PatternMatches = {};
    const patternMatchesVisitor: PatternMatches = {};
    //
    //For each profile, update the values and pattern matches on the visit.
    Object.keys(profileDefinitions).forEach(profileId => {
        //
        //Get the profile key values from the profile.
        const profileDefinition = profileDefinitions[profileId];
        //
        //Get the component responsible for updating the profile values.
        const score = getScorer(profileDefinition.type);
        if (!score) {
            logger.error("Sitecore context reader -   * No scorer was resolved, so this profile will not be tracked.", profileDefinition);
            return;
        }
        //
        //Get the profile key values to add to the current 
        //profile values. These are used to determine whether
        //to continue with the scoring process, and to provide
        //data for the visit activity event.
        const eventData: any = {
            id: profileId,
            keys: {}
        };
        let hasValues = false;
        Object.keys(profileDefinition.keys).forEach(profileKeyId => {
            const profileKey = profileDefinition.keys[profileKeyId];
            eventData.keys[profileKeyId] = profileKey.value;
            if (profileKey.value > 0) hasValues = true;
        });
        //
        //If no scores were set, continue to the next profile.
        if (!hasValues) {
            logger.debug("Sitecore context reader -   * No profile key values were greater than zero, so this profile will not be tracked.", profileDefinition);
            return;
        }
        //
        //Add a visit activity with the profile key values.
        activity.visitActivities.push({
            type: "profile score",
            date,
            data: eventData
        });
        //
        //Get the current profile values for the visit.
        const currentScoresVisit = getCurrentProfileScoresFromSource(profileId, activity.visit!);
        const currentUpdateCountVisit = getProfileUpdateCountFromSource(profileId, activity.visit!);
        const updatedScoresVisit = score(currentScoresVisit, profileId, profileDefinition, currentUpdateCountVisit);
        //
        //Get the current profile values for the visitor.
        const currentScoresVisitor = getCurrentProfileScoresFromSource(profileId, activity.visitor!);
        const currentUpdateCountVisitor = getProfileUpdateCountFromSource(profileId, activity.visitor!);
        const updatedScoresVisitor = score(currentScoresVisitor, profileId, profileDefinition, currentUpdateCountVisitor);
        //
        //Update the buffer with names and values for the visit.
        if (!profilesVisit[profileId]) {
            profilesVisit[profileId] = {
                updateCount: 0
            } as ProfileStored;
        }
        const currentProfileVisit = profilesVisit[profileId];
        //
        //Update the buffer with names and values for the visitor.
        if (!profilesVisitor[profileId]) {
            profilesVisitor[profileId] = {
                updateCount: 0
            } as ProfileStored;
        }
        const currentProfileVisitor = profilesVisitor[profileId];
        //
        //Keep track of the number of times the profile values
        //are updated. This value is needed to calculate
        //profile values in certain cases (i.e. when the 
        //profile is set to the type "Average").
        currentProfileVisit.updateCount = updatedScoresVisit.updateCount;
        currentProfileVisitor.updateCount = updatedScoresVisitor.updateCount;
        //
        //
        updateProfileKeys(updatedScoresVisit, currentProfileVisit, profileDefinition);
        profilesVisit[profileId] = currentProfileVisit;
        updateProfileKeys(updatedScoresVisitor, currentProfileVisitor, profileDefinition);
        profilesVisitor[profileId] = currentProfileVisitor;
        //
        //Pattern matching.
        applyPatternMatching(matcher, profileDefinition, updatedScoresVisit, patternMatchesVisit, profileId);
        applyPatternMatching(matcher, profileDefinition, updatedScoresVisitor, patternMatchesVisitor, profileId);
    });
    //
    //
    const updatesVisit = getUpdatesForTrackedActivityResults(date, profilesVisit, patternMatchesVisit);
    if (updatesVisit.length == 0) {
        logger.debug("Sitecore context reader -   * No profile scores changed on the visit, so no profile scoring will be tracked.");
    }
    else {
        updatesVisit.forEach(update => {
            activity.visitUpdates.push(update);
        });
    }
    //
    //
    const updatesVisitor = getUpdatesForTrackedActivityResults(date, profilesVisitor, patternMatchesVisitor);
    if (updatesVisitor.length == 0) {
        logger.debug("Sitecore context reader -   * No profile scores changed on the visitor, so no profile scoring will be tracked.");
    }
    else {
        updatesVisitor.forEach(update => {
            activity.visitorUpdates.push(update);
        });
    }
}

/**
 * Used to implement the logic to update the profile score.
 */
export interface UpdateCurrentValue { (newValue: number, currentValue: number, updateCount: number): number }

function handleCampaigns(campaigns: any, activity: TrackedActivityResults, date: string, logger: Logger) {
    if (!campaigns) {
        return;
    }
    //
    //There should be only 1 campaign. Create visit activity 
    //for each so there is tracking, but only create a visit
    //update for the first campaign.
    const trackableCampaigns = getTrackableCampaigns(campaigns, date, logger);
    if (trackableCampaigns.length == 0) {
        return;
    }
    //
    //Add visit activities
    trackableCampaigns.forEach(trackableCampaign => {
        activity.visitActivities.push({
            type: "campaign",
            date: date,
            data: trackableCampaign
        })
    });
    //
    //Add visit update
    activity.visitUpdates.push({
        type: "campaign",
        date: date,
        data: trackableCampaigns[trackableCampaigns.length - 1]
    });
}

function getTrackableCampaigns(campaigns: any, _date: string, _logger: Logger): any[] {
    const trackableCampaigns:any[] = [];
    if (campaigns) {
        for (let key in campaigns) {
            const trackableCampaign = campaigns[key];
            trackableCampaigns.push({
                id: key,
                name: trackableCampaign.name
            });
        }
    }
    return trackableCampaigns;
}
