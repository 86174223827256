import { OracleDmpDestination } from "../connectors/oracleDmp/destination";
import { getOracleDmpCallbackName, OracleDmpDispatcher, OracleDmpTrackedActivityConverter } from "../connectors/oracleDmp/dispatcher";
import { getOracleDmpTrackedActivityConverterForSitecore } from "../connectors/sitecore/oracleDmpEventConverter";
import { Destination, Dispatcher } from "../dispatchers";
import { UniformWindow } from "./global";
import { GetDispatchersArgs } from "./handleDestinations";
import { getMaxExpirationDate } from "../cookies";

export interface OracleDmpCallback { (data: any): void }

declare let window: UniformWindow;

export function getDispatchersForOracleDmpDestinations(destinations: Destination[], args: GetDispatchersArgs): Dispatcher[] {
    const { logger, loggerPrefix } = args;
    const dispatchers: Dispatcher[] = [];
    destinations.forEach(destination => {
        const dispatcher = getDispatcher(destination as OracleDmpDestination, args);
        if (!dispatcher) {
            logger.error(`${loggerPrefix} - Unable to get dispatcher for Oracle DMP destination.`, destination);
            return;
        }
        dispatchers.push(dispatcher);
    })
    return dispatchers;
}

function getDispatcher(destination: OracleDmpDestination, args: GetDispatchersArgs): Dispatcher|undefined {
    if (!destination) {
        return;
    }
    const { oracleDmp, logger, loggerPrefix } = args;
    const initializeFromArgs = oracleDmp?.initializeOracleDmp;
    const wasInitialized = initializeFromArgs ? initializeFromArgs(destination, logger) : doInitializeOracleDmp(destination, args);
    if (!wasInitialized) {
        logger.debug(`${loggerPrefix} - Unable to initialize Oracle DMP so no Oracle DMP dispatch will be performed.`, destination);
        return;
    }
    const converters = getActivityConverters(destination, args);
    const dispatcher = new OracleDmpDispatcher(converters, destination);
    return dispatcher;
}

function doInitializeOracleDmp(destination: OracleDmpDestination, args: GetDispatchersArgs): boolean {
    const { logger, loggerPrefix } = args;
    //
    //Add the callback function to window. This function must exist in order for data to be 
    if (!window) {
        logger.error(`${loggerPrefix} - Cannot initialize Oracle DMP dispatcher when window is undefined.`, destination);
        return false;
    }
    destination.containerIds.forEach(containerId => {
        const callbackName = getOracleDmpCallbackName(containerId);
        if (!callbackName) {
            logger.error(`${loggerPrefix} - Cannot initialize Oracle DMP dispatcher for container because callback name could not be determined.`, { containerId });
            return;
        }
        const callback: OracleDmpCallback = (data) => {
            //
            //Use settings from destination.dataHandling to determine 
            //how to handle the data.
            if (!destination.dataHandling) {
                logger.info(`${loggerPrefix} - No data handling settings were specified on the Oracle DMP destination, so the callback data will not be handled.`, {data, destination});
                return;
            }
            let wasDataChanged = false;
            if (data?.campaigns) {
                if (handleCampaigns(data.campaigns, destination, args)) {
                    wasDataChanged = true;
                };
            }
            if (wasDataChanged) {
                const manager = window?.uniform?.subscriptions;
                if (!manager) {
                    logger.info(`${loggerPrefix} - Unable to get a reference to the Uniform global subscription manager from the window, so unable to publish event to notify subscribers that Oracle DMP data was handled.`, { data, destination });
                    return;
                }
                if (!destination.triggerName) {
                    logger.info(`${loggerPrefix} - No trigger name is specified on the Oracle DMP destination, so unable to publish event to notify subscribers that Oracle DMP data was handled.`, { manager: manager.id, data, destination });
                    return;
                }
                logger.info(`${loggerPrefix} - Publishing event to notify subscribers that Oracle DMP data was handled.`, { manager: manager.id, data, destination });
                manager.publish({
                    type: destination.triggerName,
                    when: new Date()
                })
            }
        }
        Object.defineProperty(window, callbackName, { get: function () { return callback } });
    });
    return true;
}

function handleCampaigns(campaigns: any, destination: OracleDmpDestination, args: GetDispatchersArgs): boolean {
    const handling = destination.dataHandling;
    if (!handling) {
        return false;
    }
    let wasDataChanged = false;
    const dataTypes = ["campaigns", "audiences"];
    dataTypes.forEach(dataType => {
        const setting = handling[dataType];
        if (!setting) {
            return;
        }
        if (handleData(dataType, campaigns, (member) => member[setting.property], destination, args)) {
            wasDataChanged = true;
        }
    });
    return wasDataChanged;
}

/**
 * Returns true if the data being handled is different from the data that was previously handled.
 * @param dataType 
 * @param data 
 * @param getValue 
 * @param destination 
 * @param logger 
 */
function handleData(dataType: string, data: any, getValue: (member: any) => string, destination: OracleDmpDestination, args: GetDispatchersArgs): boolean {
    const { logger, loggerPrefix } = args;
    if (!destination?.dataHandling) {
        return false;
    }
    if (!Array.isArray(data)) {
        logger.debug(`${loggerPrefix} - Oracle DMP ${dataType} are expected to be an array.`, data);
        return false;
    }
    const setting = destination?.dataHandling[dataType];
    if (!setting) {
        logger.debug(`${loggerPrefix} - Oracle DMP destination is not configured to handle ${dataType} data.`, {destination, data});
        return false;
    }
    switch(setting.type) {
        case "cookie":
            return handleDataToCookie(setting.data, dataType, data, getValue, destination, args);
        default:
            logger.debug(`${loggerPrefix} - Unsupported type specified for handling ${dataType} data from Oracle DMP.`, {type: setting.type, data});
            return false;
    }
}

function handleDataToCookie(cookieName: string, dataType: string, data: any[], getValue: (member: any) => string, destination: OracleDmpDestination, args: GetDispatchersArgs): boolean {
    const { logger, loggerPrefix, getCookie, removeCookie, setCookie } = args;
    if (!cookieName) {
        logger.debug(`${loggerPrefix} - Oracle DMP destination is configured to save ${dataType} to a cookie, but the cookie name is not specified. Data will not be saved.`, {destination, data});
        return false;
    }
    const values: string[] = [];
    data.forEach(member => {
        const value = getValue(member);
        if (value && values.indexOf(value) == -1) {
            values.push(value);
        }
    });
    const currentValue = getCookie(cookieName);
    if (!currentValue && values.length == 0) {
        logger.debug(`${loggerPrefix} - No ${dataType} were returned from Oracle DMP and no values are currently set in the cookie, so data handling is complete.`, {cookie: cookieName, data});
        return false;
    }
    if (values.length == 0) {
        logger.debug(`${loggerPrefix} - No ${dataType} were returned from Oracle DMP so the cookie will be deleted.`, {cookie: cookieName, data});
        removeCookie(cookieName);
        return true;
    }
    const newValue = values.sort().join(',');
    if (newValue == currentValue) {
        return false;
    }
    logger.debug(`${loggerPrefix} - Data for ${dataType} from Oracle DMP will be set on the cookie.`, {cookie: cookieName, values});
    var date = getMaxExpirationDate();
    setCookie(cookieName, newValue, {expires: date, path: "/"});
    return true;
}

/**
 * Gets the tracked activity converters for the specified destination.
 * @param destination 
 * @param logger 
 */
function getActivityConverters(destination: OracleDmpDestination, args: GetDispatchersArgs): OracleDmpTrackedActivityConverter[] {
    const { logger, loggerPrefix } = args;
    const converters: OracleDmpTrackedActivityConverter[] = destination.activityConverters ?? [];
    if (destination.doNotUseDefaultActivityConverter != true) {
        const converter = getOracleDmpTrackedActivityConverterForSitecore();
        if (converter) {
            if (converters.every(c => c.type != converter.type)) {
                converters.push(converter);
                logger.debug(`${loggerPrefix} - Added default GA tracked activity converter for Sitecore.`, {converters});
            }
        }
    }
    return converters;
}
