import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Logger, getNullLogger } from '@uniformdev/common';
import { getSubscriptionManager, UniformUnsubscribe, SubscriptionManager, UniformCallback, initializeGlobalObject, UniformEvent, appendArray, appendObject } from '@uniformdev/optimize-common-sitecore';

import { ClientScripts } from '@uniformdev/common-client';
import { UniformContext } from '@uniformdev/optimize-react';
import { TrackerContext } from './TrackerContext';
import { useScripts } from './useScripts';
import { Tracker, QUEUE_ENTRY_TYPE_TRACKER, TrackerQueueEntry, TrackingSettings, GetTrackingUrl, getTrackerCookieSettings, TrackerCookieSettings, GetValueForCookie, GetCookieName, PersonalizationDetails, DispatchEventHandler } from '@uniformdev/tracking';
import { TrackedActivityResults, TrackingEventType, TrackingEvent } from '@uniformdev/tracking';
import { UniformWindow, ContextReader, CONTEXT_SOURCE_SITECORE } from '@uniformdev/tracking';
import { OnVisitorInitialized } from '@uniformdev/tracking';
import { UniformCookieNames, addSubscriptionsForTrackerCookies } from '@uniformdev/tracking';
import { getDispatchersForGaDestinations, getDispatchersForOracleDmpDestinations, getDispatchersForXdbDestinations } from '@uniformdev/tracking';
import { Dispatcher, GaDestination, OracleDmpDestination, XdbDestination, getDispatchersFromTrackingConfig, GetDispatchersArgs } from '@uniformdev/tracking';
import { DecaySettings, getDefaultDecaySettings, StorageProviderType } from '@uniformdev/tracking';
import { getSitecoreTracker, GetSitecoreTrackerArgs, SitecoreContextReaderType, getMaxExpirationDate } from '@uniformdev/tracking';
import ReactGA from 'react-ga';

export interface UseTrackerProps {
    /**
     * Client-side routing mode causes the hook to initialize the 
     * tracker, but prevents the hook from tracking. In this mode,
     * the application must call the tracker when appropriate (e.g. 
     * when the application determines the route has changed).
     */
    clientSideRouting?: boolean;
    /**
     * Names of the query string parameters that specify the campaign id.
     */
    campaignParameters?: string[];
    /**
     * Names of the query string parameters that specify the goal id.
     */
    goalParameters?: string[];
    ga?: GaDestination;
    xdb?: XdbDestination;
    oracleDmp?: OracleDmpDestination;
    logger?: Logger;
    sessionTimeout?: number;
    storage?: StorageProviderType;
    subscriptions?: (subscribe: (type: TrackingEventType, callback: UniformCallback<TrackingEvent>) => UniformUnsubscribe) => void,
    url?: GetTrackingUrl;
}

export type SitecoreRenderModes = 'normal' | 'preview' | 'edit';

export interface UseSitecoreTrackerProps extends UseTrackerProps {
    /**
     * Identifies the shape of the Sitecore context data.
     */
    type?: SitecoreContextReaderType;
    /**
     * Specifies how profile scores decay over time.
     * These settings provide more detailed control
     * over decay than standard Sitecore offers.
     */
    decay?: DecaySettings;
    /**
     * If true, only the specified context readers are used.
     * If false, the specified context readers are used in 
     * addition to the default context readers.
     * 
     * This option should only be used when context readers 
     * are explicitly specified.
     */
    doNotUseDefaultContextReader?: boolean;
    /**
     * These context readers are used to determine tracking
     * activity when tracker.track() is called.
     */
    contextReaders?: ContextReader[];
    /**
     * Setting context in cookies is important when visitor
     * behavior values must be transmitted on subsequent
     * origin calls (e.g. origin-based personalization).
     * 
     * This setting specifies which values to set in cookies.
     */
    cookies?: TrackerCookieSettings;
    /**
     * The tracker will only be activated when the page state
     * from the context matches one of the specified values.
     */
    renderModes?: SitecoreRenderModes[];
    /**
     * URLs for the scripts that need to be loaded before 
     * tracking can begin. Each script has an id. This
     * makes it possible to override specific URLs.
     */
    scripts?: ClientScripts;
    /**
     * This function is called when the tracker has visitor
     * activity data to dispatch.
     */
    onDispatch?: DispatchEventHandler;
    /**
     * Knowing when the tracker is initialized is important when
     * client-side routing is used. The client app triggers the
     * tracker when the route changes. But the route may change
     * before the tracker is initialized. This callback enables
     * the client app to trigger the tracker in this case.
     */
    onInitialized?: () => void;
    /**
     * This function is passed along to the tracker to enable
     * the developer to specify code that runs after the 
     * visitor is initialized.
     */
    onVisitorInitialized?: OnVisitorInitialized;
    /**
     * This functionallows you to override the default function
     * that determines the cookie name for a specified cookie type.
     */
    getCookieName?: GetCookieName;
    /**
     * This function allows you to override the default function
     * that determines the cookie value from the current visit.
     */
    getCookieValueFromVisit?: GetValueForCookie;
    /**
     * This function allows you to override the default function
     * that determines the cookie value from the visitor.
     */
    getCookieValueFromVisitor?: GetValueForCookie;
}

declare let window: UniformWindow;

/**
 * Merge tracking scripts from tracking data and props, with
 * values from props having priority.
 * @param trackingConfig 
 * @param props 
 */
function getTrackerScripts(trackingConfig: any, props?: UseSitecoreTrackerProps): ClientScripts {
    const scripts: ClientScripts = {};
    appendObject(trackingConfig?.settings?.scripts, scripts);
    appendObject(props?.scripts, scripts);
    return scripts;
}

/**
 * When dispatchers are configured by a developer, they 
 * are exposed in hook's props as destinations. This 
 * function controls the process responsible for 
 * converting these destinations into dispatchers.
 * @param props 
 * @param logger 
 */
function getDispatchersFromProps(props: UseSitecoreTrackerProps, args: GetDispatchersArgs): Dispatcher[] {
    const dispatchers: Dispatcher[] = [];
    if (props.ga) {
        appendArray(getDispatchersForGaDestinations([props.ga], args), dispatchers);
    }
    if (props.oracleDmp) {
        appendArray(getDispatchersForOracleDmpDestinations([props.oracleDmp], args), dispatchers);
    }
    if (props.xdb) {
        appendArray(getDispatchersForXdbDestinations([props.xdb], args), dispatchers);
    }
    return dispatchers;
}

/**
 * Get dispatchers from tracking data and props.
 * @param trackingConfig 
 * @param props 
 * @param logger 
 */
function getDispatchers(trackingConfig: any, props: UseSitecoreTrackerProps, args: GetDispatchersArgs): Dispatcher[] {
    //
    //
    const { logger } = args;
    const fromProps = getDispatchersFromProps(props, args);
    if (fromProps.length > 0) {
        logger.debug("useSitecoreTracker - Dispatchers retrieved from destinations set on props.", {fromProps});
    }
    //
    //
    const fromTrackingConfig = getDispatchersFromTrackingConfig(trackingConfig, args);
    if (fromTrackingConfig.length > 0) {
        logger.debug("useSitecoreTracker - Dispatchers retrieved from tracking config.", {fromTrackingConfig});
    }
    //
    //
    return fromProps.concat(fromTrackingConfig);
}

export function useSitecoreTracker(sitecoreContext: any, props?: UseSitecoreTrackerProps): Tracker|undefined {
    const trackerId = "SC_TRACKER";
    const logger = props?.logger ?? getNullLogger();
    const renderModes = props?.renderModes ?? ['normal'];

    const uniformContext = useContext(UniformContext);
    const trackerContext = useContext(TrackerContext);

    const [cookies, setCookie, removeCookie] = useCookies([]);
    const [tracker, setTracker] = useState<Tracker|undefined>(undefined);
    const [scriptsLoaded, setScriptsLoaded] = useState(false);
    const [clientScripts, setClientScripts] = useState<ClientScripts>({});
    const [trackingConfig, setTrackingConfig] = useState<any>(undefined);

    function getTrackingConfig(): any {
        let tracking = sitecoreContext?.tracking;
        if (tracking) {
            logger.debug("useSitecoreTracker -   * Tracking config available directly off Sitecore context.", {sitecoreContext});
        }
        else {
            logger.debug("useSitecoreTracker -   * Tracking config is not available directly off Sitecore context. Try alternate location.", {sitecoreContext});
            tracking = sitecoreContext?.sitecore?.context?.tracking;
        }
        if (!tracking) {
            logger.debug("useSitecoreTracker -   * Unable to resolve tracking config from Sitecore context.", {sitecoreContext});
            return;
        }
        logger.debug("useSitecoreTracker -   * Finished resolving tracking config.", { trackingConfig: tracking, sitecoreContext });
        return tracking;
    }

    function getPersonalizationConfig(): any {
        let personalization = sitecoreContext?.personalization;
        if (personalization) {
            logger.debug("useSitecoreTracker -   * Personalization config available directly off Sitecore context.", {sitecoreContext});
        }
        else {
            logger.debug("useSitecoreTracker -   * Personalization config is not available directly off Sitecore context. Try alternate location.", {sitecoreContext});
            personalization = sitecoreContext?.sitecore?.context?.personalization;
        }
        if (!personalization) {
            logger.debug("useSitecoreTracker -   * Unable to resolve personalization config from Sitecore context.", {sitecoreContext});
            return;
        }
        logger.debug("useSitecoreTracker -   * Finished resolving personalization config.", { personalizationConfig: personalization, sitecoreContext });
        return personalization;
    }

    function getPlaceholdersFromSitecoreContext(sitecoreContext: any): any {
        if (sitecoreContext?.placeholders) {
            logger.debug("useSitecoreTracker -   * Placeholders available directly off Sitecore context.", { sitecoreContext });
            return sitecoreContext.placeholders;
        }
        if (sitecoreContext?.route?.placeholders) {
            logger.debug("useSitecoreTracker -   * Placeholders available off Sitecore context route.", { sitecoreContext });
            return sitecoreContext.route.placeholders;
        }
        if (sitecoreContext?.sitecore?.route?.placeholders) {
            logger.debug("useSitecoreTracker -   * Placeholders available off Layout Service context.", { sitecoreContext });
            return sitecoreContext?.sitecore?.route?.placeholders;
        }
        logger.debug("useSitecoreTracker -   * Unable to resolve placeholders from Sitecore context.", { sitecoreContext });
    }

    function getPageStateFromSitecoreContext(sitecoreContext: any): any {
        if (sitecoreContext?.pageState) {
            logger.debug("useSitecoreTracker -   * Page state available directly off Sitecore context.", { sitecoreContext });
            return sitecoreContext.pageState;
        }
        if (sitecoreContext?.sitecore?.context?.pageState) {
            logger.debug("useSitecoreTracker -   * Page state available off Layout Service context.", { sitecoreContext });
            return sitecoreContext.sitecore.context.pageState;
        }
        logger.debug("useSitecoreTracker -   * Unable to resolve page state from Sitecore context.", { sitecoreContext });
    }


    function addOriginPersonalizationActivityToTrackingConfig(trackingConfig: any, sitecoreContext: any) {
        //
        //Origin-based personalization activity may be included on
        //renderings included in Sitecore context route data. The
        //personalization activity is only tracked if all dependencies
        //have been met. 
        //
        //Any dependencies for rendering in Sitecore context route
        //data are defined in tracking config personalization 
        //details.
        //
        //Activity and dependencies must be added to tracking config
        //personalization details. It is up to the context reader to
        //consider the dependencies and determine whether the activity
        //should get tracked.
        const placeholders = getPlaceholdersFromSitecoreContext(sitecoreContext);
        if (!placeholders) {
            logger.debug("useSitecoreTracker -   * No placeholders could be resolved from Sitecore context, so no personalization activity can be resolved.", { sitecoreContext });
            return;
        }
        //
        //Get activities from placeholders.
        let count = 0;
        const detailsWithActivities: PersonalizationDetails = {};
        Object.keys(placeholders).forEach(key => {
            const renderings = placeholders[key];
            if (Array.isArray(renderings)) {
                renderings.forEach(rendering => {
                    if (rendering.uid && rendering.personalization) {
                        detailsWithActivities[rendering.uid] = {
                            activity: rendering.personalization
                        };
                        count++;
                    }
                });
            }
        });
        if (count == 0) {
            logger.debug("useSitecoreTracker -   * No origin-based personalization activity was resolved from Sitecore context placeholders.", { trackingConfig, sitecoreContext, placeholders });
            return;
        }
        logger.debug("useSitecoreTracker -   START: Updating tracking config personalization details.", { trackingConfig, sitecoreContext });
        //
        //
        if (!trackingConfig.personalization) {
            trackingConfig.personalization = {};
        }
        const tDetails: PersonalizationDetails = trackingConfig.personalization;
        //
        //Assign activities to tracking config.
        Object.keys(detailsWithActivities).forEach(key => {
            const sActivity = detailsWithActivities[key]?.activity;
            if (!sActivity) return;
            if (!tDetails[key]) {
                tDetails[key] = {};
            }
            const tActivity = tDetails[key].activity;
            if (tActivity) {
                logger.warn("useSitecoreTracker -     * Tracking config already has activity assigned for rendering. This will not be overwritten with new activity from Sitecore context.", { rendering: key, current: tActivity, new: sActivity });
                return;
            }
            tDetails[key].activity = sActivity;
            logger.debug("useSitecoreTracker -     * Origin-based personalization activity for rendering was added to tracking config personalization details.", { rendering: key, activity: sActivity });
        });
        //
        //Get dependencies from context.
        const detailsWithDependencies: PersonalizationDetails = getPersonalizationConfig();
        if (!detailsWithDependencies) {
            logger.debug("useSitecoreTracker -     * No personalization details are set on Sitecore context, so no dependencies will be added to tracking config personalization details.", { trackingConfig, sitecoreContext });
        }
        else {
            logger.debug("useSitecoreTracker -     START: Resolve dependencies from Sitecore context personalization details.", { trackingConfig, sitecoreContext });
            let count = 0;
            Object.keys(detailsWithDependencies).forEach(key => {
                const sDependencies = detailsWithDependencies[key]?.dependencies;
                if (!sDependencies) return;
                if (!tDetails[key]) {
                    tDetails[key] = {};
                }
                const tDependencies = tDetails[key].dependencies;
                if (tDependencies) {
                    logger.warn("useSitecoreTracker -       * Tracking config already has dependencies assigned for rendering. This will not be overwritten with new dependencies from Sitecore context.", { rendering: key, current: tDependencies, new: sDependencies });
                    return;
                }
                tDetails[key].dependencies = sDependencies;
                count++;
                logger.debug("useSitecoreTracker -       * Personalization dependencies for rendering were added to tracking config personalization details.", { rendering: key, dependencies: sDependencies });
            });
            logger.debug("useSitecoreTracker -     END: Resolve dependencies from Sitecore context personalization details.", { count, trackingConfig, sitecoreContext });
        }
        logger.debug("useSitecoreTracker -   END: Updating tracking config personalization details.", { trackingConfig, sitecoreContext });
    }

    function updateCookieVisitorId(visitorId: string|undefined, results: TrackedActivityResults): string|undefined {
        let newId: string|undefined = results.visitor?.id ?? visitorId;
        if (visitorId != newId) {
            logger.debug('useSitecoreTracker - Updating the visitor id cookie.', { old: visitorId, new: newId });
            var date = getMaxExpirationDate();
            setCookie(UniformCookieNames.VisitorId, newId, {
                path: "/",
                expires: date
            });
        }
        return newId;
    }

    function updateCookieVisitId(visitId: string|undefined, results: TrackedActivityResults): string|undefined {
        let newId: string|undefined = results.visit?.id ?? visitId;
        if (visitId != newId) {
            logger.debug('useSitecoreTracker - Updating the visit id cookie.', { old: visitId, new: newId });
            setCookie(UniformCookieNames.VisitId, newId, {
                path: "/",
            });
        }
        return newId;
    }

    async function onScriptsLoaded() {
        //
        //Add subscriptions to tracker.
        const trackerSubs: SubscriptionManager<TrackingEvent> = getSubscriptionManager<TrackingEvent>(`${trackerId}_SUBS`);
        if (props?.subscriptions) {
            logger.debug("useSitecoreTracker -   * Adding subscriptions from props to the tracker.");
            props.subscriptions(trackerSubs.subscribe);
        }
        else {
            logger.debug("useSitecoreTracker -   * No subscriptions are set on props, so no subscribers will be added to the tracker.");
        }
        //
        //Use cookie settings from props if available, otherwise use from tracking config.
        const cookieSettings = props?.cookies ? props.cookies : getTrackerCookieSettings(trackingConfig);
        //
        //
        logger.debug("useSitecoreTracker -   * Adding subscriptions for tracker cookies.", { manager: trackerSubs.id });
        addSubscriptionsForTrackerCookies(trackerSubs, {
            cookieSettings,
            getCookieName: props?.getCookieName,
            getCookieValueFromVisit: props?.getCookieValueFromVisit,
            getCookieValueFromVisitor: props?.getCookieValueFromVisitor,
            logger, 
            loggerPrefix: "useSitecoreTracker", 
            getCookie: (name: string) => cookies[name],
            removeCookie, 
            setCookie
        });
        //
        //Add subscription to publish tracker events to the context.
        const unsubscribes: UniformUnsubscribe[] = [];
        if (!uniformContext.subscriptions) {
            uniformContext.subscriptions = getSubscriptionManager<UniformEvent>("UNIFORM_CONTEXT_SUBS", false, logger);
            logger.warn("useSitecoreTracker -   * No subscription manager was assigned to the Uniform context. One was just assigned, but this should not happen.", { id: uniformContext.subscriptions.id, uniformContext });
        }
        const contextSubs = uniformContext.subscriptions;
        if (contextSubs) {
            logger.debug("useSitecoreTracker -   * Adding subscription for all tracker events to be republished to the Uniform context subscription manager.", { trackerManager: trackerSubs.id, contextManager: contextSubs.id });
            const unsubscribe = trackerSubs.subscribe(undefined, e => {
                logger.debug("useSitecoreTracker -   * Event the tracker was subscribed to was fired. Publish the event to the Uniform context subscription manager.", { event: e, trackerManager: trackerSubs.id, contextManager: contextSubs.id });
                contextSubs.publish(e);
            })
            if (unsubscribe) {
                unsubscribes.push(unsubscribe);
            }
        }
        //
        //React-specific code is needed to initialize the GA dispatcher.
        //This code is passed to the dispatcher.
        logger.debug("useSitecoreTracker -   * Resolving dispatchers.", { trackingConfig });
        const dispatchers = getDispatchers(trackingConfig, props ?? {}, {
            logger,
            loggerPrefix: "useSitecoreTracker",
            getCookie: (name: string) => cookies[name],
            setCookie,
            removeCookie,
            ga: {
                initializeGa: (destination, logger) => {
                    if (!destination.trackingIds || destination.trackingIds.length == 0) {
                        logger.debug("useSitecoreTracker - GA destination has no tracking ids assigned, so no GA initialization will be performed.", { destination });
                        return false;
                    }
                    var didInitialize = true;
                    destination.trackingIds.forEach(id => {
                        logger.debug("useSitecoreTracker - Will initialize GA from tracking id.", { id });
                        try {
                            ReactGA.initialize(id, { gaOptions: { name: id } });
                        }
                        catch (ex: any) {
                            logger.error("useSitecoreTracker - Error while initializing GA from tracking id.", { id, ex });
                            didInitialize = false;
                        }
                    });
                    return didInitialize;
                }
            }    
        });
        if (dispatchers.length == 0) {
            logger.debug("useSitecoreTracker -   * No dispatchers were resolved.")
        }
        else {
            logger.debug("useSitecoreTracker -   * Dispatchers were resolved.", { dispatchers });
        }
        //
        //
        const contextReaders = new Map<string, ContextReader[]>();
        if (props?.contextReaders) {
            logger.debug("useSitecoreTracker -   * Adding context readers from props.", { key: CONTEXT_SOURCE_SITECORE, readers: props.contextReaders });
            contextReaders.set(CONTEXT_SOURCE_SITECORE, props.contextReaders);
        }
        //
        //
        let sessionTimeout = 20;
        if (props?.sessionTimeout) {
            logger.debug("useSitecoreTracker -   * Use session timeout value from props.", { props });
            sessionTimeout = props.sessionTimeout;
        }
        else if (trackingConfig.settings?.session?.timeout) {
            logger.debug("useSitecoreTracker -   * Use session timeout value from tracking config.", { trackingConfig });
            sessionTimeout = trackingConfig.settings.session.timeout;
        }
        else {
            logger.debug("useSitecoreTracker -   * Use default session timeout value.", { sessionTimeout });
        }
        //
        //
        let campaignParameters;
        if (Array.isArray(props?.campaignParameters)) {
            logger.debug("useSitecoreTracker -   * Use campaign parameters from props.", { props });
            campaignParameters = props?.campaignParameters;
        }
        else if (Array.isArray(trackingConfig.settings?.campaigns?.parameters)) {
            logger.debug("useSitecoreTracker -   * Use campaign parameters from tracking config.", { trackingConfig });
            campaignParameters = trackingConfig.settings?.campaigns?.parameters;
        }
        else {
            logger.debug("useSitecoreTracker -   * No campaign parameters will be used.", { props, trackingConfig });
        }
        //
        //
        let goalParameters;
        if (Array.isArray(props?.goalParameters)) {
            logger.debug("useSitecoreTracker -   * Use goal parameters from props.", { props });
            goalParameters = props?.goalParameters;
        }
        else if (Array.isArray(trackingConfig.settings?.goals?.parameters)) {
            logger.debug("useSitecoreTracker -   * Use goal parameters from tracking config.", { trackingConfig });
            goalParameters = trackingConfig.settings?.goals?.parameters;
        }
        else {
            logger.debug("useSitecoreTracker -   * No goal parameters will be used.", { props, trackingConfig });
        }
        //
        //
        const args: GetSitecoreTrackerArgs = {
            campaignParameters,
            contextReaders,
            decay: props?.decay ?? getDefaultDecaySettings(),
            dispatchers,
            onDispatch: props?.onDispatch,
            goalParameters,
            logger,
            sessionTimeout,
            type: props?.type ?? 'default',
            storage: props?.storage ?? 'default',
            subscriptions: trackerSubs
        };
        //
        //Get the tracker
        logger.debug('useSitecoreTracker -   * Resolving the tracker.', { settings: args });
        const scTracker = getSitecoreTracker(args, logger);
        if (!scTracker) {
            logger.error('useSitecoreTracker -   * No tracker was resolved.');
            return;
        }
        setTracker(scTracker);
        logger.debug('useSitecoreTracker -   * Tracker was resolved.', { tracker: scTracker });
        //
        //Update the tracker context.
        logger.debug('useSitecoreTracker -   * Updating the tracker context.', { tracker: tracker?.id, trackerContext });
        trackerContext.tracker = scTracker;
        if (trackerContext.subscriptions) {
            logger.debug('useSitecoreTracker -   * Publishing event to subscribers on the tracker context to indicate that the tracker is ready.', { tracker: tracker?.id, manager: trackerContext.subscriptions.id, trackerContext });
            trackerContext.subscriptions.publish({ 
                type: "tracker-set", 
                when: new Date(),
                tracker: scTracker
            });
        }
        //
        //
        unsubscribes.push(scTracker.subscribe("tracking-finished", e => {
            if (props && !props.clientSideRouting) {
                logger.debug("useSitecoreTracker - Tracking finished and not in client-side routing mode, so set flag on tracker context to indicate refresh is needed.", { event: e });
                trackerContext.needsRefresh = true;
            }
        }));
        //
        //Get visitor id for tracker settings.
        logger.debug('useSitecoreTracker -   * Current cookies.', { cookies });
        let visitorId = cookies[UniformCookieNames.VisitorId];
        if (visitorId) {
            logger.debug('useSitecoreTracker -   * Found visitor id in cookie.', { cookie: UniformCookieNames.VisitorId, value: visitorId });
        }
        else {
            logger.debug('useSitecoreTracker -   * Visitor id not found in cookie, so check tracker context.');
            visitorId = trackerContext.visitorId;
            if (visitorId) {
                logger.debug('useSitecoreTracker -   * Found visitor id in tracker context.', { value: visitorId });
            }
        }
        if (!visitorId) {
            logger.debug('useSitecoreTracker -   * No visitor id was found.');
        }
        //
        //Get visit id for tracker settings.
        let visitId = cookies[UniformCookieNames.VisitId];
        if (visitId) {
            logger.debug('useSitecoreTracker -   * Found visit id in cookie.', { cookie: UniformCookieNames.VisitId, value: visitId });
        }
        else {
            logger.debug('useSitecoreTracker -   * No visit id was found.');
        }
        //
        //
        const trackingSettings: TrackingSettings = {
            visitId,
            visitorId,
            createVisitor: true,
            onVisitorInitialized: props?.onVisitorInitialized,
        }
        //
        //Use the tracker.
        if (props?.clientSideRouting == true) {
            logger.debug(
                'useSitecoreTracker -   * Client-side routing is enabled, so only initialize the tracker. ' + 
                'The tracker must be called in the application, after the application determines the route has changed.'
            );
            const results = await scTracker.initialize(trackingSettings);
            logger.debug('useSitecoreTracker -   * Received tracking results from tracker initialization.', results);
            visitorId = updateCookieVisitorId(visitorId, results);
            visitId = updateCookieVisitId(visitId, results);
        }
        else {
            logger.debug('useSitecoreTracker -   * Client-side routing is disabled, so track using tracking data.', {props, trackingConfig, trackingSettings});
            const results = await scTracker.track('sitecore', trackingConfig, trackingSettings);
            logger.debug('useSitecoreTracker -   * Received tracking results from tracking with tracking data.', {visitorId, results});
            visitorId = updateCookieVisitorId(visitorId, results);
            visitId = updateCookieVisitId(visitId, results);
        }
        if (trackerContext.visitorId != visitorId) {
            logger.debug('useSitecoreTracker -   * Update visitor id on tracker context.', { current: trackerContext.visitorId, new: visitorId });
            trackerContext.visitorId = visitorId;
        }
        //
        //
        if (props?.onInitialized) {
            logger.debug('useSitecoreTracker -   * Event handler for tracker initialization was set on props, so call it.', { props });
            props.onInitialized();
        }
        //
        //Components can use the global queue to capture
        //tracker events even if the tracker is not yet
        //initialized. Now that the tracker is being 
        //initialized, it is time to handle those events.
        const queue = window.uniform?.queue;
        if (!queue) {
            logger.debug('useSitecoreTracker -   * No global queue is defined, so there are no tracker events to process.', { visitorId });
        }
        else {
            const handleEntry = async (entry: TrackerQueueEntry): Promise<void> => {
                if (entry) {
                    const tracker = window.uniform?.tracker;
                    if (tracker) {
                        const settings: TrackingSettings = {
                            visitorId
                        }
                        logger.debug('useSitecoreTracker - Track using global queue entry data.', {queue: queue.id, entry, settings});
                        const results = await tracker.event(entry.type, entry.e, settings);
                        logger.debug('useSitecoreTracker - Received tracking results from tracking with global queue entry data.', results);
                        visitorId = updateCookieVisitorId(visitorId, results);
                    }
                }
            }
            //
            //Process entries already in the global queue.
            if (queue.count(QUEUE_ENTRY_TYPE_TRACKER) === 0) {
                logger.debug('useSitecoreTracker -   * No tracker entries in the global queue to process.', { queue: queue.id, visitorId });
            }
            else {
                logger.debug('useSitecoreTracker -   START: handle existing tracker entries from the global queue.', { queue: queue.id, visitorId });
                while (queue.count(QUEUE_ENTRY_TYPE_TRACKER) > 0) {
                    const entry = queue.get(QUEUE_ENTRY_TYPE_TRACKER) as TrackerQueueEntry;
                    logger.debug('useSitecoreTracker -     * Handle existing tracker entry.', { queue: queue.id, entry, visitorId });
                    await handleEntry(entry);
                }
                logger.debug('useSitecoreTracker -   END: handle existing tracker entries from the global queue.', { queue: queue.id, visitorId });
            }
            //
            //Subscribe to the global queue.
            logger.debug('useSitecoreTracker -   * Add subscriptions for subsequent tracker entries to the global queue.', { queue: queue.id, visitorId });
            const unsubscribe = queue.subscribe(QUEUE_ENTRY_TYPE_TRACKER, async (e) => {
                logger.debug('useSitecoreTracker - Handle tracker entry.', { queue: queue.id, entry: e.entry, visitorId });
                const entry = e.entry as TrackerQueueEntry;
                await handleEntry(entry);
            });
            if (unsubscribe) {
                unsubscribes.push(unsubscribe);
            }
        }
        //
        //
        return function cleanup() {
            logger.debug("useSitecoreTracker - CLEANUP EFFECT: Scripts loaded flag changed.");
            unsubscribes.forEach(unsub => {
                logger.debug('useSitecoreTracker -   * Removing subscription for tracker entries from global queue.', { 
                    subscription: unsub.id, 
                    queue: queue?.id 
                });
                unsub.unsub();
            });
        }
    }

    function isSupportedRenderMode(): boolean {
        const pageState = getPageStateFromSitecoreContext(sitecoreContext);
        if (pageState) return renderModes.includes(pageState);
        return false;
    }
    /**
     * Sitecore context object changed.
     */
    useEffect(() => {
        if (!sitecoreContext) return;
        if (!isSupportedRenderMode()) return;
        logger.debug("useSitecoreTracker - START EFFECT: Sitecore context changed.", { sitecoreContext });
        //
        //
        const config = getTrackingConfig();
        setTrackingConfig(config);
        //
        //This hook usually is passed the Sitecore context, and this context
        //may change as the page is loaded. As a result, this hook shouldn't
        //run until it's clear that enough context data is available to run.
        if (!config) {
            logger.debug("useSitecoreTracker -   * No tracking config object was resolved. The tracker cannot be loaded until this object is available.", { sitecoreContext });
        }
        else {
            //
            //
            addOriginPersonalizationActivityToTrackingConfig(config, sitecoreContext);
            //
            //
            const scripts = getTrackerScripts(config, props);
            setClientScripts(scripts);
            logger.debug("useSitecoreTracker -   * Set tracker context logger.", { logger });
            trackerContext.logger = logger;
        }
        logger.debug("useSitecoreTracker - END EFFECT: Sitecore context changed.");
    }, [sitecoreContext]);

    /**
     * Load scripts.
     */
    useScripts(clientScripts, (_scripts) => {
        if (scriptsLoaded == true) return;
        logger.debug("useSitecoreTracker - START: useScripts callback.", { clientScripts });
        initializeGlobalObject(logger);
        logger.info("useSitecoreTracker - Scripts are loaded.", clientScripts);
        setScriptsLoaded(true);
        logger.debug("useSitecoreTracker - END: useScripts callback.", { clientScripts });
    });

    /**
     * Scripts loaded.
     */
    useEffect(() => {
        if (!isSupportedRenderMode()) return;
        if (!trackingConfig) return;
        if (scriptsLoaded !== true) return;
        logger.info("useSitecoreTracker - START EFFECT: Scripts loaded flag changed.", { flag: scriptsLoaded, clientScripts });
        //
        //Logic is in function in order to call async code in hook.
        onScriptsLoaded().then(() => {
            logger.info("useSitecoreTracker - END EFFECT: Scripts loaded flag changed.");
        });
    }, [scriptsLoaded, trackingConfig]);

    return tracker;
}
