import { SitecoreItem } from './sitecore';
import { RuleBasedRenderingPersonalizationDefinition } from '../ruleManagers/sitecore';

/**
 * Provides the ability to read data from the context
 * that is passed to a personalization manager.
 */
export interface ContextDataReader<TPageDetails> {
    getDefinitions(
        contextData: any
    ):
        | RuleBasedRenderingPersonalizationDefinition
        | DependencyBasedRenderingPersonalizationDefinition
        | undefined;
    getPageDetails(contextData: any): TPageDetails;
}

/**
 * Personalization data is exposed from Sitecore through
 * a source. The source used by a Sitecore site depends
 * on the technology used to build the Sitecore site.
 * This type represents the options available for how
 * the personalization data is exposed.
 */
export type SitecoreContextDataSource = 'uniform' | 'jss' | 'jss-esi' | 'default';

/**
 * Gets a Sitecore context data reader based on the specified source.
 * @param source
 */
export function getSitecoreContextDataReader(
    source?: SitecoreContextDataSource
): ContextDataReader<SitecoreItem> | undefined {
    switch (source ?? 'default') {
        case 'jss':
            return JssContextDataReader;
        case 'jss-esi':
            return JssEsiContextDataReader;
        case 'uniform':
        case 'default':
            break;
    }
    return UniformContextDataReader;
}

export interface DependencyBasedRenderingPersonalizationDefinition {
    [renderingUid: string]: {
        dependencies: Array<string>;
    };
}

/**
 * Reads data from the JSS Layout Service.
 */
const JssContextDataReader: ContextDataReader<SitecoreItem> = {
    getDefinitions(contextData: any): RuleBasedRenderingPersonalizationDefinition | undefined {
        if (contextData?.sitecore?.context?.personalization) {
            return contextData?.sitecore?.context?.personalization;
        }
        return contextData?.personalization;
    },
    getPageDetails(contextData: any): any {
        if (contextData?.sitecore?.route) {
            return {
                id: contextData?.sitecore?.route?.itemId,
                name: contextData?.sitecore?.route?.name,
            };
        }
        return {
            id: contextData?.route?.itemId,
            name: contextData?.route?.name,
        };
    }
}

/**
 * Reads data from the Uniform Page Service.
 */
const UniformContextDataReader: ContextDataReader<SitecoreItem> = {
    getDefinitions(contextData: any): RuleBasedRenderingPersonalizationDefinition | undefined {
        return contextData.personalization;
    },
    getPageDetails(contextData: any): SitecoreItem {
        return contextData;
    }
}

const JssEsiContextDataReader: ContextDataReader<SitecoreItem> = {
    getDefinitions: (contextData: any): DependencyBasedRenderingPersonalizationDefinition | undefined => {
        if (contextData?.sitecore?.context?.personalization) {
            return contextData?.sitecore?.context?.personalization;
        }
        return contextData?.personalization;
    },
    getPageDetails: (contextData: any): SitecoreItem => {
        if (contextData?.sitecore?.route) {
            return {
                id: contextData?.sitecore?.route?.itemId,
                name: contextData?.sitecore?.route?.name,
            };
        }
        return {
            id: contextData?.route?.itemId,
            name: contextData?.route?.name,
        };
    },
};
