import { TrackedActivityResults } from './trackedActivity';
import { Visit } from './visit';
import { Visitor } from './visitor';

export function appendTrackedActivityResults(source: TrackedActivityResults, target: TrackedActivityResults): void {
    if (!source) {
        return;
    }
    source.visitActivities.forEach(a => target.visitActivities.push(a));
    source.visitUpdates.forEach(a => target.visitUpdates.push(a));
    source.visitorUpdates.forEach(a => target.visitorUpdates.push(a));
}

export function getLatestVisit(visitor: Visitor): (Visit | undefined) {
    var latest: Visit | undefined = undefined;
    for (var i = 0; i < visitor.visits.length; i++) {
        var current = visitor.visits[i];
        if (current.end) continue;
        if (!latest || current.updated > latest.updated) {
            latest = current;
        }
    }
    return latest;
}

export function getActiveVisits(visitor: Visitor): Visit[] {
    const visits: Visit[] = [];
    for (var i = 0; i < visitor.visits.length; i++) {
        var current = visitor.visits[i];
        if (current.end) continue;
        visits.push(current);
    }
    return visits;
}
