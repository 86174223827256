"use strict";

import { TrackedActivity } from '../../models/trackedActivity';
import { GaEvent, GaTrackedActivityConverter } from "../ga/dispatcher";

/**
 * Converts Sitecore-specific events into events for Google Analytics.
 */
export function getGaTrackedActivityConverterForSitecore() : GaTrackedActivityConverter {
  return {
    type: "default",
    convert(activity: TrackedActivity): GaEvent | undefined {
      switch(activity.type) {
        case "page view":
          return convertPageView("Sitecore Page View", activity);
        case "page event":
          return convertEvent("Sitecore Event", activity);
        case "goal":
          return convertEvent("Sitecore Goal", activity);
        case "personalization":
          return convertPersonalization("Uniform Personalization", activity);
        default:
          return undefined;
      }
    }
  }
}

/**
 * Converts page event and goals.
 * @param category 
 * @param activity 
 */
function convertEvent(category: string, activity: TrackedActivity): GaEvent {
  return {
    category: category,
    action: activity.data.name,
    label: undefined,
    value: activity.data.points
  };
}

/**
 * Converts page view event.
 * @param category 
 * @param activity 
 */
function convertPageView(category: string, activity: TrackedActivity): GaEvent {
  return {
    category,
    action: activity.data.item.id,
    label: activity.data.item.name,
    value: 0
  };
}

/**
 * Converts personalization event.
 * @param category 
 * @param activity 
 */
function convertPersonalization(category: string, activity: TrackedActivity): GaEvent {
  const pageName = activity?.data?.page?.name;
  const componentName = activity?.data?.component?.name;
  return {
    category,
    action: `${pageName}|${componentName}`,
    label: activity.data.rule.name,
    value: activity.data.isIncludedInTest ? 1 : 0
  }
}
