import { Visitor } from '../models/visitor';
import { Logger } from '@uniformdev/common';
import { LocalStorageProvider } from './localStorage';

export type StorageProviderType = 'local' | 'custom' | 'default';

export function getStorageProvider(storage: StorageProviderType, getCustomProvider: ((() => StorageProvider) | undefined), logger: Logger): StorageProvider | undefined {
    switch (storage) {
        case 'custom':
            const provider = getCustomProvider ? getCustomProvider!() : undefined;
            if (!provider) {
                logger.error('When the custom storage provider type is specified, getCustomProvider must return a provider.', { storage });
            }
            return provider;
        case 'default':
        case 'local':
            break;
        default:
            logger.error(
                'The storage option specified for the tracker is not supported. Default storage provider will be used.',
                { storage: storage }
            );
    }
    return new LocalStorageProvider();
}

export interface StorageProvider {
    read: (visitorId: string, logger?: Logger) => Visitor | undefined;
    write: (visitor: Visitor, logger?: Logger) => void;
}
