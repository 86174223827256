import { PatternDefinition, ProfileDefinition } from './profiles';
import { ProfileKeyScores, getNormalizedScores } from './scoring';

export interface GetDistance { (a: number[], b: number[]): number }

export interface PatternMatcher {
    match(scores: ProfileKeyScores, profile: ProfileDefinition): PatternMatch | undefined
}

export interface PatternMatch {
    name?: string,
    patternId: string,
    distance: number
}

export function getPatternMatcher(getDistance?: GetDistance): PatternMatcher {
    return new DefaultPatternMatcher(getDistance ?? getSquaredDistance);
}

class DefaultPatternMatcher implements PatternMatcher {
    constructor(getDistance: GetDistance) {
        this.getDistance = getDistance;
    }
    getDistance: GetDistance;
    match(scores: ProfileKeyScores, profile: ProfileDefinition): PatternMatch | undefined {
        if (!scores || !profile) {
            return undefined;
        }
        const values = Object.keys(scores).map(profileKeyId => {
            return scores[profileKeyId];
        })
        return getMatch(values, profile, this.getDistance);
    }
}

function getMatch(visitorValues: number[], profile: ProfileDefinition, getDistance: GetDistance): PatternMatch | undefined {
    const patterns = profile?.patterns;
    if (!patterns) {
        return undefined;
    }
    //
    //If the visitor has no values, no pattern should match.
    if (!visitorValues.find(value => value > 0)) {
        return undefined;
    }
    let bestMatchId: (string | undefined) = undefined;
    let bestMatchPattern: (PatternDefinition | undefined);
    let shortestDistance: number = 0;
    Object.keys(patterns).forEach(patternId => {
        const pattern = patterns[patternId];
        const normalizedPatternScores = getNormalizedScores(pattern.keys, profile.keys)
        const patternValues = Object.keys(normalizedPatternScores).map(patternId => {
            return normalizedPatternScores[patternId];
        })
        const distance = getDistance(visitorValues, patternValues);
        if (shortestDistance > distance || bestMatchId == undefined) {
            shortestDistance = distance;
            bestMatchId = patternId;
            bestMatchPattern = pattern;
        }
    });
    //
    //
    if (!bestMatchId) {
        return undefined;
    }
    return {
        name: bestMatchPattern?.name,
        patternId: bestMatchId,
        distance: shortestDistance
    };
}

/**
 * Get Euclidean squared distance.
 * @param a 
 * @param b 
 */
function getSquaredDistance(a:number[], b:number[]): number {
    var sum = 0
    var n
    for (n = 0; n < a.length; n++) {
      sum += Math.pow(a[n] - b[n], 2)
    }
    return Math.sqrt(sum)
}
