import { createContext } from 'react';
import { Logger } from '@uniformdev/common';
import { SubscriptionManager, UniformEvent, getSubscriptionManager } from '@uniformdev/optimize-common-sitecore';

export interface UniformContextValue {
    logger?: Logger;
    subscriptions?: SubscriptionManager<UniformEvent>;
    when: Date;
}

export const UniformContext = createContext({
    subscriptions: getSubscriptionManager<UniformEvent>("UNIFORM_CONTEXT_SUBS"),
    when: new Date()
} as UniformContextValue);
