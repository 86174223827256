import { ClientScript, ClientScripts } from '@uniformdev/common-client';
import { useEffect } from 'react';

let cachedScripts: ClientScripts = {};

/**
 * Runs after the scripts are loaded.
 * @callback scriptsLoadedCallback
 */

/**
 * Loads scripts and calls a callback when the scripts are finished loading.
 * Scripts that are already loaded are not re-loaded. The scripts that are
 * actually loaded (i.e. not already loaded) are passed as a parameter to
 * the callback function.
 * @param {TrackerScripts} scripts - Urls for the scripts to load.
 * @param {scriptsLoadedCallback} callback - Function that is called after the scripts are loaded.
 */
export function useScripts(scripts: ClientScripts, callback: (loadedScripts: ClientScript[]) => void) {
    useEffect(() => {
        //
        //Return if no urls were specified.
        const ids = Object.keys(scripts);
        if (ids.length == 0) {
            callback([]);
            return;
        }
        //
        //Return if all urls are already cached.
        const idsNotCached: string[] = [];
        ids.forEach(id => {
            const cachedUrl = cachedScripts[id];
            if (cachedUrl != scripts[id]) {
                idsNotCached.push(id);
            }
        });
        if (idsNotCached.length == 0) {
            callback([]);
            return;
        }
        //
        //
        const promises: Promise<ClientScript>[] = [];
        idsNotCached.forEach(id => {
            const url = scripts[id];
            promises.push(load.js({ id, url }));
        });
        Promise.all(promises)
            .then((scripts: ClientScript[]) => {
                scripts.forEach(script => {
                    cachedScripts[script.id] = script.url;
                });
                callback(scripts);
            });
    }, [scripts]);
}

const load = (function () {
    return {
        css: _load('link'),
        js: _load('script'),
        img: _load('img')
    }
})();

function _load(tag: string) {
    return function (script: ClientScript) {
        return new Promise<ClientScript>(function (resolve, reject) {
            var element = document.createElement(tag);
            var parent = document.body;
            var attr = 'src';
            element.onload = function () {
                resolve(script);
            };
            element.onerror = function () {
                element.remove();
                reject(script);
            };
            switch (tag) {
                case 'script':
                    (element as HTMLScriptElement).defer = true;
                    break;
                case 'link':
                    (element as HTMLLinkElement).type = 'text/css';
                    (element as HTMLLinkElement).rel = 'stylesheet';
                    attr = 'href';
                    parent = document.head;
            }
            const attr2 = document.createAttribute(attr);
            attr2.value = script.url;
            element.attributes.setNamedItem(attr2);
            parent.appendChild(element);
        });
    };
}